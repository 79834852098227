import { createSlice } from "@reduxjs/toolkit";
export const SettlementsSlice=createSlice({
    name:"settlements",
    initialState:{
        value:[]
    },
    reducers:{
        populate_settlements:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_settlements}=SettlementsSlice.actions;
export default SettlementsSlice.reducer;