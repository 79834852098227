import { createSlice } from "@reduxjs/toolkit";
export const MetadataSlice=createSlice({
    name:"metadata",
    initialState:{
        value:[]
    },
    reducers:{
        populate_metadata:(state,action)=>
        {
            state.valuea=action.payload
        }
    }
});
export const {populate_metadata}=MetadataSlice.actions;
export default MetadataSlice.reducer;