import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_news_feed } from "../Redux/NewsFeedSlice";
import { populate_metadata } from "../Redux/MetadataSlice";
import DeleteIcon from '../..//Images/DeleteIcon.png';
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {

  const news_feed=useSelector((state)=>state.newsFeedComponent.value);
  const meta_data=useSelector((state)=>state.metaDataComponent.value);
      //Pagination
      const counter=useSelector((state)=>state.counterComponent.value);
      const[current_news_feed_array,set_current_news_feed_array]=useState([]);
      const current_page=useRef(1);
      const total_pages=useRef(Math.ceil(Number(counter.news_feed_count/10)));
      //Load The First 10 elements of the table
      const[bannerFiles,setBannerFiles]=useState([]);
      const[thumbnailFile,setThumbnailFile]=useState(null);
      useEffect(()=>
      {
        let temp=[];
        if(news_feed.length<10)
        {
          for(let i=0;i<news_feed.length;i++)
          {
            temp.push(news_feed[i]);
          }
        }
        else
        {
        for(let i=0;i<10;i++)
        {
          temp.push(news_feed[i]);
        }
        }
        set_current_news_feed_array(temp);
      },[]);
      const onPressNext=()=>
      {
        if(current_page.current+1<=total_pages.current)
        {
          if(news_feed.length>=(current_page.current+1)*10)
          {
            let temp=[];
            for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
            {
              temp.push(news_feed[i]);
            }
            current_page.current+=1;
            set_current_news_feed_array(temp);
          }
          else
          {
            axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/news_feed?limit=10&offset=${current_page.current*10}`)
            .then((response)=>
            {
              if(response.status===200)
              {
                let temp=[];
                let temp_all=[];
                for(let i=0;i<news_feed.length;i++)
                {
                  temp_all.push(news_feed[i]);
                }
                for(let i=0;i<response.data.length;i++)
                {
                  temp_all.push(response.data[i]);
                  temp.push(response.data[i]);
                }
                current_page.current+=1;
                set_current_news_feed_array(temp);
                dispatch(populate_news_feed(temp_all));
              }
              else
              {
                alert(response.message);
              }
            }).catch((error)=>
            {
              alert(error.message);
            })
          }
        }
      }
      const onPressPrevious=()=>
      {
        if(current_page.current-1>0)
        {
         let temp=[];
         for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
         {
          temp.push(news_feed[i]);
         }
         current_page.current-=1;
         set_current_news_feed_array(temp);
        }
      }
      ///////////////////////////////////////////////////////////////////////
  console.log(meta_data[0]);
  const dispatch = useDispatch();
  const[title,set_title]=useState("");
  const onChangeTitle=(e)=>
  {
    set_title(e.target.value);
  }
  const[content,set_content]=useState("");
  const onChangeContent=(e)=>
  {
    set_content(e.target.value);
  }
  const[youtube_video_id,set_youtube_video_id]=useState("");
  const onChangeYoutubeVideoID=(e)=>
  {
    set_youtube_video_id(e.target.value);
  }
  const [file_to_upload, set_file_to_upload] = useState([]);
  const [file_to_preview, set_file_to_preview] = useState([]);
  const[file_name,set_file_names]=useState([]);
  const add_news_feed_button_ref = useRef();
  const onChangeImage = (id) => {
    let temp_file_upload_array=[];
    let temp_names=[];
    let temp_file_preview_array=[];
    for(let i=0;i<bannerFiles.length;i++)
    {    
    let form_data = new FormData();
    form_data.append("file", bannerFiles[i]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/NewsFeed/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        //For Server
       // temp_names.push(e.target.files[i].name);
        if(temp_names.length==bannerFiles.length && thumbnail_file_url.length>0)
        {
          axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/news_feed/${id}`,
          {
            banners_url:JSON.stringify(temp_names),
            thumbnail_url:thumbnailFile.name
          })
        }
        //For Local Confirmation
        //temp_file_preview_array.push(URL.createObjectURL(e.target.files[i]));
      }).catch(function(error){
        alert(error);
      })
    }
    set_file_names(temp_names);
    set_file_to_preview(temp_file_preview_array);
    set_file_to_upload(temp_file_upload_array);
  };
  const[thubnail_file,set_thumbnail_file]=useState([]);
  const[thumbnail_file_url,set_thumbnail_file_url]=useState("");
  const onChangeThumbnailFile=(id)=>
  {
    let form_data = new FormData();
    form_data.append("file", thubnail_file);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/NewsFeed/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_thumbnail_file_url(response.data.url);
      }).catch(function(error){
        alert(error);
      })
    }

  const add_news_feed = () => {
    console.log(JSON.stringify({
      title:title,
      content: content,
      youtube_video_id:youtube_video_id,
      thumbnail_url:thubnail_file.name,
      banners_url:JSON.stringify(file_name),
      creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
    }));
    add_news_feed_button_ref.current.disabled = true;
    if (
     title.length > 0 &&
      content.length > 0 &&
      file_name.length>0 &&
      thumbnail_file_url.length>0
    ) {
      const data = JSON.stringify({
        title:title,
        content: content,
        youtube_video_id:youtube_video_id,
        thumbnail_url:thubnail_file.name,
        banners_url:JSON.stringify(file_name),
        creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
          });
          axios
            .post(
              "https://node-service-app-ifox3xnafa-el.a.run.app/add_news_feed",
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              console.log(response);

              add_news_feed_button_ref.current.disabled = false;
              let news_feed_temp_array = [];
              for (let i = 0; i < news_feed.length; i++) {
                news_feed_temp_array.push(news_feed[i]);
              }
              news_feed_temp_array.push({
                id: news_feed.length+1,
                title:title,
                content:content,
                youtube_video_id:youtube_video_id,
                thumbnail_url:thubnail_file.name,
                banners_url:JSON.stringify(file_name),
                creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()
              });
              dispatch(populate_news_feed(news_feed_temp_array));
              alert("Successfully Added News Feed");
              set_title("");
              set_content("");
              set_thumbnail_file("");
              set_thumbnail_file_url("");
              set_file_names("");
              set_file_to_upload("");
              set_youtube_video_id("");
              set_file_to_preview([]);
            })
            .catch(function (err) {
              alert(err.message);
            });
      
    } else {
      alert("Fields Are Missing!");
      add_news_feed_button_ref.current.disabled = false;
    }
  };
  const[banner_files_url,set_banner_files_url]=useState("");
  const[selected_id,set_selected_id]=useState(0);
  const [show_edit_modal,set_show_edit_modal] = useState(false);
  const onEditNewsFeedClicked=(id)=>
  {
    set_selected_id(id);
    set_show_edit_modal(true);
    set_title(news_feed.find(e=>e.id===id).title);
    set_content(news_feed.find(e=>e.id===id).content);
    set_banner_files_url(news_feed.find(e=>e.id===id).banners_url);
    set_thumbnail_file_url(news_feed.find(e=>e.id===id).thumbnail_url);
    console.log(news_feed.find(e=>e.id===id).banners_url);
  }
  const[selected_action,set_selected_action]=useState("")
  return (
    <Container>
      {show_edit_modal === true ? (
        <FormContainer>
            <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        Update News Feed
      </h1>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">News Feed Title</Label>
                  <Input
                    type="text"
                    placeholder="News Feed Title"
                    value={title}
                    onChange={(e) => onChangeTitle(e)}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
               
              </ColumnForm>
            </TwoColumn>
            <TwoColumn>
              <ColumnForm>
            
              </ColumnForm>

              <ColumnForm>
              </ColumnForm>
              <ColumnForm>
              
              </ColumnForm>
            </TwoColumn>
            <InputContainer>
              <Label htmlFor="name-input">News Feed Content</Label>
              <InputLarge
                type="text"
                placeholder="News Feed Content"
                value={content}
                onChange={(e) => onChangeContent(e)}
              />
            </InputContainer>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">News Feed Banners</Label>
                <Input type="file" multiple={true} onChange={(e) => onChangeImage(e)} />
              </InputContainer>
             {JSON.parse(banner_files_url).length>0?
              <div  style={{width:"100%",alignSelf:"center",display:"flex",flexDirection:"row"}}>
              {JSON.parse(banner_files_url).map((item,index)=>
              <div  key={index} style={{width: "100%", height: "100%",marginLeft:20,marginRight:20,flexDirection:"column",display:"flex" }}>
              <img
                src={`https://storage.googleapis.com/p4uconsole/NewsFeed/${selected_id}/${item}`}
                style={{ width: "100%", height: "100%", objectFit: "fill"}}
              />
              <img src={DeleteIcon} style={{width:15,height:15,objectFit:"contain",position:"absolute",alignSelf:"flex-end"}}/>
              </div>)}

              </div>:null}
            </ColumnForm>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Thumbnail Image</Label>
                <Input type="file" onChange={(e) => onChangeThumbnailFile(e)} />
              </InputContainer>
              {
                thumbnail_file_url.length>0?
                <div style={{width:"50%",height:"50%",display:"flex",flexDirection:"column"}}>
                <img src={`https://storage.googleapis.com/p4uconsole/NewsFeed/${selected_id}/${thumbnail_file_url}`} style={{width:"100%",height:"100%",objectFit:"contain",alignSelf:"center"}}/>
                <img src={DeleteIcon} style={{width:15,height:15,objectFit:"contain",position:"absolute",alignSelf:"flex-end"}}/>
              </div>:null
              }
            </ColumnForm>
            <TwoColumn>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">News Feed Video ID</Label>
              <Input
                type="text"
                placeholder="News Feed Video ID"
                value={youtube_video_id}
                onChange={(e) => onChangeYoutubeVideoID(e)}
              />
            </InputContainer>
            </Column>
            <Column>
            </Column>
            </TwoColumn>
            <TwoColumn>
            
            
            </TwoColumn>
            <Actions>
              <Button
                ref={add_news_feed_button_ref}
                onClick={() => add_news_feed()}
              >
                Save News Feed
              </Button>
            </Actions>

          </form>
        </FormContainer>
      ) : 
      <>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        NEWS FEED TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "13%" }}>News Feed ID</TableTitle>
          <TableTitle style={{ width: "13%" }}>News Feed Thumbnail</TableTitle>
          <TableTitle style={{ width: "28%" }}>News Feed Title</TableTitle>

          <TableTitle style={{ width: "28%" }}>News Feed Description</TableTitle>

          <TableTitle style={{ width: "18%" }}>Actions</TableTitle>
        </TableTop>

        {current_news_feed_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "13%" }}>{item.id}</TableDetails>
              <TableDetails
                style={{
                  width: "28%",
                  height: "20%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    `https://storage.googleapis.com/p4uconsole/NewsFeed/${item.id}/` +
                    item.thumbnail_url
                  }
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                ></img>
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>{item.title}</TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {item.content}
              </TableDetails>

              <TableDetails style={{ width: "18%" }}>
                <ActionIconsColumn>
                  <IconContainer onClick={()=>onEditNewsFeedClicked(item.id)}>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
      </>}
    </Container>
  );
};
