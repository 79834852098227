import { createSlice } from "@reduxjs/toolkit";
export const CategoriesSlice=createSlice({
    name:"categories",
    initialState:{
        value:[]
    },
    reducers:{
        populate_categories:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_categories}=CategoriesSlice.actions;
export default CategoriesSlice.reducer;