import React, { useState,useEffect,useRef} from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_categories } from "../Redux/CategoriesSlice";
import CheckedCircle from "../../Images/CheckedCircle.png";
import UnCheckedCircle from "../../Images/UncheckedCircle.png";
import { populate_notifications } from "../Redux/NotificationsSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default() => {
  const customers = useSelector((state) => state.customersComponent.value);
  const [notification_for, set_notification_for] = useState("Customers");
  const notifications = useSelector(
    (state) => state.notificationComponent.value
  );
  const dispatch = useDispatch();
     //Pagination
     const counter=useSelector((state)=>state.counterComponent.value);
     const[current_notification_array,set_current_notification_array]=useState([]);
     const current_page=useRef(1);
     const total_pages=useRef(Math.ceil(Number(counter.notifications_count/10)));
     //Load The First 10 elements of the table
     useEffect(()=>
     {
       let temp=[];
       if(notifications.length<10)
    {
      for(let i=0;i<notifications.length;i++)
      {
        temp.push(notifications[i]);
      }
    }
    else
    {
       for(let i=0;i<10;i++)
       {
         temp.push(notifications[i]);
       }
      }
       set_current_notification_array(temp);
     },[]);
     const onPressNext=()=>
     {
       if(current_page.current+1<=total_pages.current)
       {
         if(notifications.length>=(current_page.current+1)*10)
         {
           let temp=[];
           for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
           {
             temp.push(notifications[i]);
           }
           current_page.current+=1;
           set_current_notification_array(temp);
         }
         else
         {
           axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/notifications?limit=10&offset=${current_page.current*10}`)
           .then((response)=>
           {
             if(response.status===200)
             {
               let temp=[];
               let temp_all=[];
               for(let i=0;i<notifications.length;i++)
               {
                 temp_all.push(notifications[i]);
               }
               for(let i=0;i<response.data.length;i++)
               {
                 temp_all.push(response.data[i]);
                 temp.push(response.data[i]);
               }
               current_page.current+=1;
               set_current_notification_array(temp);
               dispatch(populate_notifications(temp_all));
             }
             else
             {
               alert(response.message);
             }
           }).catch((error)=>
           {
             alert(error.message);
           })
         }
       }
     }
     const onPressPrevious=()=>
     {
       if(current_page.current-1>0)
       {
        let temp=[];
        for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
        {
         temp.push(notifications[i]);
        }
        current_page.current-=1;
        set_current_notification_array(temp);
       }
     }
     ///////////////////////////////////////////////////////////////////////
  const [title, set_title] = useState("");
  const onChangeTitle = (e) => {
    set_title(e.target.value);
  };
  const [description, set_description] = useState("");
  const onChangeDescription = (e) => {
    set_description(e.target.value);
  };
  const add_notification_button_ref = useRef();
  const add_notification = () => {
    let registration_ids = [];
    if (notification_for === "Customers") {
      for (let i = 0; i < customers.length; i++) {
        registration_ids.push(customers[i].registration_token);
      }
    } else {
    }
    if (title.length > 0 && description.length > 0) {
      // add_notification_button_ref.disabled = true;
      var data1 = {
        data: 
        { 
          type: "User",
          specificRoute:"Notifications",
          specificId:0
        },
        notification: {
          title: title,
          body: description,
          sound:'notif.wav'
          // image:
        },
        registration_ids: registration_ids,
      };

      var config = {
        method: "post",
        url: "https://fcm.googleapis.com/fcm/send",
        headers: {
          Authorization:
            "key=AAAAtqgGS0o:APA91bEWPCUlMzWZY3AXnJd9499nHC1iQnzIU6vmbbOoMGDem8IiEtM0ZiAl7OMQFQ0cB4eBtC1MC4KwjPjR-eGljXUsvRmGPl-ryS7GQpnJNAN0pnffMMlhM74XpzwEgYY0V4euVYae",
          "Content-Type": "application/json",
        },
        data: data1,
      };
      axios(config)
        .then(function (response2) {
          console.log(response2);
          if (response2.status === 200) {
            axios
              .post(
                `https://node-service-app-ifox3xnafa-el.a.run.app/add_notification`,
                {
                  title: title,
                  body: description,
                  date:
                    new Date().getFullYear() +
                    "-" +
                    Number(new Date().getMonth() + 1) +
                    "-" +
                    new Date().getDay() +
                    " " +
                    new Date().getHours() +
                    ":" +
                    new Date().getMinutes() +
                    ":" +
                    new Date().getSeconds(),
                  for_customers: notification_for === "Customers" ? 1 : 0,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response3) => {
                console.log(response3);
                if (response3.status === 200) {
                  let temp_notifications = [];
                  for (let i = 0; i < notifications.length; i++) {
                    temp_notifications.push(notifications[i]);
                  }
                  temp_notifications.push({
                    id: notifications.length + 1,
                    title: title,
                    body: description,
                    date:
                      new Date().getFullYear() +
                      "-" +
                      Number(new Date().getMonth() + 1) +
                      "-" +
                      new Date().getDay() +
                      " " +
                      new Date().getHours() +
                      ":" +
                      new Date().getMinutes() +
                      ":" +
                      new Date().getSeconds(),
                    for_customers: notification_for === "Customers" ? 1 : 0,
                  });
                  dispatch(populate_notifications(temp_notifications));
                  set_title("");
                  set_description("");
                  set_notification_for("Customers");
                }
              });
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Fields are empty!!");
      // add_notification_button_ref.disabled = false;
    }
  };
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  return (
    <Container>
      {show_upload_modal === true ? (
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Notification Title</Label>
                  <Input
                    type="text"
                    placeholder="Notification Title"
                    value={title}
                    onChange={(e) => onChangeTitle(e)}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm></ColumnForm>
            </TwoColumn>

            <InputContainer>
              <Label htmlFor="name-input">Notification Description</Label>
              <InputLarge
                type="text"
                placeholder="Notification Description"
                value={description}
                onChange={(e) => onChangeDescription(e)}
              />
            </InputContainer>
            <div
              style={{
                width: "100%",
                height: "5%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "48%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={
                    notification_for === "Customers"
                      ? CheckedCircle
                      : UnCheckedCircle
                  }
                  onClick={() => set_notification_for("Customers")}
                />
                <label
                  style={{
                    width: "70%",
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: 30,
                    color: "#000000",
                  }}
                >
                  Customers
                </label>
              </div>
              <div
                style={{
                  width: "48%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={
                    notification_for === "Vendors"
                      ? CheckedCircle
                      : UnCheckedCircle
                  }
                  onClick={() => set_notification_for("Vendors")}
                />
                <label
                  style={{
                    width: "70%",
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: 30,
                    color: "#000000",
                  }}
                >
                  Vendors
                </label>
              </div>
            </div>
            <Actions>
              <Button
                ref={add_notification_button_ref}
                onClick={() => add_notification()}
              >
                Send Notification
              </Button>
            </Actions>
          </form>
        </FormContainer>
      ) : null}

      <h1 style={{ color: "#000000", textAlign: "center", fontSize: 32 }}>
        NOTIFICATION TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "13%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "13%" }}>Notification ID</TableTitle>
          <TableTitle style={{ width: "28%" }}>Notification Title</TableTitle>
          <TableTitle style={{ width: "28%" }}>
            Notification Description
          </TableTitle>
          <TableTitle style={{ width: "13%" }}>Notification For</TableTitle>
          {/* <TableTitle style={{ width: "18%" }}>Actions</TableTitle> */}
        </TableTop>

        {current_notification_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "13%", textAlign: "center" }}>
                {item.id}
              </TableDetails>
              <TableDetails style={{ width: "28%", textAlign: "center" }}>
                {item.title}
              </TableDetails>

              <TableDetails style={{ width: "28%", textAlign: "center" }}>
                {item.body}
              </TableDetails>
              <TableDetails style={{ width: "28%", textAlign: "center" }}>
                {item.for_customers === 0 ? "Vendors" : "Customers"}
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  );
};
