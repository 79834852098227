import React, { useState, useEffect } from "react";
import {signOut} from 'firebase/auth';
import {auth} from '../firebase.js';
import tw from "twin.macro";
import Planextlogo from "../Images/logo.png";
import SignoutIcon from '../Images/Signout.png';
const PrimaryButton = tw.a`px-6 py-1 text-xl cursor-pointer text-black bg-white rounded-2xl hocus:border-blue-500 hocus:text-blue-500 transition duration-300`;
const PrimaryLink = tw.a`pb-1 text-xl cursor-pointer text-white border-b border-transparent hocus:border-blue-500 hocus:text-blue-500 transition duration-300`;

const Container = tw.div`bg-SecondaryBackground items-center w-full justify-between flex py-4 md:px-20 px-6`;

const NavButton = tw.div`md:mx-6 my-4`;
const HeaderLogoContainer = tw.div`w-16`;

export default () => {
  const onClickedSignout=()=>
  {
    signOut(auth).then(() => {
     alert('Successfully Signed Out');
    }).catch((error) => {
      alert(error.message);
    });
  }
  return (
    <Container  style={{backgroundColor:"#04103D"}}>
      
        <HeaderLogoContainer>
          <img src={Planextlogo} />
        </HeaderLogoContainer>
        <HeaderLogoContainer style={{float:"right"}}>
          <img onClick={()=>onClickedSignout()} src={SignoutIcon} style={{cursor:"pointer"}} />
        </HeaderLogoContainer>
      
      {/* <NavButton>
        <PrimaryButton href="/ContactUs">Sign In</PrimaryButton>
      </NavButton> */}
    </Container>
  );
};
