import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../MiscStyles/Headings";

import Accounting from "../../Images/Accounting.png";
import Marketing from "../../Images/Marketing.png";
import Design from "../../Images/Design.png";
import AutomotiveJobs from "../../Images/AutomotiveJobs.png";
import { BsBriefcase } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsChatDots } from "react-icons/bs";
import { BsBookmark } from "react-icons/bs";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { MdHomeRepairService } from "react-icons/md";
import { BsCartCheck } from "react-icons/bs";
import { useSelector } from "react-redux";
import VendorIcon from "../../Images/Vendor.png";
import CustomerIcon from "../../Images/Customer.png";
import CouponIcon from "../../Images/Coupon.png";
import TagIcon from "../../Images/Tag.png";
import ProductIcon from "../../Images/Product.png";
import BannerIcon from "../../Images/Banner.png";
import CategoryIcon from "../../Images/Category.png";
import ServiceIcon from "../../Images/Service.png";
import AdvertisementIcon from "../../Images/Advertisement.png";
import NotificationIcon from "../../Images/Notification.png";
import OrderIcon from "../../Images/Order.png";
import NewsFeedIcon from "../../Images/NewsFeed.png";
const Container = tw.div`relative `;
const CountDescriptionContainer = tw.div`flex-col `;

const CountContainer = tw.div` text-3xl  font-bold`;
const CountContainer3 = tw.div`  text-3xl text-blue-800 font-bold`;
const CountContainer4 = tw.div`  text-3xl text-gray-500 font-bold`;

const DescriptionContainer = tw.div` text-xl font-semibold`;

const LeftContainer = tw.div`text-3xl   rounded-xl p-4`;
const LeftContainer2 = tw.div`text-3xl text-green-700 bg-green-200 rounded-xl p-4`;
const LeftContainer3 = tw.div`text-3xl text-blue-800 bg-yellow-100 rounded-xl p-4`;
const LeftContainer4 = tw.div`text-3xl text-gray-700 bg-gray-300 rounded-xl p-4`;
const RightContainer = tw.div` flex-col pl-6`;

const CardContianer = tw.div`w-full mt-20 flex flex-col flex-wrap items-center md:items-stretch md:flex-row  md:justify-center`;

const Card = tw.div`flex w-twentypercent flex-col sm:flex-row items-center m-6 bg-white sm:items-start text-center sm:text-left px-6 py-6 border-solid border rounded-xl`;

export default () => {
  const counts=useSelector((state)=>state.counterComponent.value);
  return (
    <Container>
      <CardContianer>
        <Card style={{ color: "orange" }}>
          <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={CustomerIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Customers</DescriptionContainer>
              <CountContainer>{counts.customers_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={CategoryIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Categories</DescriptionContainer>
              <CountContainer>{counts.categories_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={ServiceIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Services</DescriptionContainer>
              <CountContainer>{counts.services_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>

        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={VendorIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Providers</DescriptionContainer>
              <CountContainer>{counts.providers_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={NotificationIcon}/>
          </LeftContainer>
        <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Notifications</DescriptionContainer>
              <CountContainer>{counts.notifications_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        {/* <Card style={{ color: "#1d4ed8" }}>
          <LeftContainer style={{ backgroundColor: "#bbf7d0" }}>
            <RiAdvertisementLine />
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Advertisements</DescriptionContainer>
              <CountContainer>0</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card> */}

        {/* <Card style={{ color: "#6b7280" }}>
          <LeftContainer style={{ backgroundColor: "#d1d5db" }}>
            <IoNewspaperOutline />
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Posts</DescriptionContainer>
              <CountContainer>28</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card> */}

        {/* <Card style={{ color: "#0f766e" }}>
          <LeftContainer style={{ backgroundColor: "#93c5fd" }}>
            <BsChatDots />
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Comments</DescriptionContainer>
              <CountContainer>3</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card> */}

        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={OrderIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Orders</DescriptionContainer>
              <CountContainer>{counts.orders_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={ProductIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Products</DescriptionContainer>
              <CountContainer>{counts.products_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={BannerIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Banners</DescriptionContainer>
              <CountContainer>{counts.banners_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={NewsFeedIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>News Feed</DescriptionContainer>
              <CountContainer>{counts.news_feed_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={TagIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Tags</DescriptionContainer>
              <CountContainer>{counts.tags_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={CouponIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Coupons</DescriptionContainer>
              <CountContainer>{counts.coupons_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={VendorIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Vendor Requests</DescriptionContainer>
              <CountContainer>{counts.vendors_request_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={ProductIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Product Requests</DescriptionContainer>
              <CountContainer>{counts.products_request_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
        <Card style={{ color: "orange" }}>
        <LeftContainer style={{ backgroundColor: "transparent",display:"flex",justifyContent:"center",height:"100%" }}>
           <img style={{width:25,height:25,objectFit:"contain"}} src={ProductIcon}/>
          </LeftContainer>

          <RightContainer>
            <CountDescriptionContainer>
              <DescriptionContainer>Settlements</DescriptionContainer>
              <CountContainer>{counts.settlements_count}</CountContainer>
            </CountDescriptionContainer>
          </RightContainer>
        </Card>
      </CardContianer>
    </Container>
  );
};
