import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { componentReducer } from "./Redux/ComponentSlice";
import { AiOutlineHome } from "react-icons/ai";
import VendorIcon from "../Images/Vendor.png";
import CustomerIcon from "../Images/Customer.png";
import CouponIcon from "../Images/Coupon.png";
import TagIcon from "../Images/Tag.png";
import ProductIcon from "../Images/Product.png";
import BannerIcon from "../Images/Banner.png";
import CategoryIcon from "../Images/Category.png";
import ServiceIcon from "../Images/Service.png";
import AdvertisementIcon from "../Images/Advertisement.png";
import NotificationIcon from "../Images/Notification.png";
import OrderIcon from "../Images/Order.png";
import NewsFeedIcon from "../Images/NewsFeed.png";
const ButtonText = tw.h1`pl-2 `;
const ButtonText1 = tw.h1`pl-2 `;

const IconContainer = tw.div`text-xl`;
const SocialButtonsContainer = tw.div` w-2/12 pt-8 overflow-auto z-10 h-full flex flex-col  items-center`;

const Link1 = styled.button`
  ${tw`w-64 font-semibold rounded-lg py-4 pl-4 text-xl text-black bg-gray-100 hocus:shadow-md hocus:bg-primaryOrange hocus:border-gray-600 flex items-center transition-all duration-300 focus:outline-none focus:shadow-outline 
  //ml-6 mr-8
   mt-2 first:mt-0`}
`;

export default () => {
  const dispatch = useDispatch();
  const number = useSelector((state) => state.callComponent.value);

  console.log(number);
  const [current_section, set_current_section] = useState(0);
  const OnClickSidebarIcons = (section_val, component_val) => {
    set_current_section(section_val);
    dispatch(componentReducer(component_val));
  };
  return (
    <div>
      {number > 0 ? (
        <SocialButtonsContainer
          style={{ width: "100%", borderRight: "5px solid #022C43" }}
        >
          <Link1
            style={{
              backgroundColor: current_section === 0 ? "#F89F03" : "#FFFFFF",
            }}
            onClick={() => OnClickSidebarIcons(0, 1)}
          >
            <IconContainer>
              <AiOutlineHome />
            </IconContainer>
            <ButtonText1>Dashboard</ButtonText1>
          </Link1>

          <Link1
            style={{
              backgroundColor: current_section === 1 ? "#F89F03" : "#FFFFFF",
            }}
            // onClick={() => dispatch(componentReducer(2))}
            onClick={() =>OnClickSidebarIcons(1, 2)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={VendorIcon} />
            </IconContainer>
            <ButtonText>Vendors</ButtonText>
          </Link1>
          {current_section === 1 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(2))}
              >
                Add Vendor
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(7))}
              >
                List Vendors
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(24))}
              >
                New Vendor Enquiries
              </button>
            </>
          ) : null}
          <Link1
            style={{ backgroundColor: number === 3 ? "#F89F03" : "#FFFFFF" }}
                onClick={() =>OnClickSidebarIcons(2, 3)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={OrderIcon} />
            </IconContainer>
            <ButtonText>Booking</ButtonText>
          </Link1>
          {/* <Link1
            style={{ backgroundColor: number === 23 ? "#F89F03" : "#FFFFFF" }}
                onClick={() =>OnClickSidebarIcons(2, 23)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={OrderIcon} />
            </IconContainer>
            <ButtonText>Kalam Registration</ButtonText>
          </Link1> */}
          <Link1
            style={{ backgroundColor: number === 26 ? "#F89F03" : "#FFFFFF" }}
                onClick={() =>OnClickSidebarIcons(2, 26)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={OrderIcon} />
            </IconContainer>
            <ButtonText>Payroll</ButtonText>
          </Link1>
          <Link1
            style={{
              backgroundColor: current_section === 3 ? "#F89F03" : "#FFFFFF",
            }}
            // onClick={() => dispatch(componentReducer(4))}
            onClick={() => OnClickSidebarIcons(3, 15)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={CategoryIcon} />
            </IconContainer>
            <ButtonText>Categories</ButtonText>
          </Link1>
          {current_section === 3 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(15))}
              >
                Add Categories
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(4))}
              >
                List Categories
              </button>
            </>
          ) : null}
          <Link1
            style={{
              backgroundColor: current_section === 4 ? "#F89F03" : "#FFFFFF",
            }}
            //onClick={() => dispatch(componentReducer(5))}
            onClick={() => OnClickSidebarIcons(4, 16)}
          >
            <IconContainer
              value={{ size: "24", className: "global-class-name" }}
            >
              <img src={ServiceIcon} />
            </IconContainer>
            <ButtonText>Services</ButtonText>
          </Link1>
          {current_section === 4 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(16))}
              >
                Add Services
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(5))}
              >
                List Services
              </button>
            </>
          ) : null}
          <Link1
            style={{
              backgroundColor: current_section === 5 ? "#F89F03" : "#FFFFFF",
            }}
            onClick={() => OnClickSidebarIcons(5, 17)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={TagIcon} />
            </IconContainer>
            <ButtonText>Tags</ButtonText>
          </Link1>
          {current_section === 5 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(17))}
              >
                Add Tags
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => dispatch(componentReducer(9))}
              >
                List Tags
              </button>
            </>
          ) : null}
          <Link1
            style={{
              backgroundColor: current_section === 6 ? "#F89F03" : "#FFFFFF",
            }}
            // onClick={() => dispatch(componentReducer(10))}
            onClick={() => OnClickSidebarIcons(6,18)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={BannerIcon} />
            </IconContainer>
            <ButtonText>Banners</ButtonText>
          </Link1>
          {current_section === 6 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(18))}
              >
                Add Banners
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(10))}
              >
                List Banners
              </button>
            </>
          ) : null}
          <Link1
            style={{ backgroundColor: current_section === 10 ? "#F89F03" : "#FFFFFF" }}
            onClick={() => OnClickSidebarIcons(10,19)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={ProductIcon} />
            </IconContainer>
            <ButtonText>Products</ButtonText>
          </Link1>
          {current_section === 10 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(19))}
              >
                Add Products
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(11))}
              >
                List Products
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(25))}
              >
                Add Product Requests
              </button>
            </>
          ) : null}
          <Link1
            style={{
              backgroundColor: current_section === 7 ? "#F89F03" : "#FFFFFF",
            }}
            // onClick={() => dispatch(componentReducer(12))}
            onClick={() =>  OnClickSidebarIcons(7,22)}
          >
            <IconContainer  value={{ size: "24" }}>
              <img src={CouponIcon} />
            </IconContainer>
            <ButtonText>Coupons</ButtonText>
          </Link1>
          {current_section === 7 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(22))}
              >
                Add Coupons
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(12))}
              >
                List Coupons
              </button>
            </>
          ) : null}
          <Link1
            style={{
              backgroundColor: current_section === 8 ? "#F89F03" : "#FFFFFF",
            }}
            //  onClick={() => dispatch(componentReducer(13))}
            onClick={() =>  OnClickSidebarIcons(8,20)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={NewsFeedIcon} />
            </IconContainer>
            <ButtonText>News Feed</ButtonText>
          </Link1>
          {current_section === 8 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(20))}
              >
                Add News Feed
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(13))}
              >
                List News Feed
              </button>
            </>
          ) : null}
          <Link1
            style={{ backgroundColor: current_section===9 ? "#F89F03" : "#FFFFFF" }}
            onClick={() => OnClickSidebarIcons(9,21)}
          >
            <IconContainer value={{ size: "24" }}>
              <img src={NotificationIcon} />
            </IconContainer>
            <ButtonText>Notifications</ButtonText>
          </Link1>
          {current_section === 9 ? (
            <>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(21))}
              >
                Add Notifications
              </button>
              <button
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  marginTop: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={()=>dispatch(componentReducer(14))}
              >
                List Notifications
              </button>
            </>
          ) : null}
          <Link1
            style={{ backgroundColor: current_section === 11 ? "#F89F03" : "#FFFFFF" }}
            onClick={() => OnClickSidebarIcons(11,6)}
          >
            <IconContainer
              value={{ size: "24", className: "global-class-name" }}
            >
              <img src={CustomerIcon} />
            </IconContainer>
            <ButtonText>Customers</ButtonText>
          </Link1>
          <Link1
            style={{ backgroundColor: number === 8 ? "#F89F03" : "#FFFFFF" }}
            onClick={() => dispatch(componentReducer(8))}
          >
            <IconContainer
              value={{ size: "24", className: "global-class-name" }}
            >
              <img src={AdvertisementIcon} />
            </IconContainer>
            <ButtonText>Advertisements</ButtonText>
          </Link1>
        </SocialButtonsContainer>
      ) : null}
    </div>
  );
};
