import React, { useState, useRef,useEffect } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_categories } from "../Redux/CategoriesSlice";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
  const categories = useSelector((state) => state.categoriesComponent.value);
   //Pagination
   const counter=useSelector((state)=>state.counterComponent.value);
   const[current_category_array,set_current_category_array]=useState([]);
   const current_page=useRef(1);
   const total_pages=useRef(Math.ceil(Number(counter.categories_count/10)));
   //Load The First 10 elements of the table
   useEffect(()=>
   {
     let temp=[];
     if(categories.length<10)
     {
       for(let i=0;i<categories.length;i++)
       {
         temp.push(categories[i]);
       }
     }
     else
     {
     for(let i=0;i<10;i++)
     {
       temp.push(categories[i]);
     }
     }
     set_current_category_array(temp);
   },[]);
   const onPressNext=()=>
   {
     if(current_page.current+1<=total_pages.current)
     {
       if(categories.length>=(current_page.current+1)*10)
       {
         let temp=[];
         for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
         {
           temp.push(categories[i]);
         }
         current_page.current+=1;
         set_current_category_array(temp);
       }
       else
       {
         axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/categories?limit=10&offset=${current_page.current*10}`)
         .then((response)=>
         {
           if(response.status===200)
           {
             let temp=[];
             let temp_all=[];
             for(let i=0;i<categories.length;i++)
             {
               temp_all.push(categories[i]);
             }
             for(let i=0;i<response.data.length;i++)
             {
               temp_all.push(response.data[i]);
               temp.push(response.data[i]);
             }
             current_page.current+=1;
             set_current_category_array(temp);
             dispatch(populate_categories(temp_all));
           }
           else
           {
             alert(response.message);
           }
         }).catch((error)=>
         {
           alert(error.message);
         })
       }
     }
   }
   const onPressPrevious=()=>
   {
     if(current_page.current-1>0)
     {
      let temp=[];
      for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
      {
       temp.push(categories[i]);
      }
      current_page.current-=1;
      set_current_category_array(temp);
     }
   }
   ///////////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const [category_name, set_category_name] = useState("");
  const onChangeCategoryName = (e) => {
    set_category_name(e.target.value);
  };
  const [category_description, set_category_description] = useState("");
  const onChangeCategoryDescription = (e) => {
    set_category_description(e.target.value);
  };

  const [category_icon_url, set_category_icon_url] = useState("");
  const [file_to_upload, set_file_to_upload] = useState("");
  const [file_to_preview, set_file_to_preview] = useState("");
  const [is_available, set_is_available] = useState(true);

  const onChangeIsAvailable = (e) => {
    set_is_available(e.target.value);
  };
  const add_category_button_ref = useRef();
  const onChangeImage = (e) => {
    set_file_to_upload(e.target.files[0]);
    // axios
    // .delete(
    //   `https://node-service-app-ifox3xnafa-el.a.run.app/files/Categories/nothing/${category_icon_url}`,
    // )
    // .then(response => {
    //   console.log(response.status);
    //   if(response.status===200)
    //   {
        let form_data = new FormData();
        form_data.append("file", e.target.files[0]);
        axios
          .post(
            "https://node-service-app-ifox3xnafa-el.a.run.app/upload/Categories/nothing",
            form_data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          ).then((response)=>
          {
            if(response.status===200)
            {
              axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/Categories/${selected_category_id}`,
              {
                icon_url:e.target.files[0].name
              }).then((response1)=>
              {
                if(response1.status===200)
                {
                  alert("Successfully Updated The Category");
                }
              }).catch((error)=>
              {
                alert(error.message);
              })
            }
          }).catch((error)=>
          {
            console.log(error.message);
            alert(error.message);
          })
        set_file_to_preview(URL.createObjectURL(e.target.files[0]));
    //   }
    // })
    // .catch(error => {
    //   console.log(error.message);
    // });

  };
  const UpdateCategory=()=>
  {
    axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/Categories/${selected_category_id}`,
    {
      name:category_name,
      description:category_description,
      is_available:is_available,
     // icon_url:file_to_upload.name
    }).then((response)=>
    {
      if(response.status===200)
      {
        let category_obj = categories.find((e) => e.id === selected_category_id);
        let new_category_object = {
          id:selected_category_id,
          name: category_name,
          description: category_description,
          is_available:is_available,
          icon_url:category_obj.icon_url,
          services_count:category_obj.services_count
        };
        let category_temp_array = [];
        for (let i = 0; i < categories.length; i++) {
         category_temp_array.push(categories[i]);
        }
       category_temp_array.splice(
          category_temp_array.findIndex((e) => e.id === selected_category_id),
          1
        );
        category_temp_array.push(new_category_object);
        dispatch(populate_categories(category_temp_array));
        alert("Successfully Updated Categories");
        set_category_description("");
        set_category_name("");
        set_file_to_preview("");
        set_file_to_upload("");
        set_edit_category(false);
      }
      else
      {
        alert(response.message);
      }
    }).catch((error)=>
    {
      alert(error.message);
    })
  }
  const onDeleteCategoryClicked=(id)=>
  {
    let pictureName=categories.find(e=>e.id==id).icon_url;
          axios.delete(`https://node-service-app-ifox3xnafa-el.a.run.app/category/${id}`)
        .then((deleteResponse)=>
        {
          console.log(deleteResponse);
          if(deleteResponse.status===200)
          {
          console.log('Successfully Deleted The Entry');
            axios.delete(`https://node-service-app-ifox3xnafa-el.a.run.app/files/Categories/null/${pictureName}`)
    .then((response)=>
    {
      console.log(response);
      if(response.status===200)
      {
        console.log('Successfully Deleted The Picture');
   }
    })
    .catch((error)=>
    {
      console.log('Error Deleting Picture:'+error);
    })
          }
        })
        .catch((deleteError)=>
        {
          console.log('Error Deleting Entry:'+deleteError);
        });
  
  }

  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const [edit_category, set_edit_category] = useState(false);
  const [selected_category_id, set_selected_category_id] = useState("");
  const OnEditCategoryClicked = (id) => {
    set_selected_category_id(id);
    set_edit_category(true);
    set_category_name(categories.find((e) => e.id === id).name);
    set_category_description(categories.find((e) => e.id === id).description);
    set_category_icon_url(categories.find((e) => e.id === id).icon_url);
    set_is_available(categories.find((e) => e.id === id).is_available);
  };
  const UpdateCategoryAvailability=(_id,_is_available)=>
  {
    axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/Categories/${_id}`,
    {
      is_available:_is_available===0?1:0,
    }).
    then((response)=>
    {
      if(response.status===200)
      {
        let category_obj = categories.find((e) => e.id === _id);
        let new_category_object = {
          id:_id,
          name: category_obj.name,
          description:category_obj.description,
          is_available:_is_available===0?1:0,
          icon_url:category_obj.icon_url,
          services_count:category_obj.services_count
        };
        let category_temp_array = [];
        for (let i = 0; i < categories.length; i++) {
         category_temp_array.push(categories[i]);
        }
       category_temp_array.splice(
          category_temp_array.findIndex((e) => e.id ===_id),
          1
        );
        category_temp_array.push(new_category_object);
        dispatch(populate_categories(category_temp_array));
      }
      else
      {
        alert(response.message);
      }
    }).catch((error)=>
    {
      alert(error.message);
    })
  }
  return edit_category === false ? (
    <Container>
      <h1 style={{ color: "#000000", textAlign: "center", fontSize: 32 }}>
        CATEGORY TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "13%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "13%" }}>Category ID</TableTitle>
          <TableTitle style={{ width: "13%" }}>Category Icon</TableTitle>
          <TableTitle style={{ width: "28%" }}>Category Name</TableTitle>

          <TableTitle style={{ width: "28%" }}>Category Description</TableTitle>

          <TableTitle style={{ width: "18%" }}>Availability</TableTitle>
          <TableTitle style={{ width: "12%",textAlign:"center" }}>Change Availability</TableTitle>
          <TableTitle style={{ width: "18%" }}>Actions</TableTitle>
        </TableTop>

        {current_category_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "13%" }}>{item.id}</TableDetails>
              <TableDetails
                style={{
                  width: "13%",
                  height: "20%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    "https://storage.googleapis.com/p4uconsole/Categories/" +
                    item.icon_url
                  }
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                ></img>
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>{item.name}</TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {item.description}
              </TableDetails>

              <TableDetails style={{ width: "18%" ,color:item.is_available===1?"green":"red",fontWeight:"bold"}}>
                {item.is_available===1?"Available":"Unavailable"}
              </TableDetails>
              <TableDetails
                style={{
                  width: "12%",
                  height: "12%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={item.is_available === 1 ? SwitchOn : SwitchOff}
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    //  float: "left",
                  }}
                  onClick={() =>
                    UpdateCategoryAvailability(item.id,item.is_available)
                  }
                ></img>
              </TableDetails>
              <TableDetails style={{ width: "18%" }}>
                <ActionIconsColumn>
                  <IconContainer onClick={() => OnEditCategoryClicked(item.id)}>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer onClick={()=>onDeleteCategoryClicked(item.id)}>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  ) : (
    <Container>
      <h1 style={{ textAlign: "center", fontSize: 25 }}>Update Category</h1>
      <FormContainer>
        <form action="#">
          <TwoColumn>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Category Name</Label>
                <Input
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #022C43",
                  }}
                  type="text"
                  placeholder="Category Name"
                  value={category_name}
                  onChange={(e) => onChangeCategoryName(e)}
                />
              </InputContainer>
            </ColumnForm>

            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Availability</Label>
                <Select
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #022C43",
                  }}
                  value={is_available}
                  onChange={(e) => onChangeIsAvailable(e)}
                >
                  <OptionContainer value={1}>Yes</OptionContainer>
                  <OptionContainer value={0}>No</OptionContainer>
                </Select>
              </InputContainer>
            </ColumnForm>
          </TwoColumn>

          <InputContainer>
            <Label htmlFor="name-input">Category Description</Label>
            <InputLarge
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #022C43",
              }}
              type="text"
              placeholder="Category Description"
              value={category_description}
              onChange={(e) => onChangeCategoryDescription(e)}
            />
          </InputContainer>
          <ColumnForm>
            <InputContainer>
              <Label htmlFor="name-input">Category Icon</Label>
              <Input
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #022C43",
                }}
                type="file"
                onChange={(e) => onChangeImage(e)}
              />
            </InputContainer>
            <img
              src={`https://storage.googleapis.com/p4uconsole/Categories/${category_icon_url}`}
              style={{ width: "25%", height: "25%", objectFit: "contain" }}
            />
          </ColumnForm>
          <Actions>
            <Button
              ref={add_category_button_ref}
              onClick={() => UpdateCategory()}
            >
              Update Category
            </Button>
            <Button
              ref={add_category_button_ref}
              onClick={() => set_edit_category(false)}
            >
              Cancel
            </Button>
          </Actions>
        </form>
      </FormContainer>
    </Container>
  );
};
