import { store } from "./Components/Redux/Store";
import { Provider } from "react-redux";
import RoutingPage from './Pages/RoutingPage.js';


export default function App() {
  return (
    <Provider store={store}>
      <RoutingPage/>
    </Provider>
  );
}

{
  /* <Router>
<Routes>
  <Route exact path="/" element={<HomePage />} />
</Routes>
</Router> */
}
