import { createSlice } from "@reduxjs/toolkit";
export const CouponsSlice=createSlice({
    name:"coupons",
    initialState:{
        value:[]
    },
    reducers:{
        populate_coupons:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_coupons}=CouponsSlice.actions;
export default CouponsSlice.reducer;