import React,{useState,useEffect} from "react";
import tw from "twin.macro";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {useSelector} from 'react-redux'
import axios from 'axios';
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const[kalam_registration_array,set_kalam_registration_array]=useState([]);
  useEffect(()=>
  {
    axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/kalam_registration`).then((response)=>
    {
      if(response.status===200)
      {
        let temp_array=[];
        for(let i=0;i<response.data.length;i++)
        {
          temp_array.push(response.data[i]);
        }
        set_kalam_registration_array(temp_array);
      }
      else
      {
        alert(response.message);
      }
    }).catch((error)=>
    {
      alert(error.message);
    })
  },[])
  return (
    <Container>
      <h1 style={{ color: "white", textAlign: "center", fontSize: 32 }}>
        KALAM REGISTRATIONS{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "20%" }}>Name</TableTitle>

          <TableTitle style={{ width: "20%" }}>Mobile Number</TableTitle>

          {/* <TableTitle style={{ width: "15%" }}>Customer Join Date</TableTitle>

          <TableTitle style={{ width: "15%" }}>Actions</TableTitle> */}
        </TableTop>

        {kalam_registration_array.map((card, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "12%" }}>
                {i}
              </TableDetails>
              <TableDetails style={{ width: "20%" }}>
                {card.name}
              </TableDetails>

              <TableDetails style={{ width: "20%" }}>
                {card.mobile_number}
              </TableDetails>

          
            </Card>
          </Column>
        ))}
        <ButtonsContainer>
          <Button href="/Login">
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button href="/Login">
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  );
};
