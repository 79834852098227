import React, { useState, useEffect,useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
import axios from "axios";
import { populate_providers } from "../Redux/ProvidersSlice";
import CheckedBoxIcon from "../../Images/CheckedBox.png";
import UncheckedBoxIcon from "../../Images/UncheckedBox.png";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.label`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw` bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
   //Pagination
   const counter=useSelector((state)=>state.counterComponent.value);
   const[current_vendor_array,set_current_vendor_array]=useState([]);
   const current_page=useRef(1);
   const total_pages=useRef(Math.ceil(Number(counter.providers_count/10)));
   //Load The First 10 elements of the table
   useEffect(()=>
   {
     let temp=[];
     if(vendors.length<10)
     {
       for(let i=0;i<vendors.length;i++)
       {
         temp.push(vendors[i]);
       }
     }
     else
     {
     for(let i=0;i<10;i++)
     {
       temp.push(vendors[i]);
     }
    }
     set_current_vendor_array(temp);
   },[]);
   const onPressNext=()=>
   {
     if(current_page.current+1<=total_pages.current)
     {
       if(vendors.length>=(current_page.current+1)*10)
       {
         let temp=[];
         for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
         {
           temp.push(vendors[i]);
         }
         current_page.current+=1;
         set_current_vendor_array(temp);
       }
       else
       {
         axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/vendors?limit=10&offset=${current_page.current*10}`)
         .then((response)=>
         {
           if(response.status===200)
           {
             let temp=[];
             let temp_all=[];
             for(let i=0;i<vendors.length;i++)
             {
               temp_all.push(vendors[i]);
             }
             for(let i=0;i<response.data.length;i++)
             {
               temp_all.push(response.data[i]);
               temp.push(response.data[i]);
             }
             current_page.current+=1;
             set_current_vendor_array(temp);
             dispatch(populate_providers(temp_all));
           }
           else
           {
             alert(response.message);
           }
         }).catch((error)=>
         {
           alert(error.message);
         })
       }
     }
   }
   const onPressPrevious=()=>
   {
     if(current_page.current-1>0)
     {
      let temp=[];
      for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
      {
       temp.push(vendors[i]);
      }
      current_page.current-=1;
      set_current_vendor_array(temp);
     }
   }
   ///////////////////////////////////////////////////////////////////////
  const [temp_vendors,set_temp_vendors]=useState([]);
  useEffect(()=>
  {
    let _temp=[];
    for(let i=0;i<vendors.length;i++)
    {
      _temp.push(vendors[i]);
    }
    set_temp_vendors(_temp);
  },[])

  const [show_upload_modal, set_show_upload_modal] = useState(false);

  const UpdateVendorStatus = (_mobile_number, _status) => {
    axios
      .patch(
        `https://node-service-app-ifox3xnafa-el.a.run.app/providers/${_mobile_number}`,
        { status: _status === 1 ? 0 : 1 }
      )
      .then((response) => {
        if (response.status === 200) {
          
           temp_vendors.find(e=>e.mobile_number===_mobile_number).status=1;
           dispatch(populate_providers(temp_vendors));
        } else {
          alert("Problem Occured In Updating");
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };
  const[show_edit_modal,set_show_edit_modal]=useState(false);
  const create_profile_button_ref = useRef();
  const name_ref = useRef(null);
  const business_name_ref = useRef(null);
  const address_ref = useRef(null);
  const area_ref = useRef(null);
  const profile_picture_ref = useRef(null);
  const banner_ref = useRef(null);
  const state_ref = useRef(null);
  const city_ref = useRef(null);
  const pincode_ref = useRef(null);
  const gender_ref = useRef(null);
  const latitude_ref = useRef(null);
  const longitude_ref = useRef(null);
  const landmark_ref = useRef(null);
  const comission_ref = useRef(null);
  const age_ref = useRef(null);
  const email_ref = useRef(null);
  const phone_number_ref = useRef(null);
  const secondary_phone_ref = useRef(null);
  const pan_ref = useRef(null);
  const gst_ref = useRef(null);
  const ifsc_ref = useRef(null);
  const bank_name_ref = useRef(null);
  const account_holder_name_ref = useRef(null);
  const branch_ref = useRef(null);
  const account_number_ref = useRef(null);
  const categories_ref = useRef(null);
  const services_ref = useRef(null);
  const tag_ref = useRef(null);
  const about_ref = useRef(null);
  const categories = useSelector((state) => state.categoriesComponent.value);
  const services = useSelector((state) => state.servicesComponent.value);
  const tags = useSelector((state) => state.tagsComponent.value);
  const vendors = useSelector((state) => state.vendorsComponent.value);
  const dispatch = useDispatch();
  const [name, set_name] = useState("");
  const onChangeName = (e) => {
    set_name(e.target.value);
  };
  const [business_name, set_business_name] = useState("");
  const onChangeBusinessName = (e) => {
    set_business_name(e.target.value);
  };
  const [building_number, set_building_number] = useState("");
  const onChangeBuildingNumber = (e) => {
    set_building_number(e.target.value);
  };
  const [locality, set_locality] = useState("");
  const onChangeLocality = (e) => {
    set_locality(e.target.value);
  };
  const [state, set_state] = useState("");
  const onChangeState = (e) => {
    set_state(e.target.value);
  };
  const [city, set_city] = useState("");
  const onChangeCity = (e) => {
    set_city(e.target.value);
  };
  const [pincode, set_pincode] = useState("");
  const onChangePincode = (e) => {
    set_pincode(e.target.value);
  };
  const [lat, set_lat] = useState("");
  const onChangeLatitude = (e) => {
    set_lat(e.target.value);
  };
  const [lon, set_lon] = useState("");
  const onChangeLongitude = (e) => {
    set_lon(e.target.value);
  };
  const [landmark, set_landmark] = useState("");
  const onChangeLandmark = (e) => {
    set_landmark(e.target.value);
  };
  const [mobile_number, set_mobile_number] = useState("");
  const onChangeMobileNumnber = (e) => {
    set_mobile_number(e.target.value);
  };
  const [secondary_phone, set_secondary_phone] = useState("");
  const OnChangeSecondaryPhone = (e) => {
    set_secondary_phone(e.target.value);
  };
  const [email, set_email] = useState("");
  const onChangeEmail = (e) => {
    set_email(e.target.value);
  };
  const [age, set_age] = useState("");
  const onChangeAge = (e) => {
    set_age(e.target.value);
  };
  const [gender, set_gender] = useState("");
  const onChangeGender = (e) => {
    set_gender(e.target.value);
  };
  const [gst_file, set_gst_file] = useState("");
  const onChangeGstFile = (e) => {
    set_gst_file(e.target.files[0]);
    let form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Vendors/${
          vendors.length + 1
        }`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_gst_file_url(response.data.url);
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const [gst_file_url, set_gst_file_url] = useState("");
  const [pan_card_file, set_pan_card_file] = useState("");
  const onChangePanCardFile = (e) => {
    set_pan_card_file(e.target.files[0]);
    let form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Vendors/${
          vendors.length + 1
        }`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_pan_card_file_url(response.data.url);
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const [pan_card_file_url, set_pan_card_file_url] = useState("");
  const [profile_picture_file, set_profile_picture_file] = useState("");
  const onChangeProfilePictureFile = (e) => {
    set_profile_picture_file(e.target.files[0]);
    let form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Vendors/${
          vendors.length + 1
        }`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_profile_picture_file_url(response.data.url);
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const [profile_picture_file_url, set_profile_picture_file_url] = useState("");
  const [banner_file, set_banner_file] = useState("");
  const onChangeBannerFile = (e) => {
    set_banner_file(e.target.files[0]);
    let form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Vendors/${
          vendors.length + 1
        }`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_banner_file_url(response.data.url);
      })
      .catch(function (error) {
        alert(error);
      });
  };
  const [banner_file_url, set_banner_file_url] = useState("");
  const [about_business, set_about_business] = useState("");
  const onChangeAboutBusiness = (e) => {
    set_about_business(e.target.value);
  };
  const [comission_rate, set_comission_rate] = useState("");
  const onChangeComissionRate = (e) => {
    set_comission_rate(e.target.value);
  };
  const [bank_name, set_bank_name] = useState("");
  const onChangeBankName = (e) => {
    set_bank_name(e.target.value);
  };
  const [ifsc_code, set_ifsc_code] = useState("");
  const onChangeIFSCCode = (e) => {
    set_ifsc_code(e.target.value);
  };
  const [account_number, set_account_number] = useState("");
  const onChangeAccountNumber = (e) => {
    set_account_number(e.target.value);
  };
  const [branch, set_branch] = useState("");
  const onChangeBranch = (e) => {
    set_branch(e.target.value);
  };
  const [account_holder_name, set_account_holder_name] = useState("");
  const onChangeAccountHolderName = (e) => {
    set_account_holder_name(e.target.value);
  };
  const [upi_id, set_upi_id] = useState("");
  const onChangeUPIId = (e) => {
    set_upi_id(e.target.value);
  };
  const [services_per_categories, set_services_per_categories] = useState([]);
  const [selected_categories, set_selected_categories] = useState([]);
  const onSelectCategory = (id) => {
    if (selected_categories.findIndex((e) => e === id) !== -1) {
      let cat1_temp = [];
      selected_categories.splice(
        selected_categories.findIndex((e) => e === id),
        1
      );
      for (let i = 0; i < selected_categories.length; i++) {
        cat1_temp.push(selected_categories[i]);
      }
      set_selected_categories(cat1_temp);
      let serv_temp = [];
      let services_count = services_per_categories.length;
      for (let i = 0; i < services_count; i++) {
        services_per_categories.splice(
          services_per_categories.findIndex((e) => e.category_id === id),
          1
        );
      }
      for (let i = 0; i < services_per_categories.length; i++) {
        serv_temp.push(services_per_categories[i]);
      }
      set_services_per_categories(serv_temp);
      return 0;
    } else {
      let cat_temp = [];
      cat_temp.push(id);
      for (let i = 0; i < selected_categories.length; i++) {
        cat_temp.push(selected_categories[i]);
      }
      set_selected_categories(cat_temp);
      let serv_temp = [];
      for (let i = 0; i < services.length; i++) {
        if (services[i].category_id === id) {
          serv_temp.push(services[i]);
        }
      }
      for (let i = 0; i < services_per_categories.length; i++) {
        serv_temp.push(services_per_categories[i]);
      }

      set_services_per_categories(serv_temp);
      return 1;
    }
  };
  const [selected_services, set_selected_services] = useState([]);
  const onSelectService = (id) => {
    if (selected_services.findIndex((e) => e === id) !== -1) {
      let cat1_temp = [];
      selected_services.splice(
        selected_services.findIndex((e) => e === id),
        1
      );
      for (let i = 0; i < selected_services.length; i++) {
        cat1_temp.push(selected_services[i]);
      }
      set_selected_services(cat1_temp);
      return 0;
    } else {
      let cat_temp = [];
      cat_temp.push(id);
      for (let i = 0; i < selected_services.length; i++) {
        cat_temp.push(selected_services[i]);
      }
      set_selected_services(cat_temp);
      return 1;
    }
  };
  const [selected_tags, set_selected_tags] = useState([]);
  const onSelectTag = (id) => {
    if (selected_tags.findIndex((e) => e === id) !== -1) {
      let cat1_temp = [];
      selected_tags.splice(
        selected_tags.findIndex((e) => e === id),
        1
      );
      for (let i = 0; i < selected_tags.length; i++) {
        cat1_temp.push(selected_tags[i]);
      }
      set_selected_tags(cat1_temp);
      return 0;
    } else {
      let cat_temp = [];
      cat_temp.push(id);
      for (let i = 0; i < selected_tags.length; i++) {
        cat_temp.push(selected_tags[i]);
      }
      set_selected_tags(cat_temp);
      return 1;
    }
  };
  const onSaveVendorInformation = () => {
    create_profile_button_ref.current.disabled = true;
    if (
      name.length > 0 &&
      business_name.length > 0 &&
      building_number.length > 0 &&
      locality.length > 0 &&
      state.length > 0 &&
      city.length > 0 &&
      pincode.length === 6 &&
      age.length > 0 &&
      gender.length > 0 &&
      mobile_number.length > 0 &&
      email.length > 0 &&
      gst_file_url.length > 0 &&
      pan_card_file_url.length > 0 &&
      profile_picture_file_url.length > 0 &&
      banner_file_url.length > 0 &&
      landmark.length > 0 &&
      account_number.length > 0 &&
      ifsc_code.length > 0 &&
      branch.length > 0 &&
      bank_name.length > 0
    ) {
      const data = JSON.stringify({
        name: name,
        age: age,
        gender: gender,
        mobile_number: mobile_number,
        profile_picture_url: "profile",
        banner_url: "banner",
        service_images: null,
        business_name: business_name,
        email: email,
        gst: "gst",
        pan: "pan",
        status: 1,
        wallet: 0,
        about: about_business,
        category_id: JSON.stringify(selected_categories),
        service_id: JSON.stringify(selected_services),
        tag_id: JSON.stringify(selected_tags),
        comission_rate: comission_rate,
        star_rating: 0,
        registration_token: null,
        address: JSON.stringify([
          {
            building_number: building_number,
            locality: locality,
            city: city,
            state: state,
            landmark: landmark,
            pincode: pincode,
            latitude: lat,
            longitude: lon,
          },
        ]),
        bank_details: JSON.stringify([
          {
            bank_name: bank_name,
            account_number: account_number,
            ifsc_code: ifsc_code,
            branch: branch,
          },
        ]),
        date_of_registration:
          new Date().getFullYear() +
          "-" +
          Number(new Date().getMonth() + 1) +
          "-" +
          Number(new Date().getDay() + 1) +
          " " +
          new Date().getHours() +
          ":" +
          new Date().getMinutes() +
          ":" +
          new Date().getSeconds(),
      });
      console.log(data);
      axios
        .post(
          "https://node-service-app-ifox3xnafa-el.a.run.app/add_vendors",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let vend_temp = [];
            vend_temp.push({
              id: vendors.length + 1,
              name: name,
              age: age,
              gender: gender,
              mobile_number: mobile_number,
              profile_picture_url: URL.createObjectURL(profile_picture_file),
              banner_url: URL.createObjectURL(banner_file),
              service_images: "",
              business_name: business_name,
              email: email,
              gst: "gst",
              pan: "pan",
              status: 1,
              wallet: 0,
              about: about_business,
              category_id: JSON.stringify(selected_categories),
              service_id: JSON.stringify(selected_services),
              tag_id: JSON.stringify(selected_tags),
              comission_rate: comission_rate,
              star_rating: 0,
              address: JSON.stringify([
                {
                  building_number: building_number,
                  locality: locality,
                  city: city,
                  state: state,
                  landmark: landmark,
                  pincode: pincode,
                  latitude: lat,
                  longitude: lon,
                },
              ]),
              bank_details: JSON.stringify([
                {
                  bank_name: bank_name,
                  account_number: account_number,
                  ifsc_code: ifsc_code,
                  branch: branch,
                },
              ]),
              date_of_registration:
                new Date().getFullYear() +
                "-" +
                Number(new Date().getMonth() + 1) +
                "-" +
                Number(new Date().getDay() + 1) +
                " " +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
            });
            for (let i = 0; i < vendors.length; i++) {
              vend_temp.push(vendors[i]);
            }
            dispatch(populate_providers(vend_temp));
            set_name("");
            set_age("");
            set_gender("");
            set_mobile_number("");
            set_email("");
            set_profile_picture_file_url("");
            set_profile_picture_file("");
            set_banner_file_url("");
            set_banner_file("");
            set_gst_file_url("");
            set_gst_file("");
            set_pan_card_file_url("");
            set_pan_card_file("");
            set_selected_categories([]);
            set_selected_services([]);
            set_selected_tags([]);
            set_about_business("");
            set_business_name("");
            set_locality("");
            set_building_number("");
            set_city("");
            set_state("");
            set_comission_rate("");
            set_bank_name("");
            set_branch("");
            set_ifsc_code("");
            set_landmark("");
            set_lat("");
            set_lon("");
            set_account_number("");
            create_profile_button_ref.current.disabled = false;
            alert("Successfully add the vendor");
          }
        })
        .catch((error) => {
          console.log(error);
          create_profile_button_ref.current.disabled = false;
        });
    } else {
      alert("Fields Are Missing!");
      name.length === 0
        ? (name_ref.current.style.border = "1px solid red")
        : (name_ref.current.style.border = "1px solid transparent");
      age.length === 0
        ? (age_ref.current.style.border = "1px solid red")
        : (age_ref.current.style.border = "1px solid transparent");
      gender.length === 0
        ? (gender_ref.current.style.border = "1px solid red")
        : (gender_ref.current.style.border = "1px solid transparent");
      mobile_number.length === 0
        ? (phone_number_ref.current.style.border = "1px solid red")
        : (phone_number_ref.current.style.border = "1px solid transparent");
      secondary_phone.length === 0
        ? (secondary_phone_ref.current.style.border = "1px solid red")
        : (secondary_phone_ref.current.style.border = "1px solid transparent");
      profile_picture_file_url.length === 0
        ? (profile_picture_ref.current.style.border = "1px solid red")
        : (profile_picture_ref.current.style.border = "1px solid transparent");
      banner_file_url.length === 0
        ? (banner_ref.current.style.border = "1px solid red")
        : (banner_ref.current.style.border = "1px solid transparent");
      business_name.length === 0
        ? (business_name_ref.current.style.border = "1px solid red")
        : (business_name_ref.current.style.border = "1px solid transparent");
      email.length === 0
        ? (email_ref.current.style.border = "1px solid red")
        : (email_ref.current.style.border = "1px solid transparent");
      gst_file_url.length === 0
        ? (gst_ref.current.style.border = "1px solid red")
        : (gst_ref.current.style.border = "1px solid transparent");
      pan_card_file_url.length === 0
        ? (pan_ref.current.style.border = "1px solid red")
        : (pan_ref.current.style.border = "1px solid transparent");
      about_business.length === 0
        ? (about_ref.current.style.border = "1px solid red")
        : (about_ref.current.style.border = "1px solid transparent");
      selected_categories.length === 0
        ? (categories_ref.current.style.border = "1px solid red")
        : (categories_ref.current.style.border = "1px solid transparent");
      selected_services.length === 0
        ? (services_ref.current.style.border = "1px solid red")
        : (services_ref.current.style.border = "1px solid transparent");

      building_number.length === 0
        ? (address_ref.current.style.border = "1px solid red")
        : (address_ref.current.style.border = "1px solid transparent");
      locality.length === 0
        ? (area_ref.current.style.border = "1px solid red")
        : (area_ref.current.style.border = "1px solid transparent");
      city.length === 0
        ? (city_ref.current.style.border = "1px solid red")
        : (city_ref.current.style.border = "1px solid transparent");
      state.length === 0
        ? (state_ref.current.style.border = "1px solid red")
        : (state_ref.current.style.border = "1px solid transparent");
      landmark.length === 0
        ? (landmark_ref.current.style.border = "1px solid red")
        : (landmark_ref.current.style.border = "1px solid transparent");
      pincode.length === 0
        ? (pincode_ref.current.style.border = "1px solid red")
        : (pincode_ref.current.style.border = "1px solid transparent");
      lat.length === 0
        ? (latitude_ref.current.style.border = "1px solid red")
        : (latitude_ref.current.style.border = "1px solid transparent");
      lon.length === 0
        ? (longitude_ref.current.style.border = "1px solid red")
        : (longitude_ref.current.style.border = "1px solid transparent");
      account_holder_name.length === 0
        ? (account_holder_name_ref.current.style.border = "1px solid red")
        : (account_holder_name_ref.current.style.border =
            "1px solid transparent");
      bank_name.length === 0
        ? (bank_name_ref.current.style.border = "1px solid red")
        : (bank_name_ref.current.style.border = "1px solid transparent");

      account_number.length === 0
        ? (account_number_ref.current.style.border = "1px solid red")
        : (account_number_ref.current.style.border = "1px solid transparent");
      ifsc_code.length === 0
        ? (ifsc_ref.current.style.border = "1px solid red")
        : (ifsc_ref.current.style.border = "1px solid transparent");
      branch.length === 0
        ? (branch_ref.current.style.border = "1px solid red")
        : (branch_ref.current.style.border = "1px solid transparent");
      const data = JSON.stringify({
        name: name,
        age: age,
        gender: gender,
        mobile_number: mobile_number,
        profile_picture_url: profile_picture_file_url,
        banner_url: banner_file_url,
        service_images: null,
        business_name: business_name,
        email: email,
        gst: gst_file_url,
        pan: pan_card_file_url,
        status: 1,
        wallet: 0,
        about: about_business,
        category_id: JSON.stringify(selected_categories),
        service_id: JSON.stringify(selected_services),
        tag_id: JSON.stringify(selected_tags),
        comission_rate: comission_rate,
        star_rating: 0,
        address: JSON.stringify([
          {
            building_number: building_number,
            locality: locality,
            city: city,
            state: state,
            landmark: landmark,
            pincode: pincode,
            latitude: lat,
            longitude: lon,
          },
        ]),
        bank_details: JSON.stringify([
          {
            bank_name: bank_name,
            account_number: account_number,
            ifsc_code: ifsc_code,
            branch: branch,
          },
        ]),
        date_of_registration:
          new Date().getFullYear() +
          "-" +
          Number(new Date().getMonth() + 1) +
          "-" +
          Number(new Date().getDay() + 1) +
          " " +
          new Date().getHours() +
          ":" +
          new Date().getMinutes() +
          ":" +
          new Date().getSeconds(),
      });
      create_profile_button_ref.current.disabled = false;
    }
  };
  const onClickEditVendor=(id)=>
  {
    set_show_edit_modal(true);
  }
  return (
    <Container>
      {show_edit_modal===false?
      <>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        VENDORS TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "6%" }}>ID</TableTitle>
          <TableTitle style={{ width: "10%" }}>Name</TableTitle>
          <TableTitle style={{ width: "10%" }}>Business Name</TableTitle>
          <TableTitle style={{ width: "10%" }}>Email</TableTitle>
          <TableTitle style={{ width: "12%" }}>Profile Picture</TableTitle>

          <TableTitle style={{ width: "12%" }}>Mobile Number</TableTitle>

          <TableTitle style={{ width: "12%" }}>City</TableTitle>

          <TableTitle style={{ width: "10%" }}>Pincode</TableTitle>

          <TableTitle style={{ width: "10%" }}>Status</TableTitle>
          <TableTitle style={{ width: "12%" }}>Update Status</TableTitle>

          <TableTitle style={{ width: "10%" }}>Actions</TableTitle>
        </TableTop>

        {current_vendor_array.map((card, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "6%", textAlign: "left" }}>
                {card.id}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                {card.name}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                {card.business_name}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                {card.email}
              </TableDetails>

              <TableDetails
                style={{
                  width: "12%",
                  height: "12%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    `https://storage.googleapis.com/p4uconsole/Vendors/${card.id}/` +
                    "profile.png"
                  }
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    //  float: "left",
                  }}
                ></img>
              </TableDetails>

              <TableDetails style={{ width: "12%", textAlign: "left" }}>
                {card.mobile_number}
              </TableDetails>

              <TableDetails style={{ width: "12%", textAlign: "left" }}>
                {JSON.parse(card.address)[0].city}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                {JSON.parse(card.address)[0].pincode}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                <label
                  style={{
                    width: "100%",
                    height: "100%",
                    color: card.status === 1 ? "green" : "red",
                    fontWeight: "bold",
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  {card.status === 1 ? "Enabled" : "Disabled"}
                </label>
              </TableDetails>
              <TableDetails
                style={{
                  width: "12%",
                  height: "12%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={card.status === 1 ? SwitchOn : SwitchOff}
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    //  float: "left",
                  }}
                  onClick={() =>
                    UpdateVendorStatus(card.mobile_number, card.status)
                  }
                ></img>
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "left" }}>
                <ActionIconsColumn>
                  <IconContainer onClick={()=>onClickEditVendor(card.id)}>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
      </>:
        <Container>
            <FormContainer>
            <form action="#">
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Information
              </h1>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={name_ref}
                  >
                    <Label htmlFor="name-input">Full Name</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      maxLength={120}
                      type="text"
                      placeholder="Vendor Name"
                      value={name}
                      onChange={(e) => onChangeName(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={business_name_ref}
                  >
                    <Label htmlFor="name-input">Business Name</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      maxLength={120}
                      type="text"
                      placeholder="Business Name"
                      value={business_name}
                      onChange={(e) => onChangeBusinessName(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={age_ref}
                  >
                    <Label htmlFor="name-input">Age</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="number"
                      placeholder="Vendor Age"
                      value={age}
                      onChange={(e) => onChangeAge(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={gender_ref}
                  >
                    <Label htmlFor="name-input">Gender</Label>
                    <Select
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      value={gender}
                      onChange={(e) => onChangeGender(e)}
                    >
                      <OptionContainer>Select The Gender...</OptionContainer>
                      <OptionContainer value="Male">Male</OptionContainer>
                      <OptionContainer value="Female">Female</OptionContainer>
                      <OptionContainer value="Others">Others</OptionContainer>
                    </Select>
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={profile_picture_ref}
                  >
                    <Label htmlFor="name-input">Profile Picture</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="file"
                      // value={profile_picture_file}
                      onChange={(e) => onChangeProfilePictureFile(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={banner_ref}
                  >
                    <Label htmlFor="name-input">Banner</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="file"
                      //value={banner_file}
                      onChange={(e) => onChangeBannerFile(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={phone_number_ref}
                  >
                    <Label htmlFor="name-input">Primary Phone Number</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Primary Phone Number"
                      value={mobile_number}
                      onChange={(e) => onChangeMobileNumnber(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={secondary_phone_ref}
                  >
                    <Label htmlFor="name-input">Secondary Phone Number</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Secondary Phone Number"
                      value={secondary_phone}
                      onChange={(e) => OnChangeSecondaryPhone(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={email_ref}
                  >
                    <Label htmlFor="name-input">Email</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => onChangeEmail(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
    
              <InputContainer
                style={{ border: "1px solid transparent" }}
                ref={about_ref}
              >
                <Label htmlFor="name-input">About Business</Label>
                <InputLarge
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #022C43",
                  }}
                  maxLength={1000}
                  type="text"
                  placeholder="About Business"
                  value={about_business}
                  onChange={(e) => onChangeAboutBusiness(e)}
                />
              </InputContainer>
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Address Information
              </h1>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={address_ref}
                  >
                    <Label htmlFor="name-input">Building Number</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Building Number"
                      value={building_number}
                      onChange={(e) => onChangeBuildingNumber(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={area_ref}
                  >
                    <Label htmlFor="name-input">Area/Locality</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Area/Locality"
                      value={locality}
                      onChange={(e) => onChangeLocality(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={state_ref}
                  >
                    <Label htmlFor="name-input">State</Label>
                    <Select
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      value={state}
                      onChange={(e) => onChangeState(e)}
                    >
                      <OptionContainer>Select The State...</OptionContainer>
                      <OptionContainer value="Tamil Nadu">
                        Tamil Nadu
                      </OptionContainer>
                    </Select>
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={city_ref}
                  >
                    <Label htmlFor="name-input">City</Label>
                    <Select
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      value={city}
                      onChange={(e) => onChangeCity(e)}
                    >
                      <OptionContainer>Select The City...</OptionContainer>
                      <OptionContainer value="Chennai">Chennai</OptionContainer>
                      <OptionContainer value="Coimbatore">
                        Coimbatore
                      </OptionContainer>
                    </Select>
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={latitude_ref}
                  >
                    <Label htmlFor="name-input">Latitude</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Latitude"
                      value={lat}
                      onChange={(e) => onChangeLatitude(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={longitude_ref}
                  >
                    <Label htmlFor="name-input">Longitude</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Longitude"
                      value={lon}
                      onChange={(e) => onChangeLongitude(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={pincode_ref}
                  >
                    <Label htmlFor="name-input">Pincode</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Pincode"
                      value={pincode}
                      onChange={(e) => onChangePincode(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={landmark_ref}
                  >
                    <Label htmlFor="name-input">Landmark</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Landmark"
                      value={landmark}
                      onChange={(e) => onChangeLandmark(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Category/Service
              </h1>
              <TwoColumn>
                <Column>
                  {categories.length > 0 ? (
                    <InputContainer
                      style={{ border: "1px solid transparent" }}
                      ref={categories_ref}
                    >
                      <Label htmlFor="name-input">Select Categories</Label>
                      {categories.map((item, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <img
                            src={
                              selected_categories.findIndex(
                                (e) => e === item.id
                              ) !== -1
                                ? CheckedBoxIcon
                                : UncheckedBoxIcon
                            }
                            onClick={() => onSelectCategory(item.id)}
                          />
                          <label style={{ color: "#000000" }}>{item.name}</label>
                        </div>
                      ))}
                    </InputContainer>
                  ) : null}
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={services_ref}
                  >
                    {services_per_categories.length > 0 ? (
                      <>
                        <Label htmlFor="name-input">Select Services</Label>
                        {services_per_categories.map((item, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <img
                              src={
                                selected_services.findIndex(
                                  (e) => e === item.id
                                ) !== -1
                                  ? CheckedBoxIcon
                                  : UncheckedBoxIcon
                              }
                              onClick={() => onSelectService(item.id)}
                            />
                            <label style={{ color: "#000000" }}>{item.name}</label>
                          </div>
                        ))}
                      </>
                    ) : (
                      <label style={{ color: "#000000", textAlign: "center" }}>
                        No Services Available.....
                      </label>
                    )}
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  {tags.length > 0 ? (
                    <InputContainer
                      style={{ border: "1px solid transparent" }}
                      ref={tag_ref}
                    >
                      <Label htmlFor="name-input">Select Tags</Label>
                      {tags.map((item, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <img
                            src={
                              selected_tags.findIndex((e) => e === item.id) !== -1
                                ? CheckedBoxIcon
                                : UncheckedBoxIcon
                            }
                            onClick={() => onSelectTag(item.id)}
                          />
                          <label style={{ color: "#000000" }}>{item.name}</label>
                        </div>
                      ))}
                    </InputContainer>
                  ) : null}
                </Column>
              </TwoColumn>
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Commision Rate
              </h1>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={comission_ref}
                  >
                    <Label htmlFor="name-input">Commision Rate</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Commision Rate"
                      value={comission_rate}
                      onChange={(e) => onChangeComissionRate(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Business Documents
              </h1>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={gst_ref}
                  >
                    <Label htmlFor="name-input">GST Certificate</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="file"
                      // value={gst_file}
                      onChange={(e) => onChangeGstFile(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={pan_ref}
                  >
                    <Label htmlFor="name-input">PAN Certificate</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="file"
                      onChange={(e) => onChangePanCardFile(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <h1 style={{ color: "#000000", fontWeight: "bold", fontSize: 25 }}>
                Service Provider Business Documents
              </h1>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={bank_name_ref}
                  >
                    <Label htmlFor="name-input">Bank Name</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Bank Name"
                      value={bank_name}
                      onChange={(e) => onChangeBankName(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={ifsc_ref}
                  >
                    <Label htmlFor="name-input">IFSC Code</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="IFSC Code"
                      value={ifsc_code}
                      onChange={(e) => onChangeIFSCCode(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={account_holder_name_ref}
                  >
                    <Label htmlFor="name-input">Account Holder Name</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Account Holder Name..."
                      value={account_holder_name}
                      onChange={(e) => onChangeAccountHolderName(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={account_number_ref}
                  >
                    <Label htmlFor="name-input">Account Number</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Account Number"
                      value={account_number}
                      onChange={(e) => onChangeAccountNumber(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={branch_ref}
                  >
                    <Label htmlFor="name-input">Branch</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Branch"
                      value={branch}
                      onChange={(e) => onChangeBranch(e)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer
                    style={{ border: "1px solid transparent" }}
                    ref={branch_ref}
                  >
                    <Label htmlFor="name-input">UPI ID</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="UPI ID"
                      value={upi_id}
                      onChange={(e) => onChangeUPIId(e)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <TwoColumn>
            <Column>
                <Button
                  ref={create_profile_button_ref}
                  onClick={() => onSaveVendorInformation()}
                >
                  Save Profile
                </Button>
              </Column>
              <Column>
                <Button
                 onClick={() => set_show_edit_modal(false)}
                >
                  Cancel
                </Button>
              </Column>
              </TwoColumn>
            </form>
          </FormContainer>
            </Container>
      }
    </Container>
  );
};
