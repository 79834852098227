import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { PrimaryButton, Link2 } from "./MiscStyles/Buttons";

import { useSelector, useDispatch } from "react-redux";

import Dashboard from "./ContentPage/Dashboard";
import Categorytable from "./ContentPage/Categorytable";
import CreateProfile from "./ContentPage/CreateProfile";
import ServicesTable from "./ContentPage/ServicesTable";
import CustomersTable from "./ContentPage/CustomersTable";
import VendorsTable from "./ContentPage/VendorsTable";
import BookingTable from "./ContentPage/BookingTable";
import TagTable from "./ContentPage/TagTable";
import BannerTable from "./ContentPage/BannerTable";
import ProductsTable from "./ContentPage/ProductsTable";
import CouponTable from "./ContentPage/CouponTable";
import NewsFeedTable from "./ContentPage/NewsFeedTable";
import NotificationTable from "./ContentPage/NotificationTable";
import AddCategory from "./ContentPage/AddCategory";
import AddBanner from './ContentPage/AddBanner';
import AddNewsFeed from './ContentPage/AddNewsFeed';
import AddNotification from './ContentPage/AddNotification';
import AddCoupons from './ContentPage/AddCoupons';
import KalamRegistrations from './ContentPage/KalamRegistrations'
import VendorsRequestTable from "./ContentPage/VendorsRequestTable";
import ProductsRequestTable from "./ContentPage/ProductsRequestTable";
import SettlementsTable from "./ContentPage/SettlementsTable";
import SignIn from "./SignIn";
import { populate_categories } from "./Redux/CategoriesSlice";
import { populate_news_feed } from "./Redux/NewsFeedSlice";
import { populate_services } from "./Redux/ServicesSlice";
import { populate_tags } from "./Redux/TagsSlice";
import { populate_banners } from "./Redux/BannersSlice";
import { populate_coupons } from "./Redux/CouponsSlice";
import axios from "axios";
import { populate_providers } from "./Redux/ProvidersSlice";
import { populate_products } from "./Redux/ProductsSlice";
import { populate_metadata } from "./Redux/MetadataSlice";
import { populate_notifications } from "./Redux/NotificationsSlice";
import { populate_customers } from "./Redux/CustomersSlice";
import {populate_orders} from './Redux/OrdersSlice';
import { populate_counts } from "./Redux/CounterSlice";
import { populate_vendors_request } from "./Redux/VendorsRequestSlice";
import { populate_products_request } from "./Redux/ProductsRequestSlice";
import { populate_settlements } from "./Redux/SettlementsSlice";
import AddServices from "./ContentPage/AddServices";
import AddTags from "./ContentPage/AddTags";
import AddProducts from './ContentPage/AddProducts';
const Container = tw.div`w-10/12 flex justify-center `;
const Content = tw.div`fixed right-0 w-10/12 `;
const TestDisplayArea = tw.div`py-40 bg-gray-600`;
const Text = tw.p`text-blue-700 font-bold text-2xl text-center`;

export default () => {
  const dispatch = useDispatch();
  const [home, setHome] = useState(false);
  const [category, setCategory] = useState(false);
  const [service, setService] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [provide, setProvide] = useState(false);
  const [advertisement, setAdvertisement] = useState(false);
  const [tag, setTag] = useState(false);
  const [banner, setBanner] = useState(false);
  useEffect(() => {
    GetMetaData();
     GetNewsFeed();
     GetCategories();
     GetServices();
    // GetTags();
     GetVendors();
    //GetBanners();
     GetProducts();
     GetCoupons();
    GetNotifications();
    GetCustomers();
    // GetOrders();
    // GetVendorsRequest();
    // GetProductsRequest();
    // GetSettlements();
  }, []);
  const GetMetaData=()=>
  {
    //Get Customers count
    GetCount("customer_count");
    //Get Orders Count
    GetCount("order_count");
    //Get Categories count
    GetCount("category_count");
    //Get Services Count
    GetCount("service_count");
    //Get Products count
    GetCount("product_count");
    //Get Vendors Count
    GetCount("provider_count");
    //Get Tags count
    GetCount("tag_count");
    //Get Coupons Count
    GetCount("coupon_count");
    //Get News Feed count
    GetCount("news_feed_count");
    //Get Vendors Request Count
    GetCount("vendors_request_count");
    //Get Products Request Count
    GetCount("products_request_count");
    //Get Notification Count
    GetCount("notification_count");
    //Get Banner Count
    GetCount("banner_count");
  //Get Settlement Count
  GetCount("settlement_count");
  }
  const GetCount=(_topic_name)=>
  {
    axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/${_topic_name}`)
    .then((response)=>
    {
      if(response.status===200)
      {
        switch(_topic_name)
        {
        case "customer_count":
        dispatch(populate_counts({type:"Customers",sol:response.data[0].totalCount}));
        break;
        case "provider_count":
        dispatch(populate_counts({type:"Providers",sol:response.data[0].totalCount}));
        break;
        case "order_count":
        dispatch(populate_counts({type:"Orders",sol:response.data[0].order_count}));
        break;
        case "category_count":
        dispatch(populate_counts({type:"Categories",sol:response.data[0].totalCount}));
        break;
        case "service_count":
        dispatch(populate_counts({type:"Services",sol:response.data[0].totalCount}));
        break;
        case "product_count":
        dispatch(populate_counts({type:"Products",sol:response.data[0].totalCount}));
        break;
        case "banner_count":
        dispatch(populate_counts({type:"Banners",sol:response.data[0].totalCount}));
        break;
        case "news_feed_count":
        dispatch(populate_counts({type:"NewsFeed",sol:response.data[0].news_feed_count}));
        break;
        case "notification_count":
        dispatch(populate_counts({type:"Notifications",sol:response.data[0].notification_count}));
        break;
        case "vendors_request_count":
        dispatch(populate_counts({type:"VendorRequests",sol:response.data[0].vendors_request_count}));
        break;
        case "products_request_count":
        dispatch(populate_counts({type:"ProductRequests",sol:response.data[0].products_request_count}));
        break;
        case "coupon_count":
        dispatch(populate_counts({type:"Coupons",sol:response.data[0].coupon_count}));
        break;
        case "tag_count":
        dispatch(populate_counts({type:"Tags",sol:response.data[0].tag_count}));
        break;
        case "settlement_count":
        dispatch(populate_counts({type:"Settlements",sol:response.data[0].settlement_count}));
        break;
        }
      }
      else
      {
        //alert(`Error Fetching ${_topic_name}:`+response.message);
      }
    })
    .catch((error)=>
    {
      //alert(`Error Fetching ${_topic_name}:`+error.message);
    })
  }
  const GetSettlements=()=>
  {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/settlements?limit=10&offset=0"
      )
      .then((response) => {
        if(response.status===200)
        {
          let temp=[];
          for(let i=0;i<response.data.length;i++)
          {
            temp.push(response.data[i]);
          }
          dispatch(populate_settlements(temp));
        }
        else
        {
          alert(response.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }
  const GetNewsFeed = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/news_feed?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_news_feed(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetCategories = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/categories?limit=50&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_categories(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetServices = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/services?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_services(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetProducts = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/products?limit=9&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_products(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetTags = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/tags?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_tags(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetVendors = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/vendors?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_providers(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetBanners = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/banners?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_banners(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetCoupons = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/coupons?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_coupons(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetNotifications = () => {
    axios
      .get(
        "https://node-service-app-ifox3xnafa-el.a.run.app/notifications?limit=10&offset=0"
      )
      .then((response, result) => {
        dispatch(populate_notifications(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetCustomers = () => {
    axios
      .get(
        `https://node-service-app-ifox3xnafa-el.a.run.app/customers?limit=10&offset=0`
      )
      .then((response, result) => {
        dispatch(populate_customers(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const GetOrders=()=>{
    axios
      .get(
        `https://node-service-app-ifox3xnafa-el.a.run.app/orders?limit=10&offset=0`
      )
      .then((response, result) => {
        dispatch(populate_orders(response.data));
      })
      .catch((error) => {
        alert(error.message);
      });
  }
  const GetVendorsRequest=()=>
  {
    axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/vendors_request?limit=${10}&offset=${0}`)
    .then((response)=>
    {
      if(response.status===200)
      {
        let temp=[];
        for(let i=0;i<response.data.length;i++)
        {
          temp.push(response.data[i]);
        }
        dispatch(populate_vendors_request(temp));
      }
      else
      {
        alert(response.message);
      }
    })
    .catch((error)=>
    {
      alert(error.message);
    })
  }
  const GetProductsRequest=()=>
  {
    axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/products_request?limit=${10}&offset=${0}`)
    .then((response)=>
    {
      if(response.status===200)
      {
        let temp=[];
        for(let i=0;i<response.data.length;i++)
        {
          temp.push(response.data[i]);
        }
        dispatch(populate_products_request(temp));
      }
      else
      {
        alert(response.message);
      }
    })
    .catch((error)=>
    {
      alert(error.message);
    })
  }
  const number = useSelector((state) => state.callComponent.value);
  const EnableDisable = (val) => {
    setHome(false);
    setCategory(false);
    setService(false);
    setCustomer(false);
    setProvide(false);
    setAdvertisement(false);
    setTag(false);
    setBanner(false);
    switch (val) {
      case 1:
        setHome(true);
        break;
      case 2:
        setCategory(true);
        break;
      case 3:
        setService(true);
        break;
      case 4:
        setCustomer(true);
        break;
      case 5:
        setProvide(true);
        break;
      case 6:
        setAdvertisement(true);
        break;
      case 7:
        setAdvertisement(true);
        break;
      case 8:
        setAdvertisement(true);
        break;
      case 9:
        setTag(true);
        break;
      case 10:
        setBanner(true);
        break;
      case 11:
        setBanner(true);
        break;
    }
  };
  useEffect(() => {
    console.log(number);
    EnableDisable(number);
  }, []);

  return (
    <Container style={{ backgroundColor: "#FFFFFF" }}>
      {number === 0 ? <SignIn /> : null}
      {number === 1 ? <Dashboard /> : null}
      {number === 2 ? <CreateProfile /> : null}
      {number === 3 ? <BookingTable /> : null}
      {number === 4 ? <Categorytable /> : null}
      {number === 5 ? <ServicesTable /> : null}
      {number === 6 ? <CustomersTable /> : null}
      {number === 7 ? <VendorsTable /> : null}
      {number === 9 ? <TagTable /> : null}
      {number === 10 ? <BannerTable /> : null}
      {number === 11 ? <ProductsTable /> : null}
      {number === 12 ? <CouponTable /> : null}
      {number === 13 ? <NewsFeedTable /> : null}
      {number === 14 ? <NotificationTable /> : null}
      {number === 15 ? <AddCategory /> : null}
      {number === 16 ? <AddServices /> : null}
      {number === 17 ? <AddTags /> : null}
      {number === 18 ? <AddBanner /> : null}
      {number === 19 ? <AddProducts /> : null}
      {number === 20 ? <AddNewsFeed /> : null}
      {number === 21 ? <AddNotification /> : null}
      {number === 22 ? <AddCoupons /> : null}
      {number === 23 ? <KalamRegistrations /> : null}
      {number === 24 ? <VendorsRequestTable/> : null}
      {number === 25 ? <ProductsRequestTable/> : null}
      {number === 26 ? <SettlementsTable/> : null}
      {number === 8 ? <Text>ADVERTISEMENTS AREA</Text> : null}
    </Container>
  );
};
