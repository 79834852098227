// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfQ-0baPOXaa31xnQXranIIwvHC2zbmiE",
  authDomain: "p4u-console.firebaseapp.com",
  projectId: "p4u-console",
  storageBucket: "p4u-console.appspot.com",
  messagingSenderId: "784503032650",
  appId: "1:784503032650:web:8c3d03418db7d594028fb3",
  measurementId: "G-RX9CW0VKL0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app);