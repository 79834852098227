import React,{useState,useEffect}from "react";
import tw from "twin.macro";
import styled from "styled-components";
import logo from "../Images/logo.png";
import { useDispatch } from "react-redux";
import { componentReducer } from "./Redux/ComponentSlice";
import {signInWithEmailAndPassword } from "firebase/auth";
import {auth} from '../firebase.js';
const Container = tw.div`flex items-center pt-32 px-64`;
const LeftContainer = tw.div`w-1/2 flex justify-center`;
const RightContainer = tw.div`w-1/2 flex justify-center  border-l-4 border-white`;
const Heading = tw.div`text-3xl text-center font-semibold text-textprimary`;


const FormContainer = styled.div`
  ${tw`  text-gray-100 rounded-lg relative  `}
  form {
    ${tw`  pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-white top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;

const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;

const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex 
  justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-textprimary hocus:bg-primaryOrange text-primaryBlue 
  shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
  const dispatch=useDispatch();
  const[user_email,set_user_email]=useState("");
  const onChangeUsername=(e)=>
  {
    set_user_email(e.target.value);
  }
  const[user_password,set_user_password]=useState("");
  const onChangeUserpassword=(e)=>
  {
    set_user_password(e.target.value);
  }
  const sign_in=()=>
  {
    if(user_email.length>0 && user_password.length>0)
    {
    signInWithEmailAndPassword(auth, user_email, user_password)
    .then((userCredential) => {
      alert('Successfully Signed In');
      const user = userCredential.user;
      //dispatch(componentReducer(1));
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(error.message);
    });
  }
  }
  return (
    <Container>
      <LeftContainer>
        <img src={logo} width="30%" />
      </LeftContainer>
      <RightContainer>
        <FormContainer>
          <Heading>Fill Details to Login</Heading>
          <form action="#">
            <InputContainer>
              <Label htmlFor="name-input">Phone Number</Label>
              <Input
                id="email"
                type="text"
                name="email"
                placeholder="Email"
                value={user_email}
                onChange={(e)=>onChangeUsername(e)}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "123456789")}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="name-input">Password</Label>
              <Input
                id="company-name"
                type="password"
                name="companyname"
                placeholder="Password"
                value={user_password}
                onChange={(e)=>onChangeUserpassword(e)}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "Password")}

              />
            </InputContainer>

            <Actions>
              <Link1 
              style={{cursor:"pointer"}}
              onClick={()=>sign_in()}
              >Login</Link1>
            </Actions>
          </form>
        </FormContainer>
      </RightContainer>
    </Container>
  );
};
