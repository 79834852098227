import React,{useState,useRef,useEffect} from "react";
import tw from "twin.macro";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {useSelector,useDispatch} from 'react-redux'
import {populate_orders} from '../Redux/OrdersSlice';
import {populate_settlements} from '../Redux/SettlementsSlice';
import axios from "axios";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
  const dispatch=useDispatch();
  const orders=useSelector((state)=>state.ordersComponent.value);
  const settlements=useSelector((state)=>state.settlementsComponent.value);
    //Pagination
    const counter=useSelector((state)=>state.counterComponent.value);
    const[current_order_array,set_current_order_array]=useState([]);
    const[filter_order_array,set_filter_order_array]=useState([]);
    const current_page=useRef(1);
    const total_pages=useRef(Math.ceil(Number(counter.orders_count/10)));
    //Load The First 10 elements of the table
    useEffect(()=>
    {
      let temp=[];
      if(orders.length<10)
      {
        for(let i=0;i<orders.length;i++)
        {
          temp.push(orders[i]);
        }
      }
      else
      {
      for(let i=0;i<10;i++)
      {
        temp.push(orders[i]);
      }
      }
      set_current_order_array(temp);
      set_filter_order_array(temp);
    },[]);
    const onPressNext=()=>
    {
      if(current_page.current+1<=total_pages.current)
      {
        if(orders.length>=(current_page.current+1)*10)
        {
          let temp=[];
          for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
          {
            temp.push(orders[i]);
          }
          current_page.current+=1;
          set_current_order_array(temp);
          set_filter_order_array(temp);
          set_selected_mode("All");
        }
        else
        {
          axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/orders?limit=10&offset=${current_page.current*10}`)
          .then((response)=>
          {
            if(response.status===200)
            {
              let temp=[];
              let temp_all=[];
              for(let i=0;i<orders.length;i++)
              {
                temp_all.push(orders[i]);
              }
              for(let i=0;i<response.data.length;i++)
              {
                temp_all.push(response.data[i]);
                temp.push(response.data[i]);
              }
              current_page.current+=1;
              set_current_order_array(temp);
              set_filter_order_array(temp);
              set_selected_mode("All");
              dispatch(populate_orders(temp_all));
            }
            else
            {
              alert(response.message);
            }
          }).catch((error)=>
          {
            alert(error.message);
          })
        }
      }
    }
    const onPressPrevious=()=>
    {
      if(current_page.current-1>0)
      {
       let temp=[];
       for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
       {
        temp.push(orders[i]);
       }
       current_page.current-=1;
       set_current_order_array(temp);
       set_filter_order_array(temp);
       set_selected_mode("All");
      }
    }
    ///////////////////////////////////////////////////////////////////////
    const ReturnTheOrderStatus=(_status)=>
    {
      switch(_status)
      {
        case 0:
          return "Pending";
        case 1:
          return "Accepted";
        case 2:
          return "Rejected";
        case 3:
          return "Completed";
      }
    }
    const [selected_mode,set_selected_mode]=useState("All");
    const onChangeMode=(_mode)=>
    {
      set_selected_mode(_mode);
      let temp=[];
      switch(_mode)
      {
        case "All":
          for(let i=0;i<current_order_array.length;i++)
          {
            temp.push(current_order_array[i]);
          }
          set_filter_order_array(temp);
          break;
        case "Pending":
          for(let i=0;i<current_order_array.length;i++)
          {
            if(current_order_array[i].status===0)
            {
              temp.push(current_order_array[i]);
            }
          }
          set_filter_order_array(temp);
          break;
        case "Accepted":
          for(let i=0;i<current_order_array.length;i++)
          {
            if(current_order_array[i].status===1)
            {
              temp.push(current_order_array[i]);
            }
          }
          set_filter_order_array(temp);
          break;
        case "Rejected":
          for(let i=0;i<current_order_array.length;i++)
          {
            if(current_order_array[i].status===2)
            {
              temp.push(current_order_array[i]);
            }
          }
          set_filter_order_array(temp);
          break;
        case "Completed":
          for(let i=0;i<current_order_array.length;i++)
          {
            if(current_order_array[i].status===3)
            {
              temp.push(current_order_array[i]);
            }
          }
          set_filter_order_array(temp);
          break;
      }
    }
    const[display_invoice,set_display_invoice]=useState(false);
    const[display_settlement_form,set_display_settlement_form]=useState(false);
    const[selected_id,set_selected_id]=useState("");
    const onClickedPreview=(_id)=>
    {
      set_selected_id(_id);
      set_display_invoice(true);
    }
    const onClickSettleTheAmount=(_id)=>
    {
      set_selected_id(_id);
      set_display_settlement_form(true);
    }
    const[amount,set_amount]=useState("");
    const onChangeAmount=(e)=>
    {
      set_amount(e.target.value);
    }
    const[transaction_id,set_transaction_id]=useState("");
    const onChangeTransactionID=(e)=>
    {
      set_transaction_id(e.target.value);
    }
    const[transaction_receipt_url,set_transaction_receipt_url]=useState("");
    const onChangePicture=(e)=>
    {
      let form_data = new FormData();
      form_data.append("file", e.target.files[0]);
      axios
        .post(
          "https://node-service-app-ifox3xnafa-el.a.run.app/upload/Settlements/nothing",
          form_data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ).then((response)=>
        {
          if(response.status===200)
          {
          set_transaction_receipt_url(e.target.files[0].name);
          }
          else
          {
            alert(response.message);
          }
        }).catch((error)=>
        {
          alert(error.message);
        })
    }
    const[payment_mode,set_payment_mode]=useState("");
    const onChangePaymentMode=(e)=>
    {
      set_payment_mode(e.target.value);
    }
    const onSubmitButtonClicked=()=>
    {
      if(amount.length>0 && transaction_id.length>0 && transaction_receipt_url.length>0 && payment_mode.length>0)
      {
        axios.post(`https://node-service-app-ifox3xnafa-el.a.run.app/settlements`,
        {
          amount:amount,
          order_id:selected_id,
          vendor_id:current_order_array.find(e=>e.id===selected_id).vendor_id,
          payment_mode:payment_mode,
          transaction_picture_url:transaction_receipt_url,
          date:new Date().getFullYear()+"-"+(Number(new Date().getMonth())+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
          transaction_id:transaction_id
        }).then((response)=>
        {
          if(response.status==200)
          {
            axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/updateorders/${selected_id}`,
            {settlement_status:1})
            .then((response1)=>
            
          {if(response1.status===200)
            {
              let temp=current_order_array.find(e=>e.id===selected_id);
              let new_element={
                id:temp.id,
                customer_id:temp.customer_id,
                vendor_id:temp.vendor_id,
                items:temp.items,
                cost:temp.cost,
                creation_time:temp.creation_time,
                status:temp.status,
                transaction_id:temp.transaction_id,
                settlement_status:1,
                expected_delivery:temp.expected_delivery,
                customer_first_name:temp.customer_first_name,
                customer_last_name:temp.customer_last_name,
                customer_mobile_number:temp.customer_mobile_number,
                vendor_name:temp.vendor_name,
                vendor_mobile_number:temp.vendor_mobile_number,
                customer_address:temp.customer_address
              }
              let temp_array=[];
              for(let i=0;i<current_order_array.length;i++)
              {
                temp_array.push(current_order_array[i]);
              }
              temp_array.splice(temp_array.findIndex(e=>e.id===selected_id),1);
              temp_array.push(new_element);
              set_current_order_array(temp_array);
            }
            else
            {
              alert(response.message);
            }
          }
            ).catch((error)=>
            {
              alert(error.message);
            })
            let temp=[];
            temp.push({
              amount:amount,
              order_id:selected_id,
              transaction_picture_url:transaction_receipt_url,
              transaction_id:transaction_id,
              date:new Date().getFullYear()+"-"+(Number(new Date().getMonth())+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
              payment_mode:payment_mode,
              name:current_order_array.find(e=>e.id===selected_id).vendor_name,
              mobile_number:current_order_array.find(e=>e.id===selected_id).vendor_mobile_number
            })
            for(let i=0;i<settlements.length;i++)
            {
              temp.push(settlements[i]);
            }
            dispatch(populate_settlements(temp));
            set_amount("");
            set_payment_mode("");
            set_transaction_id("");
            set_transaction_receipt_url("");
            set_display_settlement_form(false);
          }
          else
          {
            alert(response.message);
          }
        }).catch((error)=>
        {
          alert(error.message);
        })
      }
      else
      {
        alert('Fields are missing!!');
      }
    }
    const onResetButtonClicked=()=>
    {
      set_amount("");
      set_payment_mode("");
      set_transaction_id("");
      set_transaction_receipt_url("");
      set_display_settlement_form(false);
    }
  return (
    <Container>
      {display_invoice===false && display_settlement_form===false?
      <>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        BOOKING TABLE{" "}
      </h1>
      <div style={{width:"100%",height:40,display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
        <button onClick={()=>onChangeMode("All")} style={{width:"19%",height:40,backgroundColor:selected_mode==="All"?"#022C43":"#F5F5F5",border:"1px solid #022C43",borderRadius:8,color:selected_mode==="All"?"#F5F5F5":"#022C43",fontSize:16,fontWeight:"bold"}}>All</button>
        <button onClick={()=>onChangeMode("Pending")} style={{width:"19%",height:40,backgroundColor:selected_mode==="Pending"?"#022C43":"#F5F5F5",border:"1px solid #022C43",borderRadius:8,color:selected_mode==="Pending"?"#F5F5F5":"#022C43",fontSize:16,fontWeight:"bold"}}>Pending</button>
        <button onClick={()=>onChangeMode("Accepted")} style={{width:"19%",height:40,backgroundColor:selected_mode==="Accepted"?"#022C43":"#F5F5F5",border:"1px solid #022C43",borderRadius:8,color:selected_mode==="Accepted"?"#F5F5F5":"#022C43",fontSize:16,fontWeight:"bold"}}>Accepted</button>
        <button onClick={()=>onChangeMode("Completed")} style={{width:"19%",height:40,backgroundColor:selected_mode==="Completed"?"#022C43":"#F5F5F5",border:"1px solid #022C43",borderRadius:8,color:selected_mode==="Completed"?"#F5F5F5":"#022C43",fontSize:16,fontWeight:"bold"}}>Completed</button>
        <button onClick={()=>onChangeMode("Rejected")} style={{width:"19%",height:40,backgroundColor:selected_mode==="Rejected"?"#022C43":"#F5F5F5",border:"1px solid #022C43",borderRadius:8,color:selected_mode==="Rejected"?"#F5F5F5":"#022C43",fontSize:16,fontWeight:"bold"}}>Rejected</button>
      </div>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "9%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "13%" }}>Order ID</TableTitle>

          <TableTitle style={{ width: "13%" }}>Order Cost</TableTitle>

          <TableTitle style={{ width: "13%" }}>Order Status</TableTitle>

          <TableTitle style={{ width: "13%" }}>Order Transaction ID</TableTitle>

          <TableTitle style={{ width: "13%" }}>Order Creation Time</TableTitle>
          <TableTitle style={{ width: "13%" }}>Preview Data</TableTitle>
          <TableTitle style={{ width: "13%" }}>Take Action</TableTitle>
        </TableTop>

        {filter_order_array.length>0?
        filter_order_array.map((card, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "9%",textAlign:"center"}}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "13%",textAlign:"center" }}>
                {card.id}
              </TableDetails>

              <TableDetails style={{ width: "13%",textAlign:"center"}}>
                {card.cost}
              </TableDetails>

              <TableDetails style={{  width: "13%",textAlign:"center" }}>
                {ReturnTheOrderStatus(card.status)}
              </TableDetails>

              <TableDetails style={{  width: "13%",textAlign:"center" }}>
                {card.transaction_id}
              </TableDetails>

              

              <TableDetails style={{  width: "13%",textAlign:"center" }}>
                {new Date(card.creation_time).getFullYear()+"-"+new Date(card.creation_time).getMonth()+"-"+new Date(card.creation_time).getDate()+" "+new Date(card.creation_time).getHours()+":"+new Date(card.creation_time).getMinutes()+":"+new Date(card.creation_time).getSeconds()}
              </TableDetails>
              <TableDetails style={{ width: "13%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <button style={{width:"80%",alignSelf:"center",height:30,backgroundColor:"#022C43",color:"#F5F5F5",fontSize:15,fontWeight:"bold",border:"1px solid #022C43",borderRadius:8}} onClick={()=>onClickedPreview(card.id)}>Preview Data</button>
              </TableDetails>
              
              {card.status===0?<TableDetails style={{ width: "13%",display:"flex",flexDirection:"column",justifyContent:"center" }}>
                <button style={{alignSelf:"center",width:"80%",height:30,backgroundColor:"#022C43",color:"#F5F5F5",fontSize:15,fontWeight:"bold",border:"1px solid #022C43",borderRadius:8}}>Take Action</button>
              </TableDetails>:null}
            {card.status===2?<TableDetails style={{ width: "13%",display:"flex",flexDirection:"column",justifyContent:"center" }} >
                <button style={{alignSelf:"center",width:"80%",height:30,backgroundColor:"#022C43",color:"#F5F5F5",fontSize:15,fontWeight:"bold",border:"1px solid #022C43",borderRadius:8}}>Take Action</button>
              </TableDetails>:null}
              {card.status===1?<TableDetails style={{ width: "13%",display:"flex",flexDirection:"column",justifyContent:"center" }}>
                <button disable={true} style={{alignSelf:"center",width:"80%",height:30,backgroundColor:"transparent",color:"transparent",fontSize:15,fontWeight:"bold",borderRadius:8}}>Take Action</button>
              </TableDetails>:null}
            {card.status===3?<TableDetails style={{ width: "13%",display:"flex",flexDirection:"column",justifyContent:"center" }} onClick={()=>onClickSettleTheAmount(card.id)}>
                {card.settlement_status===0?
                <button style={{alignSelf:"center",width:"80%",height:30,backgroundColor:"#022C43",color:"#F5F5F5",fontSize:15,fontWeight:"bold",border:"1px solid #022C43",borderRadius:8}}>Settle Payment</button>
                :
                <label style={{alignSelf:"center",width:"80%",height:30,color:"#022C43",fontSize:15,fontWeight:"bold"}}>Payment Settled</label>
                }
              </TableDetails>:null}

            </Card>
          </Column>
        )):
        <label style={{width:"100%",height:30,color:"#022C43",fontWeight:"bold",fontSize:20,alignSelf:"center",textAlign:"center"}}>
        No orders yet.....
        </label>}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
      </>:
      display_invoice===true?
      <div style={{width:"100%",display:"flex",flexDirection:"column",alignSelf:"center"}}>
        <div style={{width:"100%",height:50,display:"flex",flexDirection:"row",justifyContent:"space-around",borderBottom:"1px solid #000000"}}>
          <label style={{width:"23%",color:"#022C43",fontWeight:"bold",fontSize:16,textAlign:"center"}}>
            Order ID:{selected_id}
          </label>
          <label style={{width:"23%",color:"#022C43",fontWeight:"bold",fontSize:16,textAlign:"center"}}>
            Date:{new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getFullYear()+"-"+new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getMonth()+"-"+new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getDate()+" "+new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getHours()+":"+new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getMinutes()+":"+new Date(current_order_array.find(e=>e.id===Number(selected_id)).creation_time).getSeconds()}
          </label>
          <label style={{width:"23%",color:"#022C43",fontWeight:"bold",fontSize:16,textAlign:"center"}}>
            Customer Mobile Number:{current_order_array.find(e=>e.id===Number(selected_id)).customer_mobile_number}
          </label>
          <label style={{width:"23%",color:"#022C43",fontWeight:"bold",fontSize:16,textAlign:"center"}}>
            Customer Name:{current_order_array.find(e=>e.id===Number(selected_id)).customer_first_name}{' '}{current_order_array.find(e=>e.id===Number(selected_id)).customer_last_name}
          </label>
        </div>
        <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Order Items</label>
        <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-around",borderBottom:"0.4px solid #022C43"}}>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
            Name
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
           Quantity
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
          Cost
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
          Discount
          </label>
          </div>
        {
          JSON.parse(current_order_array.find(e=>e.id===Number(selected_id)).items).map((item,index)=>
          <div key={index} style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
            {item.name}
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
           {item.quantity}
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
            {item.cost}
          </label>
          <label style={{width:"23%",height:30,color:"#022C43",fontSize:16,textAlign:"center"}}>
            {item.discount}
          </label>
          </div>)
        }
           <div style={{width:"100%",borderBottom:"1px solid #022C43",marginTop:3,marginBottom:3}}/>
           <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Cost:{current_order_array.find(e=>e.id===Number(selected_id)).cost}</label>
           <div style={{width:"100%",borderBottom:"1px solid #022C43",marginTop:3,marginBottom:3}}/>
           <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Expected Delivery:{current_order_array.find(e=>e.id===Number(selected_id)).expected_delivery==="Anytime"?"Anytime":JSON.parse(current_order_array.find(e=>e.id===Number(selected_id)).expected_delivery).appointed_date+" "+JSON.parse(current_order_array.find(e=>e.id===Number(selected_id)).expected_delivery).appointed_time}</label>
           <div style={{width:"100%",borderBottom:"1px solid #022C43",marginTop:3,marginBottom:3}}/>
           <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Service Address:{JSON.parse(current_order_array.find(e=>e.id===Number(selected_id)).customer_address).find(e=>e.primary_address===true).address}</label>
           <div style={{width:"100%",borderBottom:"1px solid #022C43",marginTop:3,marginBottom:3}}/>
           <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Vendor Details</label>
           <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",borderBottom:"0.4px solid #022C43"}}>
          <label style={{width:"45%",height:30,color:"#022C43",fontSize:16,textAlign:"center",fontWeight:"bold"}}>
            Name:{current_order_array.find(e=>e.id===Number(selected_id)).vendor_name}
          </label>
          <label style={{width:"45%",height:30,color:"#022C43",fontSize:16,textAlign:"center",fontWeight:"bold"}}>
           Mobile Number:{current_order_array.find(e=>e.id===Number(selected_id)).vendor_mobile_number}
          </label>
          </div>
           <label style={{width:"90%",alignSelf:"center",fontWeight:"bold",fontSize:18,color:"#022C43",marginTop:2,marginBottom:2}}>Status:{ReturnTheOrderStatus(current_order_array.find(e=>e.id===Number(selected_id)).status)}</label>
           <div style={{width:"100%",borderBottom:"1px solid #022C43",marginTop:3,marginBottom:3}}/>
           <button onClick={()=>set_display_invoice(false)} style={{width:"20%",height:40,alignSelf:"center",backgroundColor:"#022C43",color:"#F5F5F5",textAlign:"center",fontSize:20,fontWeight:"bold",marginTop:5,marginBottom:5,borderRadius:12}}>
           Back
          </button>
      </div>:display_settlement_form===true?
      <div style={{width:"100%",display:"flex",flexDirection:"column",alignSelf:"center",border:"2px solid #022C43",padding:10}}>
      <label style={{width:"65%",alignSelf:"center",fontSize:20,textAlign:"center",color:"#022C43",fontWeight:"bold"}}>
        Create The Settlement
      </label>
      <label style={{width:"80%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Order ID:{selected_id}
      </label>
      <div style={{width:"80%",alignSelf:"center",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <label style={{width:"32%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Vendor ID:{current_order_array.find(e=>e.id===Number(selected_id)).vendor_id}
      </label>
      <label style={{width:"32%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Vendor Name:{current_order_array.find(e=>e.id===Number(selected_id)).vendor_name}
      </label>
      <label style={{width:"32%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Vendor Mobile Number:{current_order_array.find(e=>e.id===Number(selected_id)).vendor_mobile_number}
      </label>
      </div>
      <label style={{width:"80%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Select Payment Mode
      </label>
      <select style={{width:"80%",alignSelf:"center",border:"1px solid #022C43",borderRadius:8}} onChange={(e)=>onChangePaymentMode(e)}>
        <option>Select The Mode....</option>
        <option style={{color:"#022C43",fontWeight:"bold",fontSize:16}} value="UPI">UPI</option>
        <option style={{color:"#022C43",fontWeight:"bold",fontSize:16}} value="NetBanking">Net Banking</option>
        <option style={{color:"#022C43",fontWeight:"bold",fontSize:16}} value="Cash">Cash</option>
        <option style={{color:"#022C43",fontWeight:"bold",fontSize:16}} value="Debit Card">Debit Card</option>
        <option style={{color:"#022C43",fontWeight:"bold",fontSize:16}} value="Credit Card">Credit Card</option>
      </select>
      <label style={{width:"80%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Transaction ID
      </label>
      <input onChange={(e)=>onChangeTransactionID(e)} style={{width:"80%",color:"#022C43",fontSize:18,border:"1px solid #022C43",borderRadius:8,fontWeight:"bold",alignSelf:"center",padding:4}} type="text" placeholder="Transaction ID...."/>
      <label style={{width:"80%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Upload Transaction Receipt
      </label>
      <input onChange={(e)=>onChangePicture(e)} style={{width:"80%",color:"#022C43",fontSize:18,border:"1px solid #022C43",borderRadius:8,fontWeight:"bold",alignSelf:"center",padding:4}} type="file"/>
      {
        transaction_receipt_url.length>0?
        <img src={"https://storage.googleapis.com/p4uconsole/Settlements/"+transaction_receipt_url} style={{width:200,height:200,objectFit:"contain"}}/>:null
      }
      <label style={{width:"80%",alignSelf:"center",fontWeight:"bold",fontSize:20,color:"#022C43"}}>
        Amount
      </label>
      <input onChange={(e)=>onChangeAmount(e)} style={{width:"80%",color:"#022C43",fontSize:18,border:"1px solid #022C43",borderRadius:8,fontWeight:"bold",alignSelf:"center",padding:4}} type="text" placeholder="Amount...."/>
      <div style={{display:"flex",flexDirection:"row",width:"80%",height:50,justifyContent:"space-around",alignSelf:"center",marginTop:10}}>
        <button style={{backgroundColor:"#022C43",color:"#F5F5F5",textAlign:"center",width:"25%",borderRadius:12,fontWeight:"bold"}} onClick={()=>onSubmitButtonClicked()}>SUBMIT</button>
        <button style={{backgroundColor:"#022C43",color:"#F5F5F5",textAlign:"center",width:"25%",borderRadius:12,fontWeight:"bold"}} onClick={()=>set_display_settlement_form(false)}>BACK</button>
        <button style={{backgroundColor:"#022C43",color:"#F5F5F5",textAlign:"center",width:"25%",borderRadius:12,fontWeight:"bold"}} onClick={()=>onResetButtonClicked()}>RESET</button>
      </div>
      </div>:null}
    </Container>
  );
};
