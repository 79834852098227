import { createSlice } from "@reduxjs/toolkit";
export const VendorsRequestSlice=createSlice({
    name:"vendorsrequest",
    initialState:{
        value:[]
    },
    reducers:{
        populate_vendors_request:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_vendors_request}=VendorsRequestSlice.actions;
export default VendorsRequestSlice.reducer;