import { configureStore } from "@reduxjs/toolkit";
import ComponentReducer from "./ComponentSlice";
import CategoriesReducer from "./CategoriesSlice";
import ServicesReducer from "./ServicesSlice";
import TagsReducer from "./TagsSlice";
import VendorsReducer from "./ProvidersSlice";
import BannersReducer from "./BannersSlice";
import ProductsReducer from "./ProductsSlice";
import CouponsReducer from "./CouponsSlice";
import NewsFeedReducer from "./NewsFeedSlice";
import MetaDataReducer from "./MetadataSlice";
import NotificationReducer from "./NotificationsSlice";
import CustomerReducer from "./CustomersSlice";
import OrderReducer from "./OrdersSlice";
import CounterReducer from "./CounterSlice";
import VendorsRequestReducer from './VendorsRequestSlice';
import ProductsRequestReducer from './ProductsRequestSlice';
import SettlementsReducer from './SettlementsSlice';
export const store = configureStore({
  reducer: {
    callComponent: ComponentReducer,
    categoriesComponent: CategoriesReducer,
    servicesComponent: ServicesReducer,
    tagsComponent: TagsReducer,
    vendorsComponent: VendorsReducer,
    bannersComponent: BannersReducer,
    productsComponent: ProductsReducer,
    couponsComponent: CouponsReducer,
    newsFeedComponent: NewsFeedReducer,
    metaDataComponent: MetaDataReducer,
    notificationComponent: NotificationReducer,
    customersComponent: CustomerReducer,
    ordersComponent:OrderReducer,
    counterComponent:CounterReducer,
    vendorsRequestComponent:VendorsRequestReducer,
    productsRequestComponent:ProductsRequestReducer,
    settlementsComponent:SettlementsReducer,
  },
});
