import { createSlice } from "@reduxjs/toolkit";
export const ProvidersSlice=createSlice({
    name:"providers",
    initialState:{
        value:[]
    },
    reducers:{
        populate_providers:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_providers}=ProvidersSlice.actions;
export default ProvidersSlice.reducer;