import React, { useState, useEffect,useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
import axios from "axios";
import { populate_providers } from "../Redux/ProvidersSlice";
import CheckedBoxIcon from "../../Images/CheckedBox.png";
import UncheckedBoxIcon from "../../Images/UncheckedBox.png";
import { populate_vendors_request } from "../Redux/VendorsRequestSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.label`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw` bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
  const vendorsrequest=useSelector((state)=>state.vendorsRequestComponent.value)
   //Pagination
   const counter=useSelector((state)=>state.counterComponent.value);
   const[current_vendors_request_array,set_current_vendors_request_array]=useState([]);
   const current_page=useRef(1);
   const total_pages=useRef(Math.ceil(Number(counter.vendors_request_count/10)));
   //Load The First 10 elements of the table
   useEffect(()=>
   {
     let temp=[];
     if(vendorsrequest.length<10)
     {
       for(let i=0;i<vendorsrequest.length;i++)
       {
         temp.push(vendorsrequest[i]);
       }
     }
     else
     {
     for(let i=0;i<10;i++)
     {
       temp.push(vendorsrequest[i]);
     }
    }
     set_current_vendors_request_array(temp);
   },[]);
   const onPressNext=()=>
   {
     if(current_page.current+1<=total_pages.current)
     {
       if(vendorsrequest.length>=(current_page.current+1)*10)
       {
         let temp=[];
         for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
         {
           temp.push(vendorsrequest[i]);
         }
         current_page.current+=1;
         set_current_vendors_request_array(temp);
       }
       else
       {
         axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/vendors_request?limit=10&offset=${current_page.current*10}`)
         .then((response)=>
         {
           if(response.status===200)
           {
             let temp=[];
             let temp_all=[];
             for(let i=0;i<vendorsrequest.length;i++)
             {
               temp_all.push(vendorsrequest[i]);
             }
             for(let i=0;i<response.data.length;i++)
             {
               temp_all.push(response.data[i]);
               temp.push(response.data[i]);
             }
             current_page.current+=1;
             set_current_vendors_request_array(temp);
             dispatch(populate_vendors_request(temp_all));
           }
           else
           {
             alert(response.message);
           }
         }).catch((error)=>
         {
           alert(error.message);
         })
       }
     }
   }
   const onPressPrevious=()=>
   {
     if(current_page.current-1>0)
     {
      let temp=[];
      for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
      {
       temp.push(vendorsrequest[i]);
      }
      current_page.current-=1;
      set_current_vendors_request_array(temp);
     }
   }
   ///////////////////////////////////////////////////////////////////////
   const ReturnTheStatus=(_status_value)=>
   {
    switch(_status_value)
    {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Declined";
    }
   }
  const dispatch = useDispatch();
  return (
    <Container>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        VENDORS ENQUIRY{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "6%" }}>ID</TableTitle>
          <TableTitle style={{ width: "10%" }}>Name</TableTitle>
          <TableTitle style={{ width: "10%" }}>Business Name</TableTitle>
          <TableTitle style={{ width: "12%" }}>Mobile Number</TableTitle>
          <TableTitle style={{ width: "12%" }}>Building Number</TableTitle>
          <TableTitle style={{ width: "12%" }}>Area</TableTitle>
          <TableTitle style={{ width: "12%" }}>City</TableTitle>
          <TableTitle style={{ width: "12%" }}>State</TableTitle>
          <TableTitle style={{ width: "10%" }}>Pincode</TableTitle>
          <TableTitle style={{ width: "12%" }}>Date</TableTitle>
          <TableTitle style={{ width: "10%" }}>Status</TableTitle>
          {/* <TableTitle style={{ width: "10%" }}>Actions</TableTitle> */}
        </TableTop>

        {current_vendors_request_array.map((card, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "12%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "6%", textAlign: "center" }}>
                {card.id}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {card.name}
              </TableDetails>

              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {card.business_name}
              </TableDetails>
              <TableDetails style={{ width: "12%", textAlign: "center" }}>
                {card.mobile_number}
              </TableDetails>

              <TableDetails style={{ width: "12%", textAlign: "center" }}>
                {JSON.parse(card.address)[0].building_number}
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {JSON.parse(card.address)[0].area_name}
              </TableDetails>
              <TableDetails style={{ width: "12%", textAlign: "center" }}>
                {JSON.parse(card.address)[0].city}
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {JSON.parse(card.address)[0].state}
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {JSON.parse(card.address)[0].pincode}
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {new Date(card.date).getFullYear()+"-"+new Date(card.date).getMonth()+"-"+new Date(card.date).getDate()+" "+new Date(card.date).getHours()+":"+new Date(card.date).getMinutes()+":"+new Date(card.date).getSeconds()}
              </TableDetails>
              <TableDetails style={{ width: "10%", textAlign: "center" }}>
                {ReturnTheStatus(card.status)}
              </TableDetails>
              {/* <TableDetails style={{ width: "10%", display:"flex", }}>
                
              </TableDetails> */}
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>

    </Container>
  );
};
