import React, { useState, useRef } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_coupons } from "../Redux/CouponsSlice";
import DatePicker from "react-datepicker";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
import "react-datepicker/dist/react-datepicker.css";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const coupons=useSelector((state)=>state.couponsComponent.value);
  const add_coupon_button_ref=useRef();
  const dispatch = useDispatch();
  const[name,set_name]=useState("");
  const onChangeName=(e)=>
  {
    set_name(e.target.value);
  }
  const[description,set_description]=useState("");
  const onChangeDescription=(e)=>
  {
    set_description(e.target.value);
  }
  const[minimum_amount,set_minimum_amount]=useState("");
  const onChangeMinimumAmount=(e)=>
  {
    set_minimum_amount(e.target.value);
  }
  const[quantity,set_quantity]=useState("");
  const onChangeQuantity=(e)=>
  {
    set_quantity(e.target.value);
  }
  const[quantity_per_user,set_quantity_per_user]=useState("");
  const onChangeQuantityPerUser=(e)=>
  {
    set_quantity_per_user(e.target.value);
  }
  const[valid_till,set_valid_till]=useState("");
  const onChangeValidTill=(e)=>
  {
    set_valid_till(e.target.value);
  }
  const[vendor_specific,set_vendor_specific]=useState("");
  const[mode,set_mode]=useState("Flat");
  const onChangeMode=(e)=>
  {
    set_mode(e.target.value);
  }
  const[discount_value,set_discount_value]=useState("");
  const onChangeDiscountValue=(e)=>
  {
    set_discount_value(e.target.value);
  }
  const[availability,set_availability]=useState(1);
  const onChangeAvailability=(e)=>
  {
    set_availability(e.target.value);
  }
  const[valid_till_date,set_valid_till_date]=useState("");
  const[open_date_picker,set_open_date_picker]=useState(false);
  const onFixDate=(date)=>
  {
    set_valid_till_date(date.getFullYear()+"-"+(Number(date.getMonth())+1)+"-"+date.getDate()+" "+"00:00:00");
    set_open_date_picker(false);
  };
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const add_coupon=()=>
  {
    add_coupon_button_ref.current.disabled = true;
    if (name.length > 0 && description.length > 0 && valid_till_date.length>0 && minimum_amount.length>0 && discount_value.length>0 && quantity.length>0 && quantity_per_user.length>0) {
      const data = JSON.stringify({
       name:name,
       description:description,
       is_available:availability,
       minimum_amount:minimum_amount,
       quantity:quantity,
       quantity_per_user:quantity_per_user,
       mode:mode,
       discount_value:discount_value,
       redeemed_by:null,
       vendor_specific:null,
       valid_till:valid_till_date
      });
      axios
        .post(
          "https://node-service-app-ifox3xnafa-el.a.run.app/add_coupon",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response);

          add_coupon_button_ref.current.disabled = false;
          let coupon_temp_array = [];
          for (let i = 0; i < coupons.length; i++) {
            coupon_temp_array.push(coupons[i]);
          }
          coupon_temp_array.push({
            id:Number(coupons.length+1),
            name:name,
            description:description,
            is_available:availability,
            minimum_amount:minimum_amount,
            quantity:quantity,
            quantity_per_user:quantity_per_user,
            mode:mode,
            discount_value:discount_value,
            redeemed_by:null,
            vendor_specific:null,
            valid_till:valid_till_date
          });
          dispatch(populate_coupons(coupon_temp_array));
          alert("Successfully Added Coupon");
          set_name("");
          set_description("");
          set_minimum_amount("");
          set_quantity("");
          set_quantity_per_user("");
          set_availability(1);
          set_mode("Flat");
          set_discount_value("");
          set_valid_till_date("");
        })
        .catch(function (err) {
          alert(err.message);
        });
    } else {
      alert("Fields Are Missing!");
      add_coupon_button_ref.current.disabled = false;
    }
  }
  return (
    <Container>
     
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Coupon Name</Label>
                  <Input
                    type="text"
                    placeholder="Coupon Name"
                    value={name}
                    onChange={(e) => onChangeName(e)}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Availability</Label>
                  <Select
                    value={availability}
                    onChange={(e) => onChangeAvailability(e)}
                  >
                    <OptionContainer value={1}>Yes</OptionContainer>
                    <OptionContainer value={0}>No</OptionContainer>
                  </Select>
                </InputContainer>
              </ColumnForm>
            </TwoColumn>
            <TwoColumn>
              <Column>
              <InputContainer>
                  <Label htmlFor="name-input">Coupon Quantity</Label>
                  <Input
                    type="text"
                    placeholder="No of coupons..."
                    value={quantity}
                    onChange={(e) => onChangeQuantity(e)}
                  />
                </InputContainer>
              </Column>
              <Column>
              <InputContainer>
                  <Label htmlFor="name-input">Quantity Per User</Label>
                  <Input
                    type="text"
                    placeholder="Number of coupons per user..."
                    value={quantity_per_user}
                    onChange={(e) => onChangeQuantityPerUser(e)}
                  />
                </InputContainer>
              </Column>
            </TwoColumn>
            <InputContainer>
              <Label htmlFor="name-input">Coupon Description</Label>
              <InputLarge
                type="text"
                placeholder="Coupon Description"
                value={description}
                onChange={(e) => onChangeDescription(e)}
              />
            </InputContainer>
            <ColumnForm>
            <InputContainer>
              <Label htmlFor="name-input">Minimum Amount</Label>
              <Input
                type="text"
                placeholder="Minimum Amount"
                value={minimum_amount}
                onChange={(e) => onChangeMinimumAmount(e)}
              />
            </InputContainer>
            </ColumnForm>
            <Column>
            <InputContainer onClick={()=>set_open_date_picker(true)}>
                  <Label htmlFor="name-input">Date</Label>
                  <Input
                    id="date"
                    type="text"
                    name="date"
                    placeholder="Input Date"
                    contentEditable={false}
                    value={valid_till_date}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Input Date")}
                  />
                </InputContainer>
                {
                open_date_picker===true?
                <DatePicker color={"#000000"} border={false} open={open_date_picker}  onChange={(val) => onFixDate(val)} />
                :
                null
                }
            </Column>
            <TwoColumn>
              <Column>
              <InputContainer>
                  <Label htmlFor="name-input">Discount Mode</Label>
                  <Select
                    value={mode}
                    onChange={(e) => onChangeMode(e)}
                  >
                    <OptionContainer value={"Flat"}>Flat</OptionContainer>
                    <OptionContainer value={"Percentage"}>Percentage</OptionContainer>
                  </Select>
                </InputContainer>
              </Column>
              <Column>
              <InputContainer>
              <Label htmlFor="name-input">Coupon Discount Value</Label>
              <Input
                type="text"
                placeholder="Coupon Discount Value"
                value={discount_value}
                onChange={(e) => onChangeDiscountValue(e)}
              />
            </InputContainer>
              </Column>
            </TwoColumn>
            <Actions>
              <Button
                ref={add_coupon_button_ref}
                onClick={() => add_coupon()}
              >
                Save Coupon
              </Button>
            </Actions>
          </form>
        </FormContainer>
    </Container>
  );
};
