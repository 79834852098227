import { createSlice } from "@reduxjs/toolkit";
export const TagsSlice = createSlice({
  name: "tags",
  initialState: {
    value: [],
  },
  reducers: {
    populate_tags: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_tags } = TagsSlice.actions;
export default TagsSlice.reducer;
