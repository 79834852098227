import React, { useState } from "react";
import tw from "twin.macro";

import FooterLogo from "../Images/logo.png";

const Container = tw.div` w-full text-center bg-SecondaryBackground`;
const FooterContainer = tw.div` items-center 
md:w-full  md:justify-center md:flex text-left
py-4
`;

const FlexContainer = tw.div`md:flex grid grid-cols-2`;
const VerticalSections = tw.div`md:w-1/4 items-center md:border-none border border-sub rounded-xl md:p-0 p-2 m-2 md:m-0`;
const FooterLogoContainer = tw.div`md:my-6 my-4 flex justify-center`;

const Heading = tw.div`text-white font-semibold md:text-xl md:mt-4 mt-2`;
const Subheading = tw.div`text-sub md:text-base`;
const FooterHeading = tw.div`text-white text-base py-2`;

export default () => {
  return (
    <Container>
      <FooterContainer>
        <FlexContainer>
          <VerticalSections>
            <FooterLogoContainer>
              <img src={FooterLogo} width="30%" />
            </FooterLogoContainer>
          </VerticalSections>

          <VerticalSections>
            <Heading>Terms</Heading>
            <Heading>Privacy</Heading>
            <a href="/VendorSignUp">
              {" "}
              <Heading>Register Vendor</Heading>{" "}
            </a>
          </VerticalSections>

          <VerticalSections>
            <Heading>For Jobs</Heading>
            <Subheading>Careers</Subheading>
            <Heading>For Support</Heading>
            <Subheading>Support@planext.com</Subheading>
          </VerticalSections>

          <VerticalSections>
            <Heading>For Contact</Heading>
            <Subheading>hello@planext.com</Subheading>
            <Heading>For Press</Heading>
            <Subheading>press@planext.com</Subheading>
          </VerticalSections>
        </FlexContainer>
      </FooterContainer>
      <FooterHeading>
        Planext Private Limited., © Copyrights 2022. All Rights Reserved.
      </FooterHeading>
    </Container>
  );
};
