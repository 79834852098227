import React, { useState,useEffect,useRef} from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_banners } from "../Redux/BannersSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const banners = useSelector((state) => state.bannersComponent.value);
  const dispatch = useDispatch();
  const [banner_icon_url, set_banner_icon_url] = useState("");
  const [file_to_upload, set_file_to_upload] = useState("");
  const [file_to_preview, set_file_to_preview] = useState("");
  const [is_available, set_is_available] = useState(true);
  //Pagination
  const counter=useSelector((state)=>state.counterComponent.value);
  const[current_banner_array,set_current_banner_array]=useState([]);
  const current_page=useRef(1);
  const total_pages=useRef(Math.ceil(Number(counter.banners_count/10)));
  //Load The First 10 elements of the table
  useEffect(()=>
  {
    let temp=[];
    if(banners.length<10)
    {
      for(let i=0;i<banners.length;i++)
      {
        temp.push(banners[i]);
      }
    }
    else
    {
    for(let i=0;i<10;i++)
    {
      temp.push(banners[i]);
    }
    }
    set_current_banner_array(temp);
  },[]);
  const onPressNext=()=>
  {
    if(current_page.current+1<=total_pages.current)
    {
      if(banners.length>=(current_page.current+1)*10)
      {
        let temp=[];
        for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
        {
          temp.push(banners[i]);
        }
        current_page.current+=1;
        set_current_banner_array(temp);
      }
      else
      {
        axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/banners?limit=10&offset=${current_page.current*10}`)
        .then((response)=>
        {
          if(response.status===200)
          {
            let temp=[];
            let temp_all=[];
            for(let i=0;i<banners.length;i++)
            {
              temp_all.push(banners[i]);
            }
            for(let i=0;i<response.data.length;i++)
            {
              temp_all.push(response.data[i]);
              temp.push(response.data[i]);
            }
            current_page.current+=1;
            set_current_banner_array(temp);
            dispatch(populate_banners(temp_all));
          }
          else
          {
            alert(response.message);
          }
        }).catch((error)=>
        {
          alert(error.message);
        })
      }
    }
  }
  const onPressPrevious=()=>
  {
    if(current_page.current-1>0)
    {
     let temp=[];
     for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
     {
      temp.push(banners[i]);
     }
     current_page.current-=1;
     set_current_banner_array(temp);
    }
  }
  ///////////////////////////////////////////////////////////////////////
  const onChangeIsAvailable = (e) => {
    set_is_available(e.target.value);
  };
  const[banner_route,set_banner_route]=useState("");
  const onChangeBannerRoute=(e)=>
  {
    set_banner_route(e.target.value);
  }
  const[banner_specific_route_detail,set_banner_specific_route_detail]=useState("");
  const onChangeBannerSpecificRouteDetail=(e)=>
  {
    set_banner_specific_route_detail(e.target.value);
  }
  const add_banner_button_ref = useRef();
  const onChangeImage = (e) => {
    set_file_to_upload(e.target.files[0]);
    console.log(e.target.files[0]);
    // let form_data = new FormData();
    // form_data.append("file", e.target.files[0],);
    // axios
    //   .post(
    //     `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Banners/nothing`,
    //     form_data,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   )
    //   .then(function (response) {
    //     set_banner_icon_url(response.data.url)
    //   }).catch(function(error){
    //     alert(error);
    //   })
  };
  const add_banner = () => {
    add_banner_button_ref.current.disabled = true;
    if (
      banner_icon_url.length > 0 &&
      banner_route.length > 0 &&
      banner_specific_route_detail.length>0
    ) {
          const data = JSON.stringify({
            banner_url:file_to_upload.name,
            banner_route:banner_route,
            banner_specific_route_detail:banner_specific_route_detail,
            is_available:is_available
          });
          axios
            .post(
              "https://node-service-app-ifox3xnafa-el.a.run.app/add_banner",
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              console.log(response);

              
              let banner_temp_array = [];
              for (let i = 0; i < banners.length; i++) {
                banner_temp_array.push(banners[i]);
              }
              banner_temp_array.push({
                id: (banners.length+1),
                banner_url:URL.createObjectURL(file_to_upload),
                banner_route: banner_route,
                is_available: is_available,
                banner_specific_route_detail:banner_specific_route_detail
              });
              dispatch(populate_banners(banner_temp_array));
              alert("Successfully Added Banner");
              set_banner_icon_url("");
              set_banner_route("");
              set_banner_specific_route_detail("");
              set_file_to_preview("");
              set_file_to_upload("");
              add_banner_button_ref.current.disabled = false;
            })
            .catch(function (err) {
              alert(err.message);
            });
    } else {
      alert("Fields Are Missing!");
      add_banner_button_ref.current.disabled = false;
    }
  };

  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const[show_edit_modal,set_show_edit_modal]=useState(false);
  const onEditBannerClicked=(id)=>
  {
    set_show_edit_modal(true);
  }
  const onDeleteBannerClicked=(id,_item)=>
  {
    console.log(_item);
    axios
    .delete(`https://node-service-app-ifox3xnafa-el.a.run.app/files/Banners/null/${_item.banner_url}`)
    .then(response => {

      if (response.status === 200) {
        console.log("Delete Response:"+Object.values(response));
        axios
          .delete(
            `https://node-service-app-ifox3xnafa-el.a.run.app/banners/${id}`,
          )
          .then(response1 => {
            if (response1.status === 200) {
              let bannersTemp=[];
              for(let i=0;i<banners.length;i++)
              {
                bannersTemp.push(banners[i]);
              }
              bannersTemp.splice(bannersTemp.findIndex(e=>e.id==id),1);
              dispatch(populate_banners(bannersTemp));
              set_current_banner_array(bannersTemp);
              alert('Successfully Deleted The Banner');
            }
          
          })
        }
      });
  }
  return (
    <Container>
      {show_edit_modal === true ? 
        <FormContainer>
          <h1 style={{color:"#000000",textAlign:"center",fontSize:25,fontWeight:"bold"}}>Update Banner</h1>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Banner Route</Label>
                  <Input
                    type="text"
                    placeholder="Banner Route"
                    value={banner_route}
                    onChange={(e) => onChangeBannerRoute(e)}
                  />
                </InputContainer>
              </ColumnForm>
              <InputContainer>
                  <Label htmlFor="name-input">Availability</Label>
                  <Select
                    value={is_available}
                    onChange={(e) => onChangeIsAvailable(e)}
                  >
                    <OptionContainer value={1}>Yes</OptionContainer>
                    <OptionContainer value={0}>No</OptionContainer>
                  </Select>
                </InputContainer>
              <ColumnForm>

              </ColumnForm>
            </TwoColumn>

            <InputContainer>
              <Label htmlFor="name-input">Banner Specific Route</Label>
              <InputLarge
                type="text"
                placeholder="Banner Special Route"
                value={banner_specific_route_detail}
                onChange={(e) => onChangeBannerSpecificRouteDetail(e)}
              />
            </InputContainer>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Banner</Label>
                <Input type="file" onChange={(e) => onChangeImage(e)} />
              </InputContainer>
              <img
                src={file_to_preview}
                style={{ width: "25%", height: "25%", objectFit: "contain" }}
              />
            </ColumnForm>
            <TwoColumn>
              <Column>
              <Button
                ref={add_banner_button_ref}
                onClick={() => add_banner()}
              >
                Save Banner
              </Button>
              </Column>
              <Column>
              <Button
                ref={add_banner_button_ref}
                onClick={() => set_show_edit_modal(false)}
              >
                Cancel
              </Button>
              </Column>
            </TwoColumn>
          </form>
        </FormContainer>
      :
<>
      <h1 style={{ color: "#000000", textAlign: "center", fontSize: 32 }}>
        BANNER TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "13%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "13%" }}>Banner ID</TableTitle>
          <TableTitle style={{ width: "13%" }}>Banner Icon</TableTitle>
          <TableTitle style={{ width: "28%" }}>Banner Route</TableTitle>
          <TableTitle style={{ width: "18%" }}>Banner Specific Route</TableTitle>
          <TableTitle style={{ width: "18%" }}>Availability</TableTitle>
          <TableTitle style={{ width: "18%" }}>Actions</TableTitle>
        </TableTop>

        {current_banner_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "13%" }}>{item.id}</TableDetails>
              <TableDetails
                style={{
                  width: "28%",
                  height: "20%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    "https://storage.googleapis.com/p4uconsole/Banners/" +
                    item.banner_url
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                ></img>
              </TableDetails>

              <TableDetails style={{ width: "28%" }}>{item.banner_route}</TableDetails>

              <TableDetails style={{ width: "28%" }}>
                {item.banner_specific_route_detail}
              </TableDetails>

              <TableDetails style={{ width: "18%" }}>
                {item.is_available}
              </TableDetails>

              <TableDetails style={{ width: "18%" }}>
                <ActionIconsColumn>
                  <IconContainer onClick={()=>onEditBannerClicked(item.id)}>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer onClick={()=>onDeleteBannerClicked(item.id,item)}>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
         <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>
          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
      </>}
    </Container>
 
  );
};
