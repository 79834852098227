import React,{useState,useEffect} from 'react';
import { PrimaryButton, Link2 } from "../Components/MiscStyles/Buttons";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import{auth} from '../firebase.js';
import {onAuthStateChanged} from "firebase/auth";
import HomePage from "./HomePage";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import Content from "../Components/Content";
import SignIn from "../Components/SignIn";
import tw from "twin.macro";
const AppContainer = tw.div`relative`;
const BodyContainer = tw.div`flex relative`;
const ContentContainer = tw.div`flex`;

const TestDisplayArea = tw.div`py-40 bg-gray-600`;
const Text = tw.p`text-blue-700 font-bold text-2xl text-center`;
const RoutingPage=()=>
{
    const[is_signed_in,set_is_signed_in]=useState(false);
    useEffect(()=>
    {
        onAuthStateChanged(auth, (user) => {
            if (user) {
             set_is_signed_in(true);
            } else {
                set_is_signed_in(false);
            }
          });          
    },[is_signed_in])
return(
    <Router>
        {is_signed_in===false?
        <Routes>
    <Route path="/" element={<SignIn/>}/>
    </Routes>
    :
    <Routes>
<Route path="/" element={<AppContainer>
        <NavBar />
        <BodyContainer>
          <Sidebar />
          <Content />
        </BodyContainer>
      </AppContainer>}/>
      </Routes>}
      </Router>
);
}
export default RoutingPage;