import { createSlice } from "@reduxjs/toolkit";
export const ProductsSlice=createSlice({
    name:"products",
    initialState:{
        value:[]
    },
    reducers:{
        populate_products:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_products}=ProductsSlice.actions;
export default ProductsSlice.reducer;