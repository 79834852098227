import React, { useState,useEffect,useRef } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_tags } from "../Redux/TagsSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default () => {
  const [open_edit_modal, set_open_edit_modal] = useState(false);
  const [selected_tag_id, set_selected_tag_id] = useState("");
  const onEditTagClicked = (id) => {
    set_open_edit_modal(true);
    set_selected_tag_id(id);
    set_tag_name(tags.find((e) => e.id === id).name);
    set_tag_description(tags.find((e) => e.id === id).description);
  };
  const update_tag = () => {
    axios
      .patch(
        `https://node-service-app-ifox3xnafa-el.a.run.app/tags/${selected_tag_id}`,
        {
          name: tag_name,
          description: tag_description,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let tag_obj = tags.find((e) => e.id === selected_tag_id);
          let new_tag_object = {
            id: tag_obj.id,
            name: tag_name,
            description: tag_description,
          };
          let tag_temp_array = [];
          for (let i = 0; i < tags.length; i++) {
            tag_temp_array.push(tags[i]);
          }
          tag_temp_array.splice(
            tag_temp_array.findIndex((e) => e.id === selected_tag_id),
            1
          );
          tag_temp_array.push(new_tag_object);
          dispatch(populate_tags(tag_temp_array));
          alert("Successfully Updated Tag");
          set_tag_name("");
          set_tag_description("");
          set_open_edit_modal(false);
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const onDeleteButtonClicked = (id) => {
    axios
      .delete(`https://node-service-app-ifox3xnafa-el.a.run.app/tags/${id}`)
      .then((response) => {
        if (response.status === 200) {
          let tags_temp_array = [];
          for (let i = 0; i < tags.length; i++) {
            tags_temp_array.push(tags[i]);
          }
          tags_temp_array.splice(
            tags_temp_array.find((e) => e.id === id),
            1
          );
          dispatch(populate_tags(tags_temp_array));
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const tags = useSelector((state) => state.tagsComponent.value);
  const dispatch = useDispatch();
    //Pagination
    const counter=useSelector((state)=>state.counterComponent.value);
    const[current_tag_array,set_current_tag_array]=useState([]);
    const current_page=useRef(1);
    const total_pages=useRef(Math.ceil(Number(counter.tags_count/10)));
    //Load The First 10 elements of the table
    useEffect(()=>
    {
      let temp=[];
      if(tags.length<10)
    {
      for(let i=0;i<tags.length;i++)
      {
        temp.push(tags[i]);
      }
    }
    else
    {
      for(let i=0;i<10;i++)
      {
        temp.push(tags[i]);
      }
    }
      set_current_tag_array(temp);
    },[]);
    const onPressNext=()=>
    {
      if(current_page.current+1<=total_pages.current)
      {
        if(tags.length>=(current_page.current+1)*10)
        {
          let temp=[];
          for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
          {
            temp.push(tags[i]);
          }
          current_page.current+=1;
          set_current_tag_array(temp);
        }
        else
        {
          axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/tags?limit=10&offset=${current_page.current*10}`)
          .then((response)=>
          {
            if(response.status===200)
            {
              let temp=[];
              let temp_all=[];
              for(let i=0;i<tags.length;i++)
              {
                temp_all.push(tags[i]);
              }
              for(let i=0;i<response.data.length;i++)
              {
                temp_all.push(response.data[i]);
                temp.push(response.data[i]);
              }
              current_page.current+=1;
              set_current_tag_array(temp);
              dispatch(populate_tags(temp_all));
            }
            else
            {
              alert(response.message);
            }
          }).catch((error)=>
          {
            alert(error.message);
          })
        }
      }
    }
    const onPressPrevious=()=>
    {
      if(current_page.current-1>0)
      {
       let temp=[];
       for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
       {
        temp.push(tags[i]);
       }
       current_page.current-=1;
       set_current_tag_array(temp);
      }
    }
    ///////////////////////////////////////////////////////////////////////
  const [tag_name, set_tag_name] = useState("");
  const onChangeTagName = (e) => {
    set_tag_name(e.target.value);
  };
  const [tag_description, set_tag_description] = useState("");
  const onChangeTagDescription = (e) => {
    set_tag_description(e.target.value);
  };
  const add_tag_button_ref = useRef();
  const add_tag = () => {
    add_tag_button_ref.current.disabled = true;
    if (tag_name.length > 0 && tag_description.length > 0) {
      const data = JSON.stringify({
        name: tag_name,
        description: tag_description,
      });
      axios
        .post(
          "https://node-service-app-ifox3xnafa-el.a.run.app/add_tags",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response);

          add_tag_button_ref.current.disabled = false;
          let tag_temp_array = [];
          for (let i = 0; i < tags.length; i++) {
            tag_temp_array.push(tags[i]);
          }
          tag_temp_array.push({
            id: 0,
            name: tag_name,
            description: tag_description,
          });
          dispatch(populate_tags(tag_temp_array));
          alert("Successfully Added Tag");
          set_tag_description("");
          set_tag_name("");
        })
        .catch(function (err) {
          alert(err.message);
        });
    } else {
      alert("Fields Are Missing!");
      add_tag_button_ref.current.disabled = false;
    }
  };

  return (
    <Container>
      {open_edit_modal === true ? (
        <>
          <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
            UPDATE TABLE
          </h1>
          <FormContainer>
            <form action="#">
              <TwoColumn>
                <ColumnForm>
                  <InputContainer>
                    <Label htmlFor="name-input">Tag Name</Label>
                    <Input
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid #022C43",
                      }}
                      type="text"
                      placeholder="Tag Name"
                      value={tag_name}
                      onChange={(e) => onChangeTagName(e)}
                    />
                  </InputContainer>
                </ColumnForm>
              </TwoColumn>

              <InputContainer>
                <Label htmlFor="name-input">Tag Description</Label>
                <InputLarge
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #022C43",
                  }}
                  type="text"
                  placeholder="Tag Description"
                  value={tag_description}
                  onChange={(e) => onChangeTagDescription(e)}
                />
              </InputContainer>
              <TwoColumn>
                <Actions>
                  <Link1 ref={add_tag_button_ref} onClick={() => update_tag()}>
                    Update Tag
                  </Link1>
                </Actions>
                <Actions>
                  <Link1 onClick={() => set_open_edit_modal(false)}>
                    Cancel
                  </Link1>
                </Actions>
              </TwoColumn>
            </form>
          </FormContainer>
        </>
      ) : (
        <>
          <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
            TAG TABLE
          </h1>
          <TableContainer>
            <TableTop>
            <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
              <TableTitle style={{ width: "13%" }}>Tag ID</TableTitle>
              <TableTitle style={{ width: "28%" }}>Tag Name</TableTitle>
              <TableTitle style={{ width: "28%" }}>Tag Description</TableTitle>
              <TableTitle style={{ width: "18%" }}>Actions</TableTitle>
            </TableTop>

            {current_tag_array.map((item, i) => (
              <Column key={i}>
                <Card>
                <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
                  <TableDetails style={{ width: "28%" }}>
                    {item.name}
                  </TableDetails>
                  <TableDetails style={{ width: "28%" }}>
                    {item.description}
                  </TableDetails>
                  <TableDetails style={{ width: "18%" }}>
                    <ActionIconsColumn>
                      <IconContainer onClick={() => onEditTagClicked(item.id)}>
                        <AiOutlineEdit />
                      </IconContainer>

                      <IconContainer
                        onClick={() => onDeleteButtonClicked(item.id)}
                      >
                        <AiOutlineDelete />
                      </IconContainer>
                    </ActionIconsColumn>
                  </TableDetails>
                </Card>
              </Column>
            ))}
                    <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
            <ButtonsContainer>
              <Button onClick={()=>onPressPrevious()}>
                <AiOutlineArrowLeft />
                <ButtonText>Previous</ButtonText>
              </Button>

              <Button onClick={()=>onPressNext()}>
                <ButtonText>Next</ButtonText>
                <AiOutlineArrowRight />
              </Button>
            </ButtonsContainer>
          </TableContainer>
        </>
      )}
    </Container>
  );
};
