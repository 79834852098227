/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import tw, { styled } from "twin.macro";
// import useState from React

export const PrimaryLink = tw.a`pb-1 text-xl cursor-pointer text-white border-b border-transparent hocus:border-blue-500 hocus:text-blue-500 transition duration-300`;

export const PrimaryButton = tw.a`px-6 py-1 text-xl w-32 inline-flex justify-center items-center cursor-pointer text-black bg-white rounded-2xl hocus:border-blue-500 hocus:text-blue-500 transition duration-300`;


export const Link2 = styled.a`
  ${tw`w-40 p-3 sm:p-4 text-sm sm:text-sm font-bold cursor-pointer uppercase tracking-wider rounded-full 
  inline-flex justify-center items-center 
   bg-green-500 
  hocus:bg-green-600 text-gray-100 hocus:text-gray-100  transition duration-300`}`;