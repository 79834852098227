import { createSlice } from "@reduxjs/toolkit";
export const NewsFeedSlice=createSlice({
    name:"news_feed",
    initialState:{
        value:[]
    },
    reducers:{
        populate_news_feed:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_news_feed}=NewsFeedSlice.actions;
export default NewsFeedSlice.reducer;