import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value:
  {
    customers_count:0,
    providers_count:0,
    categories_count:0,
    services_count:0,
    products_count:0,
    orders_count:0,
    notifications_count:0,
    vendors_request_count:0,
    products_request_count:0,
    banners_count:0,
    news_feed_count:0,
    tags_count:0,
    coupons_count:0,
    settlements_count:0
  }
};

export const CounterSlice = createSlice({
  name: "counts",
  initialState,
  reducers: {
    populate_counts: (state, action) => {
      switch (action.payload.type) {
        case "Customers":
          state.value.customers_count =action.payload.sol;
          break;
        case "Providers":
          state.value.providers_count = action.payload.sol;
          break;
        case "Categories":
          state.value.categories_count = action.payload.sol;
          break;
        case "Services":
          state.value.services_count = action.payload.sol;
          break;
        case "Products":
          state.value.products_count = action.payload.sol;
          break;
        case "Orders":
          state.value.orders_count = action.payload.sol;
          break;
        case "Notifications":
          state.value.notifications_count = action.payload.sol;
          break;
        case "VendorRequests":
          state.value.vendors_request_count = action.payload.sol;
          break;
        case "ProductRequests":
          state.value.products_request_count = action.payload.sol;
          break;
        case "Banners":
          state.value.banners_count = action.payload.sol;
          break;
        case "NewsFeed":
          state.value.news_feed_count = action.payload.sol;
          break;
        case "Tags":
          state.value.tags_count = action.payload.sol;
          break;
        case "Coupons":
          state.value.coupons_count = action.payload.sol;
          break;
        case "Settlements":
          state.value.settlements_count = action.payload.sol;
          break;
      }
    },
  },
});

export const { populate_counts } = CounterSlice.actions;

export default CounterSlice.reducer;
