import React, { useState, useRef } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_products } from "../Redux/ProductsSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const categories = useSelector((state) => state.categoriesComponent.value);
  const services = useSelector((state) => state.servicesComponent.value);
  const vendors = useSelector((state) => state.vendorsComponent.value);
  const products = useSelector((state) => state.productsComponent.value);
  const dispatch = useDispatch();
  const [name, set_name] = useState("");
  const onChangeName = (e) => {
    set_name(e.target.value);
  };
  const [description, set_description] = useState("");
  const onChangeDescription = (e) => {
    set_description(e.target.value);
  };
  const [price, set_price] = useState("");
  const onChangePrice = (e) => {
    set_price(e.target.value);
  };
  //
  const [category_id, set_category_id] = useState(1);
  //
  const[services_per_categories,set_services_per_categories]=useState([]);
  const onChangeCategoryID = (e) => {
    set_category_id(e.target.value);
    let temp_array=[];
    for(let i=0;i<services.length;i++)
    {
      if(services[i].category_id===Number(e.target.value))
      {
        temp_array.push(services[i]);
      }
    }
    set_services_per_categories(temp_array);
    console.log(temp_array);
  };
  const [service_id,set_service_id] = useState(1);
  const onChangeServiceID = (e) => {
    set_service_id(e.target.value);
  };
  const [vendor_id, set_vendor_id] = useState(1);
  const onChangeVendorID = (e) => {
    set_vendor_id(e.target.value);
  };
  const [file_to_upload, set_file_to_upload] = useState([]);
  const [file_to_preview, set_file_to_preview] = useState([]);
  const[file_name,set_file_names]=useState([]);
  const [is_available, set_is_available] = useState(1);
  const onChangeIsAvailable = (e) => {
    set_is_available(e.target.value);
  };
  const add_product_button_ref = useRef();
  const onAddDos=()=>
  {
    let temp_dos=[];
    temp_dos.push({id:dos.length+1,value:""});
    for(let i=0;i<dos.length;i++)
    {
      temp_dos.push(dos[i]);
    }
    console.log(temp_dos.length);
    set_dos(temp_dos);
  }
  const onAddDonts=()=>
  {
    let temp_donts=[];
    temp_donts.push({id:donts.length+1,value:""});
    for(let i=0;i<donts.length;i++)
    {
      temp_donts.push(donts[i]);
    }
    console.log(temp_donts.length);
    set_donts(temp_donts);
  }
  const[dos,set_dos]=useState([{id:1,value:""}]);
  const onChangeDos=(e,id)=>
  {
    dos.find(e=>e.id===id).value=e.target.value;
    let temp_dos=[];
    for(let i=0;i<dos.length;i++)
    {
      temp_dos.push(dos[i]);
    }
    set_dos(temp_dos);
  }
  const[donts,set_donts]=useState([{id:1,value:""}]);
  const onChangeDonts=(e,id)=>
  {
    donts.find(e=>e.id===id).value=e.target.value;
    let temp_donts=[];
    for(let i=0;i<donts.length;i++)
    {
      temp_donts.push(donts[i]);
    }
    set_donts(temp_donts);
  }
  const onChangeImage = (e) => {
    let temp_file_upload_array=[];
    let temp_names=[];
    let temp_file_preview_array=[];
    for(let i=0;i<e.target.files.length;i++)
    {
    temp_file_upload_array.push(e.target.files[i]);
    
    let form_data = new FormData();
    form_data.append("file", e.target.files[i]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Products/${products.length+1}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        //For Server
        temp_names.push(e.target.files[i].name);
        //For Local Confirmation
        temp_file_preview_array.push(URL.createObjectURL(e.target.files[i]));
      }).catch(function(error){
        alert(error);
      })
    }
    set_file_names(temp_names);
    set_file_to_preview(temp_file_preview_array);
    set_file_to_upload(temp_file_upload_array);
  };
  const[discount,set_discount]=useState("");
  const onChangeDiscount=(e)=>
  {
    set_discount(e.target.value);
  }
  const[duration,set_duration]=useState("");
  const onChangeDuration=(e)=>
  {
    set_duration(e.target.value);
  }
  const[video_id,set_video_id]=useState("");
  const onChangeVideoID=(e)=>
  {
    set_video_id(e.target.value);
  }
  const[thubnail_file,set_thumbnail_file]=useState([]);
  const[thumbnail_file_url,set_thumbnail_file_url]=useState("");
  const onChangeThumbnailFile=(e)=>
  {
    set_thumbnail_file(e.target.files[0]);
    let form_data = new FormData();
    form_data.append("file", e.target.files[0]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/Products/${products.length+1}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        set_thumbnail_file_url(response.data.url);
      }).catch(function(error){
        alert(error);
      })
    }
  
  const add_product = () => {
    console.log(JSON.stringify({
      name: name,
      description: description,
      cost:price,
      discount: discount,
      pictures_url:JSON.stringify(file_name),
      ddo:JSON.stringify([{name:"Do's",value:dos},{name:"Don'ts",value:donts}]),
      rating:0,
      category_id:category_id,
      service_id:service_id,
      vendor_id:vendor_id,
      is_available:is_available,
      youtube_video_id:video_id,
      thumbnail_url:thubnail_file.name,
      duration:duration
    }));
    add_product_button_ref.current.disabled = true;
    if (
      name.length > 0 &&
      // description.length > 0 &&
      price.length>0&&
      dos.length>0&&
      donts.length>0&&
      category_id.length>0&&
      service_id.length>0&&
      vendor_id.length>0&&
      // file_name.length>0 &&
      duration.length>0 &&
      //video_id.length>0 &&
      discount.length>0&&
      thumbnail_file_url.length>0
    ) {
      const data = JSON.stringify({
            name: name,
            description: description,
            cost:price,
            discount: discount,
            pictures_url:JSON.stringify(file_name),
            ddo:JSON.stringify([{name:"Do's",value:dos},{name:"Don'ts",value:donts}]),
            rating:0,
            category_id:category_id,
            service_id:service_id,
            vendor_id:vendor_id,
            is_available:is_available,
            youtube_video_id:video_id,
            thumbnail_url:thubnail_file.name,
            duration:duration
          });
          axios
            .post(
              "https://node-service-app-ifox3xnafa-el.a.run.app/add_products",
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              console.log(response);

              add_product_button_ref.current.disabled = false;
              let product_temp_array = [];
              for (let i = 0; i < products.length; i++) {
                product_temp_array.push(products[i]);
              }
              product_temp_array.push({
                id: (products.length+1),
                name: name,
                description: description,
                cost:price,
                discount: discount,
                pictures_url:JSON.stringify(file_name),
                ddo:JSON.stringify([{name:"Do's",value:dos},{name:"Don'ts",value:donts}]),
                rating:0,
                category_id:category_id,
                service_id:service_id,
                vendor_id:vendor_id,
                is_available:is_available,
                youtube_video_id:vendor_id,
                thumbnail_url:thubnail_file.name,
                duration:duration
              });
              dispatch(populate_products(product_temp_array));
              alert("Successfully Added Product");
              set_name("");
              set_description("");
              set_price("");
              set_dos([{id:1,value:""}]);
              set_donts([{id:1,value:""}]);
              set_category_id(categories[0].id);
              set_services_per_categories([]);
              set_service_id(services[0].id)
              set_vendor_id(vendors[0].id);
              set_video_id("");
              set_thumbnail_file("");
              set_thumbnail_file_url("");
              set_discount("");
              set_duration("");
            })
            .catch(function (err) {
              alert(err.message);
            });
      
    } else {
      alert("Fields Are Missing!");
      add_product_button_ref.current.disabled = false;
    }
  };
  return (
    <Container>
    
        <FormContainer>
           <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
            Add Product
      </h1>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Product Name</Label>
                  <Input
                    type="text"
                    placeholder="Priduct Name"
                    value={name}
                    onChange={(e) => onChangeName(e)}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Availability</Label>
                  <Select
                    value={is_available}
                    onChange={(e) => onChangeIsAvailable(e)}
                  >
                    <OptionContainer value={1}>Yes</OptionContainer>
                    <OptionContainer value={0}>No</OptionContainer>
                  </Select>
                </InputContainer>
              </ColumnForm>
            </TwoColumn>
            <TwoColumn>
              <ColumnForm>
              <InputContainer>
                  <Label htmlFor="name-input">Category ID</Label>
                  <Select
                    //value={category_id}
                    onChange={(e) => onChangeCategoryID(e)}
                  >
                       <OptionContainer>Select Category...</OptionContainer>
                    {
                     categories.map((item,index)=>
                     
                    <OptionContainer  key={index} value={item.id}>{item.name}</OptionContainer>
                    )
                    }
                     </Select>
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">Service ID</Label>
                  <Select
                    //value={service_id}
                    onChange={(e) => onChangeServiceID(e)}
                  >
                  <OptionContainer>Select Service...</OptionContainer>
                    
      {              
                    services_per_categories.map((item,index)=>
                    <OptionContainer key={index} value={item.id}>{item.name}</OptionContainer>
                    )
      }
                  </Select>
                </InputContainer>
              </ColumnForm>
              <ColumnForm>
              <InputContainer>
                  <Label htmlFor="name-input">Vendor ID</Label>
                  <Select
                    //value={vendor_id}
                    onChange={(e) => onChangeVendorID(e)}
                  >
                       <OptionContainer>Select Vendor...</OptionContainer>
                    {
                     vendors.map((item,index)=>
                     
                    <OptionContainer  key={index} value={item.id}>{item.name}</OptionContainer>
                    )
                    }
                     </Select>
                </InputContainer>
              </ColumnForm>
            </TwoColumn>
            <InputContainer>
              <Label htmlFor="name-input">Product Description</Label>
              <InputLarge
                type="text"
                placeholder="Product Description"
                value={description}
                onChange={(e) => onChangeDescription(e)}
              />
            </InputContainer>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Product Images</Label>
                <Input type="file" multiple={true} onChange={(e) => onChangeImage(e)} />
              </InputContainer>
              {
              file_to_preview.map((item,index)=>
              <div  key={index} style={{width:"100%",display:"flex",flexDirection:"row"}}>
              <img
                src={item}
                style={{ width: "25%", height: "25%", objectFit: "contain" }}
              />
              </div>)}
              
            </ColumnForm>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Thumbnail Image</Label>
                <Input type="file" onChange={(e) => onChangeThumbnailFile(e)} />
              </InputContainer>
             
              {/* <img
                src={URL.createObjectURL(thubnail_file}
                style={{ width: "25%", height: "25%", objectFit: "contain" }}
              /> */}
             
              
            </ColumnForm>
            <TwoColumn>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">Product Price</Label>
              <Input
                type="text"
                placeholder="Product Price"
                value={price}
                onChange={(e) => onChangePrice(e)}
              />
            </InputContainer>
            </Column>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">Product Discount</Label>
              <Input
                type="text"
                placeholder="Product Discount"
                value={discount}
                onChange={(e) => onChangeDiscount(e)}
              />
            </InputContainer>
            </Column>

            </TwoColumn>
            <TwoColumn>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">Product Video ID</Label>
              <Input
                type="text"
                placeholder="Product Video ID"
                value={video_id}
                onChange={(e) => onChangeVideoID(e)}
              />
            </InputContainer>
            </Column>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">Duration</Label>
              <Input
                type="text"
                placeholder="Duration"
                value={duration}
                onChange={(e) => onChangeDuration(e)}
              />
            </InputContainer>
            </Column>
            </TwoColumn>
            <TwoColumn>
            <Column>
            {dos.map((item,index)=>
            <Column key={index}>
              <InputContainer>
              {index===0?
              <Button onClick={()=>onAddDos()}>Do's +</Button>:null}
              <Input type="text" value={item.value} onChange={(e)=>onChangeDos(e,item.id)}/>
              </InputContainer>
            </Column>)}
            </Column>
            <Column>
            {donts.map((item,index)=>
            <Column key={index}>
              <InputContainer>
               {index===0?<Button onClick={()=>onAddDonts()}>Don'ts +</Button>:null}
                <Input type="text" value={item.value} onChange={(e)=>onChangeDonts(e,item.id)}/>
              </InputContainer>
            </Column>)}
            </Column>
            </TwoColumn>
            <Actions>
              <Button
                ref={add_product_button_ref}
                onClick={() => add_product()}
              >
                Save Product
              </Button>
            </Actions>

          </form>
        </FormContainer>
    </Container>
  );
};
