import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: 1,
};

export const ComponentSlice = createSlice({
  name: "callComponent",
  initialState,
  reducers: {
    componentReducer: (state, action) => {
      switch (action.payload) {
        case 0:
          state.value = 0;
          break;
        case 1:
          state.value = 1;
          break;
        case 2:
          state.value = 2;
          break;
        case 3:
          state.value = 3;
          break;
        case 4:
          state.value = 4;
          break;
        case 5:
          state.value = 5;
          break;
        case 6:
          state.value = 6;
          break;
        case 7:
          state.value = 7;
          break;
        case 8:
          state.value = 8;
          break;
        case 9:
          state.value = 9;
          break;
        case 10:
          state.value = 10;
          break;
        case 11:
          state.value = 11;
          break;
        case 12:
          state.value = 12;
          break;
        case 13:
          state.value = 13;
          break;
        case 14:
          state.value = 14;
          break;
        case 15:
          state.value = 15;
          break;
        case 16:
          state.value = 16;
          break;
        case 17:
          state.value = 17;
          break;
        case 18:
        state.value = 18;
        break;
        case 19:
          state.value = 19;
          break;
          case 20:
          state.value = 20;
          break;
          case 21:
            state.value = 21;
            break;
            case 22:
              state.value = 22;
              break;
              case 23:
                state.value = 23;
                break;
                case 24:
                state.value = 24;
                break;
                case 25:
                  state.value = 25;
                  break;
                  case 26:
                    state.value = 26;
                    break;
      }
    },
  },
});

export const { componentReducer } = ComponentSlice.actions;

export default ComponentSlice.reducer;
