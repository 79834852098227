import React,{useState,useEffect,useRef} from "react";
import tw from "twin.macro";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {useSelector,useDispatch} from 'react-redux';
import { populate_customers } from "../Redux/CustomersSlice";
import SwitchOnIcon from '../../Images/SwitchOn.png';
import SwitchOffIcon from '../../Images/SwitchOff.png';
import DeleteIcon from '../../Images/DeleteIcon.png'; 
import axios from "axios";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
export default()=>
{
  const dispatch=useDispatch();
  const counter=useSelector((state)=>state.counterComponent.value);
  const customers =useSelector((state)=>state.customersComponent.value);
  const[current_customer_array,set_current_customer_array]=useState([]);
  const current_page=useRef(1);
  const total_pages=useRef(Math.ceil(Number(counter.customers_count/10)));
  useEffect(()=>
  {
    let temp=[];
    for(let i=0;i<10;i++)
    {
      temp.push(customers[i]);
    }
    set_current_customer_array(temp);
  },[])
  const onPressActivateDeactivate=()=>
  {

  }
  const onPressDelete=()=>
  {

  }
  const onPressNext=()=>
  {
    if(current_page.current+1<=total_pages.current)
    {
      if(customers.length>=(current_page.current+1)*10)
      {
        let temp=[];
        for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
        {
          temp.push(customers[i]);
        }
        current_page.current+=1;
        set_current_customer_array(temp);
      }
      else
      {
        axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/customers?limit=10&offset=${current_page.current*10}`)
        .then((response)=>
        {
          if(response.status===200)
          {
            let temp=[];
            let temp_all=[];
            for(let i=0;i<customers.length;i++)
            {
              temp_all.push(customers[i]);
            }
            for(let i=0;i<response.data.length;i++)
            {
              temp_all.push(response.data[i]);
              temp.push(response.data[i]);
            }
            current_page.current+=1;
            set_current_customer_array(temp);
            dispatch(populate_customers(temp_all));
          }
          else
          {
            alert(response.message);
          }
        }).catch((error)=>
        {
          alert(error.message);
        })
      }
    }
  }
  const onPressPrevious=()=>
  {
    if(current_page.current-1>0)
    {
     let temp=[];
     for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
     {
      temp.push(customers[i]);
     }
     current_page.current-=1;
     set_current_customer_array(temp);
    }
  }
  return (
    <Container>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        CUSTOMER TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "12%" }}>Customer ID</TableTitle>

          <TableTitle style={{ width: "12%" }}>Customer Name</TableTitle>

          <TableTitle style={{ width: "25%" }}>Customer Cell Number</TableTitle>

          <TableTitle style={{ width: "21%" }}>Customer Email</TableTitle>

          <TableTitle style={{ width: "15%" }}>Customer Join Date</TableTitle>
          <TableTitle style={{ width: "15%" }}>Customer Status</TableTitle>
          <TableTitle style={{ width: "15%" }}>Action</TableTitle>
        </TableTop>

        {current_customer_array.length>0?
        current_customer_array.map((card, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "12%" }}>
                {card.id}
              </TableDetails>

              <TableDetails style={{ width: "12%" }}>
                {card.first_name}
              </TableDetails>

              <TableDetails style={{ width: "25%" }}>
                {card.mobile_number}
              </TableDetails>

              <TableDetails style={{ width: "21%" }}>
                {card.email}
              </TableDetails>

              <TableDetails style={{ width: "15%" }}>
                {new Date(card.date_of_joining).getFullYear()+"/"+Number(new Date(card.date_of_joining).getMonth()+1)+"/"+new Date(card.date_of_joining).getDate()}
              </TableDetails>
              <TableDetails style={{ width: "15%",justifyContent:"center",display:"flex"}}>
               <img style={{width:25,height:25,objectFit:"contain",alignSelf:"center"}} src={card.status===1?SwitchOnIcon:SwitchOffIcon}/>
              </TableDetails>
              <TableDetails style={{ width: "15%",justifyContent:"center",display:"flex"}}>
               <img style={{width:25,height:25,objectFit:"contain",alignSelf:"center"}} src={DeleteIcon}/>
              </TableDetails>
            </Card>
          </Column>
        )):<label>No customers available.....</label>}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  );
};
