import { createSlice } from "@reduxjs/toolkit";
export const ServicesSlice=createSlice({
    name:"services",
    initialState:{
        value:[]
    },
    reducers:{
        populate_services:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_services}=ServicesSlice.actions;
export default ServicesSlice.reducer;