import { createSlice } from "@reduxjs/toolkit";
export const ProductsRequestSlice=createSlice({
    name:"productsrequest",
    initialState:{
        value:[]
    },
    reducers:{
        populate_products_request:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_products_request}=ProductsRequestSlice.actions;
export default ProductsRequestSlice.reducer;