import React, { useState,useEffect, useRef } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { populate_news_feed } from "../Redux/NewsFeedSlice";
import { populate_metadata } from "../Redux/MetadataSlice";
const Container = tw.div`relative w-full mx-6 my-10`;

const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;

const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;

const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;

const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;

const TableTitle = tw.div`relative py-3`;

const Column = tw.div``;

const ActionIconsColumn = tw.div`flex `;

const IconContainer = tw.a`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;

const ButtonsContainer = tw.div`flex justify-between`;

const ButtonText = tw.div` -mt-1`;

const Button = tw.a`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const SelectContainer = tw.select`relative py-5 px-5 mt-6 text-black`;
const OptionContainer = tw.option`relative py-5  mt-6 text-black`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const Link1 = tw.button`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ cards = null }) => {
  const[thumbnailFile,setThumbnailFile]=useState(null);
  const[bannerFiles,setBannerFiles]=useState([]);
  const thumbnailFileUrl=useRef("");
  const bannerFilesUrl=useRef([]);
  let lastNewsID=useRef(0);
  const getTheLastNewsID=()=>
  {
    axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/lastNews`)
    .then((response)=>
    {
      if(response.status===200)
      {
        lastNewsID.current=response.data[0].id;
      }
    }).catch((error)=>
    {
      console.log(error.message);
    })
  }
  useEffect(()=>
  {
    getTheLastNewsID();
  },[]);
  const newsName=useRef("");
  const news_feed=useSelector((state)=>state.newsFeedComponent.value);
  const meta_data=useSelector((state)=>state.metaDataComponent.value);
  const customers=useSelector((state)=>state.customersComponent.value);
  const add_notification = (_id) => {
   console.log(_id);
    // let registration_ids = [];
    let registration_ids = [];
      for (let i = 0; i < customers.length; i++) {
        registration_ids.push(customers[i].registration_token);
      }
      console.log("Customers:"+registration_ids);
    
    if (title.length > 0 && content.length > 0) {
      var data1 = {
    //    data: {"route":"NewsFeed","id":`${(Number(lastNewsID.current)+1)}`},
    data: {type:"NewsFeedContent",specificId:_id},
        notification: {
          title: title.substring(0,15)+"....",
          body: content.substring(0,15)+".....",
          image:`https://storage.googleapis.com/p4uconsole/NewsFeed/${_id}/${thumbnailFile.name}`,
         },
        registration_ids: registration_ids,
      };

      var config = {
        method: "post",
        url: "https://fcm.googleapis.com/fcm/send",
        headers: {
          Authorization:
            "key=AAAAtqgGS0o:APA91bEWPCUlMzWZY3AXnJd9499nHC1iQnzIU6vmbbOoMGDem8IiEtM0ZiAl7OMQFQ0cB4eBtC1MC4KwjPjR-eGljXUsvRmGPl-ryS7GQpnJNAN0pnffMMlhM74XpzwEgYY0V4euVYae",
          "Content-Type": "application/json",
        },
        data: data1,
      };
      axios(config)
        .then(function (response2) {
          console.log(response2);
          if (response2.status === 200) {
            axios
              .post(
                `https://node-service-app-ifox3xnafa-el.a.run.app/add_notification`,
                {
                  title: title.substring(0,15)+"....",
                  body: content.substring(0,15)+".....",
                  date:
                    new Date().getFullYear() +
                    "-" +
                    Number(new Date().getMonth() + 1) +
                    "-" +
                    new Date().getDay() +
                    " " +
                    new Date().getHours() +
                    ":" +
                    new Date().getMinutes() +
                    ":" +
                    new Date().getSeconds(),
                  for_customers:  1 ,
                  banner_url:newsName.current
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response3) => {
                console.log(response3);
                if (response3.status === 200) {
                  setBannerFiles([]);
                  setThumbnailFile(null);
                  setPostType(0);
                  
                }
              });
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Fields are empty!!");
    }
  };
  console.log(meta_data[0]);
  const dispatch = useDispatch();
  const[title,set_title]=useState("");
  const onChangeTitle=(e)=>
  {
    set_title(e.target.value);
  }
  const[content,set_content]=useState("");
  const onChangeContent=(e)=>
  {
    set_content(e.target.value);
  }
  const[youtube_video_id,set_youtube_video_id]=useState("");
  const onChangeYoutubeVideoID=(e)=>
  {
    set_youtube_video_id(e.target.value);
  }
  const [file_to_upload, set_file_to_upload] = useState([]);
  const [file_to_preview, set_file_to_preview] = useState([]);
  const[file_name,set_file_names]=useState([]);
  const add_news_feed_button_ref = useRef();
  const uploadTheBanners = (id) => {
    let tempFiles=[];
    for(let i=0;i<bannerFiles.length;i++)
    {
    let form_data = new FormData();
    form_data.append("file",bannerFiles[i]);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/NewsFeed/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        if(response.status===200)
        {
          bannerFilesUrl.current.push(bannerFiles[i].name);
          tempFiles.push(bannerFiles[i].name);
          if(tempFiles.length==bannerFiles.length && thumbnailFileUrl.current.length>0)
          {
          axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/news_feed/${id}`,
          {
            thumbnail_url:thumbnailFileUrl.current,
            banners_url:JSON.stringify(tempFiles),
          })
          .then((response)=>
          {
            if(response.status===200)
            {
              bannerFilesUrl.current=[];
              thumbnailFileUrl.current="";
              add_notification(id);
            }
          })
          .catch((error)=>
          {
            console.log(error);
          })
          }
        }
      }).catch(function(error){
        alert(error);
      })
    }
  };
  const[postType,setPostType]=useState(1);
  const uploadTheThumbnail=(id)=>
  {
    let form_data = new FormData();
    form_data.append("file",thumbnailFile);
    axios
      .post(
        `https://node-service-app-ifox3xnafa-el.a.run.app/upload/NewsFeed/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        if(response.status===200)
        {
          if(bannerFilesUrl.current.length==bannerFiles.length)
          {
            axios.patch(`https://node-service-app-ifox3xnafa-el.a.run.app/news_feed/${id}`,
            {
              thumbnail_url:thumbnailFile.name,
              banners_url:JSON.stringify(bannerFilesUrl.current),
            })
            .then((response)=>
            {
              if(response.status===200)
              {
                add_notification(id);
              }
            })
            .catch((error)=>
            {
              console.log(error);
            })
          }
        }
      }).catch(function(error){
        alert(error);
      })
    }
  
  const add_news_feed = () => {
    if(content.length<=4000)
    {
    console.log(JSON.stringify({
      title:title,
      content: content,
      youtube_video_id:youtube_video_id,
      creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
      newsFeedCategory:postType
    }));
    add_news_feed_button_ref.current.disabled = true;
    if (
     title.length > 0 &&
      content.length > 0
    ) {
      const data = JSON.stringify({
        title:title,
        content: content,
        youtube_video_id:youtube_video_id,
        newsFeedCategory:postType,
        creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds(),
          });
          axios
            .post(
              "https://node-service-app-ifox3xnafa-el.a.run.app/add_news_feed",
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              if(response.status===200)
              {
                uploadTheBanners(response.data.insertId);
                uploadTheThumbnail(response.data.insertId);
              }

              add_news_feed_button_ref.current.disabled = false;
              let news_feed_temp_array = [];
              for (let i = 0; i < news_feed.length; i++) {
                news_feed_temp_array.push(news_feed[i]);
              }
              news_feed_temp_array.push({
                id: (Number(lastNewsID.current)+1),
                title:title,
                content:content,
                youtube_video_id:youtube_video_id,
                creation_time:new Date().getFullYear()+"-"+Number(new Date().getMonth()+1)+"-"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()
              });
              dispatch(populate_news_feed(news_feed_temp_array));
              alert("Successfully Added News Feed");
              set_title("");
              set_content("");
              set_file_names("");
              set_file_to_upload("");
              set_youtube_video_id("");
              set_file_to_preview([]);
              lastNewsID.current+=1;
            })
            .catch(function (err) {
              alert(err.message);
            });
      
    } else {
      alert("Fields Are Missing!");
      add_news_feed_button_ref.current.disabled = false;
    }
  }
  else
  {
    alert('Contnent is too long');
  }
  };
  const onChangeBanners=(e)=>
  {
    let temp=[];
    for(let i=0;i<e.target.files.length;i++)
    {
      temp.push(e.target.files[i]);
    }
    setBannerFiles(temp);
  }
  const deleteTheBanner=(index)=>
  {
    let tempBanners=[...bannerFiles];
    tempBanners.splice(index,1);
    setBannerFiles(tempBanners);
  }
  return (
    <Container>
        <FormContainer>
          <form action="#">
            <TwoColumn>
              <ColumnForm>
                <InputContainer>
                  <Label htmlFor="name-input">News Feed Title</Label>
                  <Input
                    type="text"
                    placeholder="News Feed Title"
                    value={title}
                    onChange={(e) => onChangeTitle(e)}
                  />
                </InputContainer>
              </ColumnForm>

              <ColumnForm>
               
              </ColumnForm>
            </TwoColumn>
            <TwoColumn>
              <ColumnForm>
            
              </ColumnForm>

              <ColumnForm>
              </ColumnForm>
              <ColumnForm>
              
              </ColumnForm>
            </TwoColumn>
            <InputContainer>
              <Label htmlFor="name-input">News Feed Content</Label>
              <InputLarge
                type="text"
                placeholder="News Feed Content"
                value={content}
                onChange={(e) => onChangeContent(e)}
              />
            </InputContainer>
          
            <ColumnForm>
          
              <InputContainer>
                <Label htmlFor="name-input">News Feed Banners</Label>
                <Input type="file" multiple={true} onChange={(e) => onChangeBanners(e)} />
              </InputContainer>
              <div style={{display:"grid",gridTemplateColumns:"auto auto auto auto",width:"100%",}}>
              {
              
              bannerFiles.map((item,index)=>
              <div  key={index} style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
              <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
              <button
                 style={{backgroundColor:"red",padding:"1px",borderRadius:1000,width:25,height:25,alignSelf:"flex-end"}}
                onClick={() =>deleteTheBanner(index)}
              >
                X
                </button>
              <img
                src={URL.createObjectURL(item)}
                style={{ width: "100%", height: "80%", objectFit: "contain" }}
              />

                </div>
              </div>)}
              </div>
            </ColumnForm>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Thumbnail Image</Label>
                <Input type="file" onChange={(e) => setThumbnailFile(e.target.files[0])} />
              </InputContainer>
             
              {
              thumbnailFile!==null?
              <div   style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <img
                src={URL.createObjectURL(thumbnailFile)}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
                 <button
                 style={{backgroundColor:"red",padding:"1px",borderRadius:1000,width:25,height:25}}
                onClick={() =>setThumbnailFile(null)}
              >
                X
                </button>
              </div>:null}
              
            </ColumnForm>
            <TwoColumn>
            <Column>
            {/* <InputContainer>
              <Label htmlFor="name-input">Product Price</Label>
              <Input
                type="text"
                placeholder="Product Price"
                value={price}
                onChange={(e) => onChangePrice(e)}
              />
            </InputContainer> */}
            </Column>
            <Column>
            {/* <InputContainer>
              <Label htmlFor="name-input">Product Discount</Label>
              <Input
                type="text"
                placeholder="Product Discount"
                value={discount}
                onChange={(e) => onChangeDiscount(e)}
              />
            </InputContainer> */}
            </Column>

            </TwoColumn>
            <TwoColumn>
            <Column>
            <InputContainer>
              <Label htmlFor="name-input">News Feed Video ID</Label>
              <Input
                type="text"
                placeholder="News Feed Video ID"
                value={youtube_video_id}
                onChange={(e) => onChangeYoutubeVideoID(e)}
              />
            </InputContainer>
            </Column>
            <Column>
            </Column>
            </TwoColumn>
            <TwoColumn>
            
            
            </TwoColumn>
            <TwoColumn>
            <InputContainer className="flex flex-row">
                  <Label htmlFor="name-input">Post Type</Label>
                  <Select
                    value={postType}
                    onChange={(e) => setPostType(e.target.value)}
                  >
                    <OptionContainer value={null}>Select....</OptionContainer>
                    <OptionContainer value={1}>Global</OptionContainer>
                    <OptionContainer value={2}>Cinema</OptionContainer>
                    <OptionContainer value={3}>Political</OptionContainer>
                    <OptionContainer value={4}>Sports</OptionContainer>
                    <OptionContainer value={5}>Official</OptionContainer>
                  </Select>
                </InputContainer>
             </TwoColumn>
            <Actions>
              <Button
                ref={add_news_feed_button_ref}
                onClick={() => add_news_feed()}
              >
                Save News Feed
              </Button>
            </Actions>

          </form>
        </FormContainer>

    </Container>
  );
};
