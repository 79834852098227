import { createSlice } from "@reduxjs/toolkit";
export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    value: [],
  },
  reducers: {
    populate_notifications: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { populate_notifications } = NotificationsSlice.actions;
export default NotificationsSlice.reducer;
