import React, { useState,useEffect,useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_settlements } from "../Redux/SettlementsSlice";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
const Container = tw.div`relative w-full mx-6 my-10`;
const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;
const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;
const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;
const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;
const TableTitle = tw.div`relative py-3`;
const Column = tw.div``;
const ActionIconsColumn = tw.div`flex `;
const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;
const ButtonsContainer = tw.div`flex justify-between`;
const ButtonText = tw.div` -mt-1`;
const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const OptionContainer = tw.option`relative py-5 rounded-lg  mt-6 text-black`;
const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
export default () => {
  const dispatch = useDispatch();
  const settlements = useSelector((state) => state.settlementsComponent.value);
        //Pagination
        const counter=useSelector((state)=>state.counterComponent.value);
        const[current_settlement_array,set_current_settlement_array]=useState([]);
        const current_page=useRef(1);
        const total_pages=useRef(Math.ceil(Number(counter.settlements_count/10)));
        //Load The First 10 elements of the table
        useEffect(()=>
        {
          let temp=[];
          if(settlements.length<10)
          {
            for(let i=0;i<settlements.length;i++)
            {
              temp.push(settlements[i]);
            }
          }
          else
          {
            for(let i=0;i<10;i++)
            {
              temp.push(settlements[i]);
            }
          }
          set_current_settlement_array(temp);
        },[]);
        const onPressNext=()=>
        {
          if(current_page.current+1<=total_pages.current)
          {
            if(settlements.length>=(current_page.current+1)*10)
            {
              let temp=[];
              for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
              {
                temp.push(settlements[i]);
              }
              current_page.current+=1;
              set_current_settlement_array(temp);
            }
            else
            {
              axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/settlements?limit=10&offset=${current_page.current*10}`)
              .then((response)=>
              {
                if(response.status===200)
                {
                  let temp=[];
                  let temp_all=[];
                  for(let i=0;i<settlements.length;i++)
                  {
                    temp_all.push(settlements[i]);
                  }
                  for(let i=0;i<response.data.length;i++)
                  {
                    temp_all.push(response.data[i]);
                    temp.push(response.data[i]);
                  }
                  current_page.current+=1;
                  set_current_settlement_array(temp);
                  dispatch(populate_settlements(temp_all));
                }
                else
                {
                  alert(response.message);
                }
              }).catch((error)=>
              {
                alert(error.message);
              })
            }
          }
        }
        const onPressPrevious=()=>
        {
          if(current_page.current-1>0)
          {
           let temp=[];
           for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
           {
            temp.push(settlements[i]);
           }
           current_page.current-=1;
           set_current_settlement_array(temp);
          }
        }
        ///////////////////////////////////////////////////////////////////////
  return (
    <Container>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        PAYROLL TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          {/* <TableTitle style={{ width: "12%" }}>Settlement ID</TableTitle> */}
          <TableTitle style={{ width: "12%" }}>Settlement Amount</TableTitle>

          <TableTitle style={{ width: "13%" }}>Vendor Name</TableTitle>

          <TableTitle style={{ width: "13%" }}>Vendor Mobile Number</TableTitle>

          <TableTitle style={{ width: "13%" }}>Transaction ID</TableTitle>

          <TableTitle style={{ width: "15%" }}>Date</TableTitle>
          <TableTitle style={{ width: "12%" }}>Transaction Receipt</TableTitle>
        </TableTop>

        {current_settlement_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              {/* <TableDetails style={{ width: "12%" }}>{item.id}</TableDetails> */}
              <TableDetails style={{ width: "12%" }}>
                {item.amount}
              </TableDetails>
              <TableDetails style={{ width: "13%" }}>{item.name}</TableDetails>

              <TableDetails style={{ width: "13%" }}>
                {item.mobile_number}
              </TableDetails>

              <TableDetails style={{ width: "15%" ,color:item.is_available===1?"green":"red",fontWeight:"bold"}}>
                {item.transaction_id}
              </TableDetails>
              <TableDetails
                style={{
                  width: "12%",
                  height: "12%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {new Date(item.date).getFullYear()+"-"+new Date(item.date).getMonth()+"-"+new Date(item.date).getDate()+" "+new Date(item.date).getHours()+":"+new Date(item.date).getMinutes()+":"+new Date(item.date).getSeconds()}
              </TableDetails>
              <TableDetails
                style={{
                  width: "13%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    "https://storage.googleapis.com/p4uconsole/Settlements/" +
                    item.transaction_picture_url
                  }
                  style={{
                    width: "50%",
                    height: "50%",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                ></img>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
  );
};
