import React, { useState,useEffect,useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_services } from "../Redux/ServicesSlice";
import SwitchOn from "../../Images/SwitchOn.png";
import SwitchOff from "../../Images/SwitchOff.png";
const Container = tw.div`relative w-full mx-6 my-10`;
const TableContainer = tw.div` mt-10 bg-white p-6 items-center md:items-stretch  md:justify-center     `;
const TableTop = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl bg-gray-200 text-center border-solid border-b `;
const Card = tw.div`flex flex-col sm:flex-row items-center  justify-center  sm:items-start text-center sm:text-left  py-4 border-solid border-b `;
const TableDetails = tw.div`flex flex-col sm:flex-row items-center justify-center rounded-xl  text-center `;
const TableTitle = tw.div`relative py-3`;
const Column = tw.div``;
const ActionIconsColumn = tw.div`flex `;
const IconContainer = tw.button`flex rounded-lg bg-gray-300  content-center mx-2 p-2 `;
const ButtonsContainer = tw.div`flex justify-between`;
const ButtonText = tw.div` -mt-1`;
const Button = tw.button`flex flex-col sm:flex-row mt-8 p-2 text-base font-semibold tracking-wider rounded-lg  items-center  bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
const FormContainer = styled.div`
  ${tw`  bg-white p-6  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-0 ml-10 mr-10 pb-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-textprimary text-base font-medium tracking-wide border-b-2 py-2 text-textprimary hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw` text-gray-600 font-semibold text-sm`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute text-gray-900 top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`pl-4 mt-3 rounded-lg bg-gray-200`;
const InputLarge = tw.textarea`pl-4  mt-3 rounded-lg bg-gray-200`;
const ColumnForm = tw.div`sm:w-5/12 flex flex-col`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center mt-8 ml-0 mb-2 mr-8 lg:justify-start `;
const OptionContainer = tw.option`relative py-5 rounded-lg  mt-6 text-black`;
const Link1 = tw.a`w-40 p-2 sm:p-3 text-base sm:text-base font-semibold tracking-wider rounded-lg inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-green-500 hocus:bg-green-600 text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`;
const Select = tw.select`relative pl-4 mt-3 rounded-lg bg-gray-200 text-black`;
export default () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.servicesComponent.value);
  const categories = useSelector((state) => state.categoriesComponent.value);
  const add_service_button_ref = useRef();
        //Pagination
        const counter=useSelector((state)=>state.counterComponent.value);
        const[current_service_array,set_current_service_array]=useState([]);
        const current_page=useRef(1);
        const total_pages=useRef(Math.ceil(Number(counter.services_count/10)));
        //Load The First 10 elements of the table
        useEffect(()=>
        {
          let temp=[];
          for(let i=0;i<10;i++)
          {
            temp.push(services[i]);
          }
          set_current_service_array(temp);
        },[]);
        const onPressNext=()=>
        {
          if(current_page.current+1<=total_pages.current)
          {
            if(services.length>=(current_page.current+1)*10)
            {
              let temp=[];
              for(let i=current_page.current*10;i<(current_page.current+1)*10;i++)
              {
                temp.push(services[i]);
              }
              current_page.current+=1;
              set_current_service_array(temp);
            }
            else
            {
              axios.get(`https://node-service-app-ifox3xnafa-el.a.run.app/services?limit=10&offset=${current_page.current*10}`)
              .then((response)=>
              {
                if(response.status===200)
                {
                  let temp=[];
                  let temp_all=[];
                  for(let i=0;i<services.length;i++)
                  {
                    temp_all.push(services[i]);
                  }
                  for(let i=0;i<response.data.length;i++)
                  {
                    temp_all.push(response.data[i]);
                    temp.push(response.data[i]);
                  }
                  current_page.current+=1;
                  set_current_service_array(temp);
                  dispatch(populate_services(temp_all));
                }
                else
                {
                  alert(response.message);
                }
              }).catch((error)=>
              {
                alert(error.message);
              })
            }
          }
        }
        const onPressPrevious=()=>
        {
          if(current_page.current-1>0)
          {
           let temp=[];
           for(let i=((current_page.current-1)*10)-10;i<((current_page.current-1)*10);i++)
           {
            temp.push(services[i]);
           }
           current_page.current-=1;
           set_current_service_array(temp);
          }
        }
        ///////////////////////////////////////////////////////////////////////
  const [show_upload_modal, set_show_upload_modal] = useState(false);
  const [name, set_name] = useState("");
  const onChangeServiceName = (e) => {
    set_name(e.target.value);
  };
  const [description, set_description] = useState("");
  const onChangeServiceDescription = (e) => {
    set_description(e.target.value);
  };
  const [is_available, set_is_available] = useState(true);
  const onChangeServiceAvailability = (e) => {
    set_is_available(e.target.value);
  };
  const [file, set_file] = useState("");
  const [file_to_preview, set_file_to_preview] = useState("");
  const onChangeFile = (e) => {
    set_file(e.target.files[0]);
    set_file_to_preview(URL.createObjectURL(e.target.files[0]));
  };
  const [category_id, set_category_id] = useState(0);
  const onChangeCategoryId = (e) => {
    console.log(e.target.value);
    set_category_id(e.target.value);
  };
  const[service_icon_url,set_service_icon_url]=useState("")
  const AddService = () => {
    add_service_button_ref.current.disabled = true;
    if (name.length > 0 && description.length > 0 && file !== null) {
      let form_data = new FormData();
      form_data.append("file", file);
      axios
        .post(
          "https://node-service-app-ifox3xnafa-el.a.run.app/upload/Services/nothing",
          form_data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          console.log(response.data.url);
          const data = JSON.stringify({
            name: name,
            description: description,
            icon_url: file.name,
            is_available: is_available,
            category_id: category_id,
            is_trending: false,
          });
          axios
            .post(
              "https://node-service-app-ifox3xnafa-el.a.run.app/add_services",
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              console.log(response);

              add_service_button_ref.current.disabled = false;
              let service_temp_array = [];
              for (let i = 0; i < services.length; i++) {
                service_temp_array.push(services[i]);
              }
              service_temp_array.push({
                id: services.length + 1,
                name: name,
                description: description,
                is_available: is_available,
                is_trending: false,
                category_id: category_id,
                icon_url: file_to_preview,
              });
              dispatch(populate_services(service_temp_array));
              alert("Successfully Added Service");
              set_description("");
              set_name("");
              set_file_to_preview();
              set_file(null);
              set_category_id(0);
            })
            .catch(function (err) {
              alert(err.message);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Fields are empty!!");
      add_service_button_ref.current.disabled = false;
    }
  };
  const OnClickedEditService = (id) => {
    set_open_edit_modal(true);
    set_name(services.find(e=>e.id===id).name);
    set_description(services.find(e=>e.id===id).description);
    set_is_available(services.find(e=>e.id===id).is_available);
    set_category_id(services.find(e=>e.id===id).category_id);
    set_service_icon_url(services.find(e=>e.id===id).icon_url);

  };
  const[open_edit_model,set_open_edit_modal]=useState(false);
  const UpdateVendorStatus = () => {
    
  };
  const onDeleteServiceClicked=(id)=>
  {
    let pictureName=services.find(e=>e.id==id).icon_url;
    axios.delete(`https://node-service-app-ifox3xnafa-el.a.run.app/files/Services/null/${pictureName}`)
    .then((response)=>
    {
      if(response.status===200)
      {
        console.log('Successfully Deleted The Picture');
        axios.delete(`https://node-service-app-ifox3xnafa-el.a.run.app/service/${id}`)
        .then((deleteResponse)=>
        {
          console.log('Successfully Deleted The Entry');
        })
        .catch((deleteError)=>
        {
          console.log('Error Deleting Entry:'+deleteError);
        });
      }
    })
    .catch((error)=>
    {
      console.log('Error Deleting Picture:'+error);
    })
  }
  return (
    open_edit_model===false?
    <Container>
      <h1 style={{ color: "black", textAlign: "center", fontSize: 32 }}>
        SERVICES TABLE{" "}
      </h1>
      <TableContainer>
        <TableTop>
        <TableTitle style={{ width: "12%" }}>Serial Number</TableTitle>
          <TableTitle style={{ width: "12%" }}>Services ID</TableTitle>
          <TableTitle style={{ width: "12%" }}>Service Icon</TableTitle>

          <TableTitle style={{ width: "12%" }}>Category ID</TableTitle>

          <TableTitle style={{ width: "25%" }}>Service Name</TableTitle>

          <TableTitle style={{ width: "21%" }}>Service Description</TableTitle>

          <TableTitle style={{ width: "15%" }}>Service Availability</TableTitle>
          <TableTitle style={{ width: "12%" }}>Change Availability</TableTitle>
          <TableTitle style={{ width: "15%" }}>Actions</TableTitle>
        </TableTop>

        {current_service_array.map((item, i) => (
          <Column key={i}>
            <Card>
            <TableDetails style={{ width: "13%" }}>{(i+((current_page.current-1)*10))+1}</TableDetails>
              <TableDetails style={{ width: "12%" }}>{item.id}</TableDetails>
              <TableDetails
                style={{
                  width: "13%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    "https://storage.googleapis.com/p4uconsole/Services/" +
                    item.icon_url
                  }
                  style={{
                    width: "50%",
                    height: "50%",
                    objectFit: "contain",
                    alignSelf: "center",
                  }}
                ></img>
              </TableDetails>
              <TableDetails style={{ width: "12%" }}>
                {item.category_id}
              </TableDetails>
              <TableDetails style={{ width: "25%" }}>{item.name}</TableDetails>

              <TableDetails style={{ width: "21%" }}>
                {item.description}
              </TableDetails>

              <TableDetails style={{ width: "15%" ,color:item.is_available===1?"green":"red",fontWeight:"bold"}}>
                {item.is_available===1?"Available":"Unavailable"}
              </TableDetails>
              <TableDetails
                style={{
                  width: "12%",
                  height: "12%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={item.is_available === 1 ? SwitchOn : SwitchOff}
                  style={{
                    width: "25%",
                    height: "25%",
                    objectFit: "contain",
                    //  float: "left",
                  }}
                  onClick={() =>
                    UpdateVendorStatus()
                  }
                ></img>
              </TableDetails>
              <TableDetails style={{ width: "15%" }}>
                <ActionIconsColumn>
                  <IconContainer onClick={()=>OnClickedEditService(item.id)}>
                    <AiOutlineEdit />
                  </IconContainer>

                  <IconContainer onClick={()=>onDeleteServiceClicked(item.id)}>
                    <AiOutlineDelete />
                  </IconContainer>
                </ActionIconsColumn>
              </TableDetails>
            </Card>
          </Column>
        ))}
        <div style={{width:"40%",height:10,display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",color:"#000000",fontWeight:"bold",fontSize:15}}>Page {current_page.current} of {total_pages.current}</label>
        </div>
        <ButtonsContainer>
          <Button onClick={()=>onPressPrevious()}>
            <AiOutlineArrowLeft />
            <ButtonText>Previous</ButtonText>
          </Button>

          <Button onClick={()=>onPressNext()}>
            <ButtonText>Next</ButtonText>
            <AiOutlineArrowRight />
          </Button>
        </ButtonsContainer>
      </TableContainer>
    </Container>
    :
    <Container>
    <FormContainer>
      <form action="#">
        <TwoColumn>
          <ColumnForm>
            <InputContainer>
              <Label htmlFor="name-input">Service Name</Label>
              <Input
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #022C43",
                }}
                id="service-name"
                type="text"
                name="servicename"
                placeholder="Service Name"
                value={name}
                onChange={(e) => onChangeServiceName(e)}
              />
            </InputContainer>
            <ColumnForm>
              <InputContainer>
                <Label htmlFor="name-input">Availability</Label>
                <Select
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #022C43",
                  }}
                  value={is_available}
                  onChange={(e) => onChangeServiceAvailability(e)}
                >
                  <OptionContainer value={1}>Yes</OptionContainer>
                  <OptionContainer value={0}>No</OptionContainer>
                </Select>
              </InputContainer>
            </ColumnForm>
          </ColumnForm>

          <ColumnForm>
            <InputContainer>
              <Label htmlFor="name-input">Category</Label>
              <Select
                style={{
                  width: "100%",
                  height: "100%",
                  border: "1px solid #022C43",
                }}
                value={category_id}
                onChange={(e) => onChangeCategoryId(e)}
              >
                  <OptionContainer>
                    Select from the following categories....
                  </OptionContainer>
                {categories.map((item1, index1) => (
                  <OptionContainer key={index1} value={item1.id}>
                    {item1.name}
                  </OptionContainer>
                ))}
              </Select>
            </InputContainer>
          </ColumnForm>
        </TwoColumn>
        <TwoColumn>
          <Column>
          <InputContainer>
            <Label htmlFor="name-input">Service Icon</Label>
            <Input
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #022C43",
              }}
              type="file"
              name="Service Icon"
              placeholder="Service Icon"
              // value={file}
              onChange={(e) => onChangeFile(e)}
            />
            {file_to_preview !== "" ? (
              <img
                src={file_to_preview}
                style={{
                  width: "50%",
                  height: "50%",
                  objectFit: "contain",
                }}
              />
            ) : null}
           
          </InputContainer>
          </Column>
          <Column>
          <img
                src={`https://storage.googleapis.com/p4uconsole/Services/${service_icon_url}`}
                style={{
                  width: "50%",
                  height: "50%",
                  objectFit: "contain",
                }}
              />
              </Column>
        </TwoColumn>
        <InputContainer>
          <Label htmlFor="name-input">Service Description</Label>
          <InputLarge
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #022C43",
            }}
            type="text"
            name="ServiceDescription"
            placeholder="Service Description..."
            value={description}
            onChange={(e) => onChangeServiceDescription(e)}
          />
        </InputContainer>

        <Actions>
          <Button ref={add_service_button_ref} onClick={() => AddService()}>
            Update Service
          </Button>
          <Button ref={add_service_button_ref} onClick={() =>set_open_edit_modal(false)}>
            Cancel
          </Button>
        </Actions>
      </form>
    </FormContainer>
  </Container>
  );
};
