import React from "react";
import tw from "twin.macro";

import { PrimaryButton, Link2 } from "../Components/MiscStyles/Buttons";

import Sidebar from "../Components/Sidebar";

const Container = tw.div`flex`;
const ContentContainer = tw.div`flex`;

const Content = tw.div`fixed right-0 w-10/12 `;
const TestDisplayArea = tw.div`py-40 bg-gray-600`;
const Seperator = tw.div` h-1 my-20 bg-sub`;
const Text = tw.p`text-blue-700 font-bold text-2xl text-center`;

export default () => {
  return (
    <Container style={{backgroundColor:"#04103D"}}>
      <Sidebar />

      <ContentContainer>
        <Content>
          <Text>ULTIMATE TEMPELATE Test Display Area</Text>
          <TestDisplayArea>
            <PrimaryButton>Button</PrimaryButton>
            <Link2>Button</Link2>
          </TestDisplayArea>
        </Content>
      </ContentContainer>
    </Container>
  );
};
