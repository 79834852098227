import { createSlice } from "@reduxjs/toolkit";
export const BannersSlice=createSlice({
    name:"banners",
    initialState:{
        value:[]
    },
    reducers:{
        populate_banners:(state,action)=>
        {
            state.value=action.payload
        }
    }
});
export const {populate_banners}=BannersSlice.actions;
export default BannersSlice.reducer;